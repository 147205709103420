import React, { useContext, useState } from "react";

import {
  makeStyles,
  IconButton,
  InputBase,
  Switch,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
  Checkbox,
  FormLabel,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import openModal from "../../assets/icons/Add.svg";
import Filters from "../../assets/icons/Filters.svg";
import Search from "../../assets/icons/Search.svg";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";

const useStyles = makeStyles(({palette}) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '1rem 0.5rem',
  },
  serachInputWrapper: {
    flex: 1,
    display: "flex",
    border: `1px solid ${palette.grey[600]}`,
    borderRadius: '8px',
    height: '3rem',
    padding: '0 1rem',
    margin: '0 0.5rem'
  },
  search: {
    alignSelf: "center",
    marginRight: '0.5rem'
  },
  searchInput: {
    flex: 1,
    border: "none",
    fontSize: '1rem',
    color: palette.text.primary
  }
}));

const ManagerHeader = ({ 
  handleSearch,
  handleOpenModal,
  isTicket,
  handleShowAllList,
  showAll,
  selectedQueueIds,
  onChange,
  currentTab,
  setCurrentTab
}) => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { queues } = user;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleSearchChange = (e) => {
    const searchInputValue = e.target.value; 
    
    if (isTicket) {
      setCurrentTab("search");
    }
    handleSearch(searchInputValue.toLowerCase());
  }

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  }
  
  const handleCloseMenu = () => {
    setAnchorEl(null);
  }
  
  const handleChangeTab = () => {
    setCurrentTab((prevState) => prevState === "closed" ? "open" : "closed");
    handleCloseMenu();
  }
  
  const toolTipTitle = () => {
    if (isTicket) {
      return showAll ? i18n.t("managerHeader.showAllTicketsSwitch.activeToolTip") : i18n.t("managerHeader.showAllTicketsSwitch.disabledTooltip");
    }
    return showAll ? i18n.t("managerHeader.showAllChatsSwitch.activeToolTip") : i18n.t("managerHeader.showAllChatsSwitch.disabledTooltip");
  }
  
  
  return (
    <div className={classes.header}>
      <IconButton
        onClick={handleOpenModal}
      >
      <img src={openModal} alt="Open modal button"/>
      </IconButton>
      <div className={classes.serachInputWrapper}>
        <img className={classes.search} src={Search} alt="Search icon"/>
        <InputBase
          className={classes.searchInput}
          placeholder={i18n.t("managerHeader.searchInput.placeholder")}
          type="search"
          onChange={handleSearchChange}
        />
      </div>
      <Can
        role={user.profile}
        perform="tickets-manager:showall"
        yes={() => (
          <Tooltip
            title={toolTipTitle()}
            placement="bottom"
          >
            <Switch
              color="primary"
              checked={showAll}
              onChange={handleShowAllList}
            />
          </Tooltip>
        )}
      />
      {isTicket && (
        <>
          <IconButton
            arias-controls="filters-menu"
            arias-haspopup="true"
            onClick={handleOpenMenu}
          >
            <img src={Filters} alt="Open filters button" />
          </IconButton>
          <Menu
            id="filters-menu"
            elevation={2}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
          >
            <MenuItem
              onClick={handleChangeTab}
            >
              <Checkbox
                color="primary"
                size="small"
                checked={currentTab === "closed"}
              />
              <FormLabel
                style={{color: "#303030"}}
              >
                {i18n.t("managerHeader.closedTickets")}
              </FormLabel>
            </MenuItem>
            {queues.length > 0 && (
              <div>
                <Divider/>
                <TicketsQueueSelect
                  userQueues={queues}
                  selectedQueueIds={selectedQueueIds}
                  onChange={onChange}
                />
              </div>
            )}
          </Menu>
        </>
      )}
    </div>
  )
}

export default ManagerHeader;