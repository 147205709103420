import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	makeStyles
} from "@material-ui/core";

import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import SelectedQueues from "../SelectQueues";
import ContactModal from "../ContactModal";
import ShowTicketOwnerModal from '../ShowTicketOwnerModal'
import { toastError } from "../../messages/toasts";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import useWhatsApps from "../../hooks/useWhatsApps";
import getWhatsappId from "../../utils/getWhatsappId";

const filter = createFilterOptions({
	trim: true,
});

const useStyles = makeStyles(() => ({
	progress: {
    position: "absolute",
    right: "32px",
    top: "0",
    bottom: "0",
    margin: "auto 0"
  }
}))

const NewTicketModal = ({ modalOpen, onClose, handleSwitchToOpenTab }) => {
	const history = useHistory();
	const classes = useStyles();

	const { user } = useContext(AuthContext);
	const { whatsApps, loading: loadingFetchWhatsapp } = useWhatsApps();

	const whatsappId = getWhatsappId(whatsApps, user.whatsappId);

	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState(null);
	const [ticketExists, setTicketExists] = useState({});
	const [newContact, setNewContact] = useState({});
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const [showTicketOwnerModalOpen, setShowTicketOwnerModalOpen] = useState(false);
	const [autoSelectContact, setAutoSelectContact] = useState(false);
	const [selectedWhatsappId, setSelectedWhatsappId] = useState("");

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data: dataContacts } = await api.get("contacts", {
						params: { searchParam },
					});

					const contacts = dataContacts.contacts.map(contact => ({
						...contact, type: 'contact'
					}))

					setOptions(contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setQueues([]);
		setSearchParam("");
		setSelectedContact(null);
		setSelectedQueue(null);
	};

	const handleSaveTicket = async selectedContact => {
		if (!selectedContact.id) return;

		setLoading(true);

		try {
			if (selectedContact.type === 'contact') {
				const { data: { ticket, ticketIsOpen }} = await api.post("/tickets", {
					contactId: selectedContact.id,
					userId: user.id,
					status: "open",
					queueId: selectedQueue,
					whatsappId: selectedWhatsappId
				});

				if (ticketIsOpen) {
					setTicketExists(ticket);
					setShowTicketOwnerModalOpen(true);
					onClose();
					return;
				}

				history.push(`/tickets/${ticket.id}`);
				handleSwitchToOpenTab();
			} else {
				const { data: ticket } = await api.post("/tickets", {
					userId: user.id,
					status: "open",
					invitedUserId: selectedContact.id,
					whatsappId: selectedWhatsappId
				});
				history.push(`/tickets/${ticket.id}`);
			}
		} catch (err) {
			toastError(err);
		}
		setLoading(false);
		handleClose();
	};

	const handleSelectedContact = (contact) => {
		if (!contact) {
			setQueues([]);
			setSearchParam("");
			setSelectedContact(null);
			setSelectedQueue(null);
			return;
		}
		
		if (whatsappId) {
			setSelectedWhatsappId(whatsappId);
		}

		if (contact.number || contact.type === 'user') {
			setSelectedContact(contact);

			const userLoggedQueues = user.queues
			if (userLoggedQueues.length > 0) {
				setQueues(userLoggedQueues);
				setSelectedQueue(userLoggedQueues[0].id);
			}
		} else if (contact.name) {
			setNewContact({ name: contact.name });
			setContactModalOpen(true);
		}
	};

	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleCloseShowTicketOwnerModal = () => {
		setShowTicketOwnerModalOpen(false);
		handleClose();
	}

	const handleAddNewContactTicket = contact => {
		handleSaveTicket(contact.id);
		setSearchParam(contact.name);
		setAutoSelectContact(true);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.type === 'contact') {
			return `${option.name} - ${option.number}`
		}

		return `${i18n.t("newTicketModal.add")} ${option.name}`
	};

	const renderOptionLabel = option => {
		if (option.type === 'contact') {
			if (autoSelectContact) {
				handleSelectedContact(option);
				setAutoSelectContact(false);
			}
			return option.number ? `${option.name} - ${option.number}` : `${option.name}`
		}
	};

	const shouldOpenOptions = searchParam.length >= 3 && !loading;

	return (
		<>
			<ShowTicketOwnerModal
				open={showTicketOwnerModalOpen}
				onClose={handleCloseShowTicketOwnerModal}
				ticket={ticketExists}
			/>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			></ContactModal>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("newTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						options={options}
						loading={loading}
						style={{ width: 300, marginBottom: 15 }}
						autoHighlight
						freeSolo
						open={shouldOpenOptions}
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(_, contact) => handleSelectedContact(contact)}
						value={selectedContact}
						onInputChange={(_, value) => setSearchParam(value)}
						inputValue={searchParam}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket(selectedContact);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					<SelectedQueues
						queues={queues}
						setSelectedQueue={setSelectedQueue}
						selectedQueue={selectedQueue}
					/>
					{(selectedContact && whatsApps.length > 0) && (
						<FormControl 
							variant="outlined"
							fullWidth
							style={{
								display: "flex",
								marginTop: "15px"
							}}
						>
							<InputLabel>
								{i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
							</InputLabel>
							<Select
								value={selectedWhatsappId}
								onChange={(e) => setSelectedWhatsappId(e.target.value)}
								label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
								disabled={loadingFetchWhatsapp}
								endAdornment={
									loadingFetchWhatsapp && (
										<CircularProgress 
											size={16}
											className={classes.progress}
										/>
									)
								}
							>
								{whatsApps.map((whatsapp) => (
									<MenuItem 
										key={whatsapp.id} 
										value={whatsapp.id}
									>
										{whatsapp.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={!selectedContact || !selectedWhatsappId}
						onClick={() => handleSaveTicket(selectedContact)}
						color="primary"
						loading={loading}
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default NewTicketModal;
