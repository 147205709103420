import React, { useState } from "react";

import {
  makeStyles,
  Paper,
  Box,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";

import {
  ReplyRounded,
  CloseRounded
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import ForwardMessageModal from "./ForwardMessageModal";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#EEEEEE",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    width: "100%",
    padding: "7px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  rightContent: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  replyIcon: {
    transform: "rotateY(180deg)",
  },
}));

const shouldDisableForward = (selectedMessages) => {
  if (selectedMessages.length === 0) return true;

  for (const message of selectedMessages) {
    if (message.isDeleted === true) return true;
  }

  return false;
};

const MessageOptionsFooter = ({ onResetAllProperties, selectedMessages }) => {
  const classes = useStyles();

  const messagesSize = selectedMessages.length;

  const [forwardMessageModalVisible, setForwardMessageModalVisible] = useState(false);

  const handleOpenFormardMessageModal = () => {
    setForwardMessageModalVisible(true);
  }
  const handleCloseFormardMessageModal = () => {
    setForwardMessageModalVisible(false);
  }

  return (
    <Paper 
      elevation={0}
      className={classes.container}
    >
      <Box
        className={classes.rightContent}
      >
        <IconButton 
          aria-label="reset"
          onClick={onResetAllProperties}
        >
          <CloseRounded/>
        </IconButton>
        <Typography>
          {messagesSize}
          {messagesSize === 1 ? (
            i18n.t("messageOptionsFooter.selected")
          ) :  (
            i18n.t("messageOptionsFooter.selectedMultiple")
          )}
        </Typography>
      </Box>
      <Box>
        <Tooltip
          title={i18n.t("messageOptionsFooter.tooltip.forward")}
          placement="top"
        >
          <IconButton
            disabled={shouldDisableForward(selectedMessages)}
            aria-label="reply"
            onClick={handleOpenFormardMessageModal}
            color="primary"
          >
            <ReplyRounded className={classes.replyIcon}/>
          </IconButton>
        </Tooltip>
      </Box>

      <ForwardMessageModal
        visible={forwardMessageModalVisible}
        onClose={handleCloseFormardMessageModal}
        onResetMessageProperties={onResetAllProperties}
        selectedMessages={selectedMessages}
      />
    </Paper>
  );
};

export default MessageOptionsFooter;