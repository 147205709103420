import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import NewConnection from "../pages/NewConnection/";
import EditConnection from "../pages/EditConnection/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import NewUser from "../pages/NewUser";
import EditUser from "../pages/EditUser";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import NewQueue from "../pages/NewQueue/";
import EditQueue from "../pages/EditQueue/";
import About from "../pages/About/"
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import Chats from "../pages/Chats";
import URA from "../pages/URA";
import NewURA from "../pages/NewURA";
import EditURA from "../pages/EditURA";
import Announcements from "../pages/Announcements";
import NewAnnouncement from "../pages/NewAnnouncement";
import EditAnnouncement from "../pages/EditAnnouncement";
import Monitoring from "../pages/Monitoring";
import NewMonitoring from "../pages/NewMonitoring";
import EditMonitoring from "../pages/EditMonitoring";
import AdminPage from "./AdminPage";
import RootPage from "./RootPage";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={RootPage} isPrivate />
              
              <Route exact path="/tickets/:ticketId?" component={Tickets} isPrivate />
              <Route exact path="/quickAnswers" component={QuickAnswers} isPrivate />
              <Route exact path="/chats/:chatId?" component={Chats} isPrivate />
              <Route exact path="/contacts" component={Contacts} isPrivate />

              <AdminPage exact path="/settings" component={Settings} />
              <AdminPage exact path="/queues" component={Queues} />
              <AdminPage exact path="/queues/new" component={NewQueue} />
              <AdminPage exact path="/queues/edit/:id" component={EditQueue} />
              <AdminPage exact path="/about" component={About} />
              <AdminPage exact path="/dashboard" component={Dashboard} />
              <AdminPage exact path="/connections" component={Connections} />
              <AdminPage exact path="/connections/new" component={NewConnection} />
              <AdminPage exact path="/connections/edit/:id" component={EditConnection} />
              <AdminPage exact path="/users" component={Users} />
              <AdminPage exact path="/users/new" component={NewUser} />
              <AdminPage exact path="/users/edit/:id" component={EditUser} />
              <AdminPage exact path="/URA" component={URA} />
              <AdminPage exact path="/URA/new" component={NewURA}/>
              <AdminPage exact path="/URA/edit/:id" component={EditURA} />
              <AdminPage exact path="/announcements" component={Announcements} />
              <AdminPage exact path="/announcements/new" component={NewAnnouncement} />
              <AdminPage exact path="/announcements/edit/:id" component={EditAnnouncement} />
              <AdminPage exact path="/monitoring" component={Monitoring} />
              <AdminPage exact path="/monitoring/new" component={NewMonitoring} />
              <AdminPage exact path="/monitoring/edit/:id" component={EditMonitoring} />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={1000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
