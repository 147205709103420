import React from "react";

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";

export default function SelectUsers({
  options,
  setParticipantIds,
  value
}){

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={option => option.extension ? `${option.name} (${option.extension})` : option.name}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ minWidth: 250 }}
      name="userIds"
      fullWidth
      filterSelectedOptions
      clearOnBlur
      autoHighlight
      clearOnEscape
      value={value && value}
      onChange={(_, participantIds) => setParticipantIds(participantIds)}
      renderInput={params => (
        <TextField
          {...params}
          label={i18n.t("selectUsers.title")}
          variant="outlined"
        />
      )}
    />
  );
}