import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  useMediaQuery,
  makeStyles,
  useTheme,
  Collapse,
  Button,
  Hidden,
  Box,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import ConfigurationManager from "../ConfigurationManager";
import MainContainer from "../MainContainer";
import Title from "../Title";

const useStyles = makeStyles(() => ({
  contentWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    gap: "24px",
  },

  header: {
    display: "flex",
    alignItems: 'flex-start',
    flexDirection: "column",
  },

  backButton: {
    marginBottom: "32px",
  },

  configButton: {
    marginBottom: "8px",
  }
}))

const FormWrapper = ({ children, title  }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [toggleConfigManager, setToggleConfigManager] = useState(false);

  const handleToggleConfigManager = () => {
		setToggleConfigManager((prevState) => !prevState);
	};

  return (
    <MainContainer>
      {matches ? (
        <Collapse
          in={toggleConfigManager}
        >
          <ConfigurationManager
            onClick={handleToggleConfigManager}
          />
        </Collapse>
      ) : (
        <ConfigurationManager />
      )}
      <Box
        className={classes.contentWrapper}
      >
        <header
          className={classes.header}
        >
          <Button
            className={classes.backButton}
            onClick={() => history.goBack()}
            startIcon={<ChevronLeft />}
          >
            {i18n.t("formWrapper.buttons.back")}
          </Button>
          <Hidden mdUp>
            <Button
              className={classes.configButton}
              onClick={handleToggleConfigManager}
              size="small"
              startIcon={<ChevronRight />}
            >
              {i18n.t("mainDrawer.listItems.settings")}
            </Button>
          </Hidden>
          <Title>{title}</Title>
        </header>
        {children}
      </Box>
    </MainContainer>
  );
}

export default FormWrapper;