import React, { useEffect, useState } from "react";

import {
  makeStyles,
  Container,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";

import { toastError } from "../../messages/toasts";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    overflow: "hidden",
    height: 200,
  },
  firstPaper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "hidden",
    height: 200,
  },
  titleFirtPaperSection: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  seloFistPaper: {
    height: "100%",
  },
  danger: {
    color: "red",
  },
  input: {
    maxWidth: 75,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  text: {
    display: "flex",
    alignItems: "center",
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  link: {
    textDecoration: 'underline',
    color: '#0000EE',
    cursor: 'pointer',
  }
}));

const About = () => {
  const classes = useStyles();

  const [data, setData] = useState({});

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchData = async () => {
        try {
          const { data } = await api.get("/about");

          setData(data);
        } catch (err) {
          toastError(err);
        }
      };
      fetchData();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, []);

  return (
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={7}>
          <Paper className={classes.firstPaper}>
            <section className={classes.titleFirtPaperSection}>
              <Typography variant="h4" color="primary">
                {i18n.t("about.messages.plain.title")}
              </Typography>

              <p>{i18n.t("about.messages.plain.caption")}</p>
            </section>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Paper className={classes.paper}>
            <Typography variant="h6" color="primary">
              {i18n.t("about.messages.userInfo.users")}
            </Typography>
            <p className={data.usersCount > 5 ? classes.danger : undefined}>
              {data.usersCount}/5
            </p>

            <Typography variant="h6" color="primary">
              {i18n.t("about.messages.userInfo.administrators")}
            </Typography>
            <p className={data.administrators > 1 ? classes.danger : undefined}>
              {data.administrators}/1
            </p>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h4" color="primary">
              {i18n.t("about.messages.payment.title")}
            </Typography>
            <p>{i18n.t("about.messages.payment.form")}</p>
            <p>{i18n.t("about.messages.payment.charge")}</p>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" color="primary" align="center">
              {i18n.t("about.messages.version.title")}
            </Typography>
            <p>
              {i18n.t("about.messages.version.lib")}
              {data.libVersion}
            </p>
            <p>
              {i18n.t("about.messages.version.system")}
              {data.tagVersion}
            </p>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" color="primary" align="center">
              {i18n.t("about.messages.terms.title")}
            </Typography>

            <p>
              {i18n.t("about.messages.terms.click")}

              <span className={classes.link}>{i18n.t("about.messages.terms.here")}</span>

              {i18n.t("about.messages.terms.terms")}
            </p>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
