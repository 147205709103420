import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import UserForm from "../../components/UserForm";

import api from "../../services/api";

import {
  toastError,
  toastSuccess
} from "../../messages/toasts";

const EditUser = () => {
  const history = useHistory();
  const params = useParams();

  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`users/${params.id}`);
        setUser(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [params.id])

  const handleSubmit = async (formData) => {
    if (!params.id) return;

    const data = {
      ...formData,
      id: params.id,
    }

    try {
      await api.put(`/users/${params.id}`, data);
      toastSuccess(i18n.t("userModal.success"));
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <>
      {!loading && (
        <FormWrapper
          title={`${i18n.t("formWrapper.titles.edit")} ${user?.name}`}
        >
          <UserForm
            onSubmit={handleSubmit}
            user={user}
          />
        </FormWrapper>
      )}
    </>
  );
}

export default EditUser;