import { useEffect, useState } from "react";
import api from "../../services/api";

const useDownload = () => {
  const [file, setFile] = useState({
    url: null,
    name: ""
  });

  useEffect(() => {
    if (!file.url) return;

    const fetchDowload = async() => {
      const config = {responseType: "blob"};
      const { data } = await api.get(file.url, config);
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      const fileName = file.name.length > 0 ? file.name : href.split("/").pop();

      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setFile({});
    }

    fetchDowload();
  }, [file.url, file.name]);

  return [setFile];
};

export default useDownload;