import { Button, withStyles } from "@material-ui/core";

export const SuccessButton = withStyles(() => ({
  root: {
    color: "#e2f8e3",
    backgroundColor: "#44ce4b",
    '&:hover': {
      backgroundColor: "#2eae34",
    },
    textTransform: "none"
  },
}))(Button);