import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";

import openSocket from "../../services/socket-io";

import { toastError, toastSuccess } from "../../messages/toasts";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Collapse,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { 
  DeleteOutline,
  Edit
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfigurationManager from "../../components/ConfigurationManager";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";

import api from "../../services/api";

const useStyles = makeStyles(() => ({
  dataWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px"
  },

  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_QUEUES") {
    const queues = action.payload;
    const newQueues = [];

    queues.forEach((queue) => {
      const queueIndex = state.findIndex((q) => q.id === queue.id);
      if (queueIndex !== -1) {
        state[queueIndex] = queue;
      } else {
        newQueues.push(queue);
      }
    });

    return [...state, ...newQueues];
  }

  if (action.type === "UPDATE_QUEUES") {
    const queue = action.payload;
    const queueIndex = state.findIndex((u) => u.id === queue.id);

    if (queueIndex !== -1) {
      state[queueIndex] = queue;
      return [...state];
    } else {
      return [queue, ...state];
    }
  }

  if (action.type === "DELETE_QUEUE") {
    const queueId = action.payload;
    const queueIndex = state.findIndex((q) => q.id === queueId);
    if (queueIndex !== -1) {
      state.splice(queueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Queues = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [queues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toggleConfigManager, setToggleConfigManager] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/queue");
        dispatch({ type: "LOAD_QUEUES", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket();

    socket.on("queue", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUEUES", payload: data.queue });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUEUE", payload: data.queueId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedQueue(null);
  };

  const handleDeleteQueue = async (queueId) => {
    try {
      await api.delete(`/queue/${queueId}`);
      toastSuccess(i18n.t("Queue deleted successfully!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedQueue(null);
  };

  const handleToggleConfigManager = useCallback(() => {
		setToggleConfigManager((prevState) => !prevState);
	}, []);

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedQueue &&
          `${i18n.t("queues.confirmationModal.deleteTitle")} ${
            selectedQueue.name
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteQueue(selectedQueue.id)}
      >
        {i18n.t("queues.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      {matches ? (
        <Collapse
          in={toggleConfigManager}
        >
          <ConfigurationManager
            onClick={handleToggleConfigManager}
          />
        </Collapse>
      ) : (
        <ConfigurationManager />
      )}
      <Box
        className={classes.dataWrapper}
      >
        <MainHeader
          title="queues.title"
          onClick={handleToggleConfigManager}
        >
          <MainHeaderButtonsWrapper>
            <Link
              to="/queues/new"
            >
              <Button
                variant="contained"
                color="primary"
                >
                {i18n.t("queues.buttons.add")}
              </Button>
            </Link>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          elevation={0}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {i18n.t("queues.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("queues.table.color")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("queues.table.greeting")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("queues.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {queues.map((queue) => (
                  <TableRow key={queue.id}>
                    <TableCell align="center">{queue.name}</TableCell>
                    <TableCell align="center">
                      <div className={classes.customTableCell}>
                        <span
                          style={{
                            backgroundColor: queue.color,
                            width: 60,
                            height: 20,
                            alignSelf: "center",
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <div className={classes.customTableCell}>
                        <Typography
                          style={{ width: 300, align: "center" }}
                          noWrap
                          variant="body2"
                        >
                          {queue.greetingMessage}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/queues/edit/${queue.id}`}>
                        <IconButton
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      </Link>

                      <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedQueue(queue);
                          setConfirmModalOpen(true);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={4} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </Box> 
    </MainContainer>
  );
};

export default Queues;
