import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import openSocket from "../../services/socket-io";

import { 
  IconButton,
  makeStyles,
  Tooltip
} from "@material-ui/core";

import { 
  Replay,
  AccountTreeOutlined,
  PersonAdd,
  CompareArrowsOutlined
} from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";
import { toastError } from "../../messages/toasts";
import { i18n } from "../../translate/i18n";

import AddTicketParticipantModal from "../../components/AddTicketParticipantModal";
import TicketParticipantList from "../TicketParticipantList";
import isUserLoggedAdmin from "../../utils/isUserLoggedAdmin"
import TransferTicketModal from "../TransferTicketModal";
import ButtonWithSpinner from "../ButtonWithSpinner";
import TicketOptionsMenu from "../TicketOptionsMenu";
import useCurrentUrlId from "../../hooks/useCurrentUrlID";
import CloseTicketOptions from "../CloseTicketOptions";
import ShowTicketOwnerModal from "../ShowTicketOwnerModal";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  actionButtons: {
    marginRight: 6,
    flex: "none",
    alignSelf: "center",
    marginLeft: "auto",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  hover: {
    "&:hover": {
      color: "#33697B",
    },
    padding: "5px",
  },
}));

const TicketActionButtons = ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [participants, setParticipants] = useState(ticket?.userTicket || []);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [ticketExists, setTicketExists] = useState({});
  const [showTicketOwnerModalOpen, setShowTicketOwnerModalOpen] = useState(false);
  const { currentUrlIdRef } = useCurrentUrlId();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => {
			socket.emit("joinTickets", "open");
		});

    socket.on("ticket", (data) => {
      const participantList = data?.ticket?.userTicket;

      if (+currentUrlIdRef.current !== data?.ticket?.id) return;

      if (data.action === "addParticipant") {
        setParticipants(participantList);
      }

      if (data.action === "removeParticipant" || data.action === "transferOwner") {
        const newParticipantList = participantList?.filter((participant) => participant.user.id !== data.participantId);
        setParticipants(newParticipantList);
      }
    })

    return () => {
      socket.disconnect();
    }
  }, [setParticipants, currentUrlIdRef])

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleUpdateTicketStatus = async (_, status, userId, action, sendFarewellMessage) => {
    setLoading(true);
    try {
      const { data } = await api.put(`/tickets/${ticket.id}`, {
        status,
        userId: userId || null,
        action,
        sendFarewellMessage
      });

      if (data.ticketIsOpen) {
        setTicketExists(data.ticket);
        setShowTicketOwnerModalOpen(true);
        return;
      }

      if (status === "open") {
        history.push(`/tickets/${data.ticket.id}`);
      } else {
        history.push("/tickets");
      }
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenTransferModal = (e) => {
    setTransferTicketModalOpen(true);
  };

  const handleCloseTransferTicketModal = () => {
    setTransferTicketModalOpen(false);
  };

  const haveAccessToActions = () => {
    return (user.id === ticket.userId || isUserLoggedAdmin(user));
  }

  const handleCloseShowTicketOwnerModal = () => {
    setShowTicketOwnerModalOpen(false);
    setTicketExists({});
  };

  return (
    <div className={classes.actionButtons}>
      <ShowTicketOwnerModal
				open={showTicketOwnerModalOpen}
				onClose={handleCloseShowTicketOwnerModal}
				ticket={ticketExists}
			/>
      <AddTicketParticipantModal
        modalOpen={openModal}
        onClose={handleCloseModal}
        ticketId={ticket.id}
      />
      <TransferTicketModal
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticket={ticket}
      />
      {(ticket.status === "closed" && haveAccessToActions()) && (
        <Tooltip
          arrow
          placement="bottom"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id, "assigned")}
          title={i18n.t("messagesList.header.buttons.reopen")}
        >
          <IconButton
            component="span"
            loading={loading}
            className={classes.hover}
          >
            <Replay />
          </IconButton>
        </Tooltip>
      )}
      {ticket.status === "open" && (
        <>
          {ticket.userId === user.id && (
            <>
              {participants.length > 0 && (
                <TicketParticipantList
                  participants={participants}
                  setParticipants={setParticipants}
                />  
              )}
              <Tooltip
                arrow
                placement="bottom"
                title={i18n.t("messagesList.header.buttons.addParticipant")}
              >
                <IconButton
                  component="span"
                  loading={loading}
                  onClick={() => setOpenModal(true)}
                  className={classes.hover}
                >
                  <PersonAdd />
                </IconButton>
              </Tooltip>
            </>
          )}
          {
            haveAccessToActions() && (
              <>
                <Tooltip
                  arrow
                  placement="bottom"
                  title={i18n.t("messagesList.header.buttons.return")}
                >
                  <IconButton
                    component="span"
                    loading={loading}
                    onClick={(e) => handleUpdateTicketStatus(e, "pending", null, "pending")}
                    className={classes.hover}
                  >
                    <AccountTreeOutlined />
                  </IconButton>
                </Tooltip>
                <CloseTicketOptions
                  loading={loading}
                  handleUpdateTicketStatus={handleUpdateTicketStatus}
                />
                <Tooltip
                  arrow
                  placement="bottom"
                  title={i18n.t("ticketOptionsMenu.transfer")}
                >
                  <IconButton
                    component="span"
                    className={classes.hover}
                    onClick={handleOpenTransferModal}
                  >
                    <CompareArrowsOutlined />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <TicketOptionsMenu
              ticketParticipants={participants}
              ticket={ticket}
            />
        </>
      )}
      {ticket.status === "pending" && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id, "assigned")}
        >
          {i18n.t("messagesList.header.buttons.accept")}
        </ButtonWithSpinner>
      )}
    </div>
  );
};

export default TicketActionButtons;
