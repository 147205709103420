import { useState, useEffect } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const ToggleShowAll = (isTicket) => {
  const key = isTicket ? "showAllTickets" : "showAllChats"

  const [lastState, setLastState] = useLocalStorage(key, false);
  const [showAll, setShowAll] = useState(lastState || false);

  useEffect(() => {
    setLastState(showAll);
  }, [setLastState, showAll])

  const handleShowAllList = () => {
    setShowAll((prevState) => !prevState)
  }

  return { showAll, handleShowAllList };
}