import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  makeStyles,
  TextField,
  InputAdornment,
  List,
  Typography,
  ListItem,
  ListItemAvatar,
  Chip,
  ListItemSecondaryAction,
  Avatar,
  ListItemText,
  Checkbox,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import {
  Search
} from "@material-ui/icons";

import { i18n } from "../../../translate/i18n";

import useForwardReceivers from "./useForwardReceivers";

import DialogModal from "../../DialogModal";
import SnackbarMessage from "../../SnackbarMessage"
import ListSkeleton from "../../TicketsListSkeleton"

import api from "../../../services/api";

import { toastError } from "../../../messages/toasts";

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: "300px",
    overflowY: "auto",
  },

  disabledAspect: {
    opacity: "0.5",
    cursor: "not-allowed",
  }
}));

const ForwardMessageModal = (props) => {
  const {
    visible,
    onClose,
    onResetMessageProperties,
    selectedMessages
  } = props;

  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    isLoading,
    hasError,
    showSnackbar,
    selectedReceivers,
    hideSnackbar,
    handleSelectReceiver,
    renderList,
    cleanUpProperties
  } = useForwardReceivers("tickets", searchTerm);

  const classes = useStyles();

  const handleClose = () => {
    cleanUpProperties();
    onClose();
  }

  const handleForwardMessages = async () => {
    const messageIds = selectedMessages.map((message) => message.id);
    const ticketIds = selectedReceivers.map((receiver) => receiver.id);

    setIsSubmitting(true);

    try {
      await api.post("forwardmessage/tickets", {
        messageIds,
        ticketIds
      });
      
      history.push(`/tickets/${ticketIds[0]}`);
    } catch (err) {
      toastError(err);
    } finally {
      setIsSubmitting(false);
      onResetMessageProperties();
    }
  }

  return (
    <DialogModal
      visible={visible}
      maxWidth="xs"
      fullWidth={true}
      isloading={isSubmitting}
      onClose={handleClose}
      onConfirm={handleForwardMessages}
      initialDisabled={selectedReceivers.length === 0}
      title={i18n.t("messageOptionsFooter.formardMessageModal.title")}
      cancelLabel={i18n.t("messageOptionsFooter.formardMessageModal.cancel")}
      confirmLabel={i18n.t("messageOptionsFooter.formardMessageModal.actions.confirm")}
    >
      <SnackbarMessage
        open={showSnackbar}
        message={i18n.t("messageOptionsFooter.formardMessageModal.selectedMaximumReached")}
        onClose={hideSnackbar}
      />
      <TextField
        disabled={isLoading || hasError}
        margin="normal"
        fullWidth
        label={i18n.t("messageOptionsFooter.formardMessageModal.search")}
        id="search-contacts"
        InputProps={{
          startAdornment: <InputAdornment position="start">
                            <Search fontSize="small" color="action"/>
                          </InputAdornment>,
        }}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <List className={classes.list}>
        {isLoading && (<ListSkeleton/>)}

        {hasError ? (
          <Alert
            severity="error"
          >
            <Typography color="error">
              {i18n.t("messageOptionsFooter.formardMessageModal.errorFetchListContacts")}!
            </Typography>
          </Alert>
        ) : (
          renderList((receiver, { isSelected, hasFiveSelectedAndIsNotSelected }) => (
            <ListItem
              key={receiver.id}
              divider
              button
              dense
              className={`${hasFiveSelectedAndIsNotSelected && classes.disabledAspect}`}
              selected={isSelected}
              onClick={() => handleSelectReceiver(receiver)}
            >
              <ListItemAvatar>
                <Avatar
                  src={receiver.contact.profilePicUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={receiver.contact.name}
                secondary={receiver.contact.number}
              />
              <Chip
                label={receiver.whatsapp.name}
                size="small"
              /> 
              <ListItemSecondaryAction>
                <Checkbox
                  edge="end"
                  color="primary"
                  disabled={hasFiveSelectedAndIsNotSelected}
                  checked={isSelected}
                  onChange={() => handleSelectReceiver(receiver)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))
        )}
      </List>
    </DialogModal>
  );
}

export default ForwardMessageModal;