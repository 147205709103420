import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import subDays from "date-fns/subDays";

import { i18n } from "../../translate/i18n";

import { Autocomplete } from "@material-ui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { useFormik } from "formik";

import * as yup from "yup";

import useUserQueue from "../../hooks/useUserQueue";

import getUserOptionLabel from "../../utils/getUserOptionLabel";

const validationSchema = yup.object({
  startDate: yup
    .string()
    .required("teste")
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  filtersWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
  },
  filterItems: {
    width: 220,
  }
}));

const parseDate = (date) => {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  return year + "-" + month + "-" + day + "T" + hours + ":" + minutes;
};

const to = (endCustomDate, currentDateRangeSelection) => {
  if (currentDateRangeSelection === "custom") {
    return new Date(endCustomDate);
  }
  return new Date();
};

const from = (startCustomDate, currentDateRangeSelection) => {
  if (currentDateRangeSelection === "custom") {
    return new Date(startCustomDate);
  }
  const dateRange = {
    lastWeek: 6,
    lastMonth: 29,
    lastTwoMonths: 59,
  };
  const diff = dateRange[currentDateRangeSelection];
  return subDays(new Date(), diff);
};

const Filters = ({ setReqParams, isDownloading, isFetchingTickets }) => {
  const classes = useStyles();

  const today = new Date();

  const [endCustomDate, setEndCustomDate] = useState(parseDate(today));
  const [startCustomDate, setStartCustomDate] = useState(parseDate(today));

  const onChangeSelectedUser = (user) => {
    const userName = user?.name ? getUserOptionLabel(user) : "";
    setFieldValue("user", userName);
  };

  const onChangeSelectedQueue = (queue) => {
    const queueName = queue?.name || "";
    setFieldValue("queue", queueName);
  };

  const {
    selectedUser,
    selectedQueue,
    loadingUsers,
    loadingQueues,
    users,
    queues,
    handleFetchUsers,
    handleFetchQueues,
    handleSelectUserChange,
    handleSelectQueueChange,
  } = useUserQueue({ onChangeSelectedQueue, onChangeSelectedUser });

  const { values, setFieldValue, handleSubmit, handleChange, touched, errors } = useFormik({
    initialValues: {
      queue: "",
      user: "",
      status: "",
      duration: "lastWeek",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      senReqParams();
    },
  });

  const senReqParams = () => {
    setReqParams({
      userId: selectedUser?.id,
      queueId: selectedQueue?.id,
      status: values.status === "any" ? undefined : values.status,
      startDate: from(startCustomDate, values.duration),
      endDate: to(endCustomDate, values.duration),
    });
  };

  useEffect(() => {
    senReqParams();
  }, [values, endCustomDate, startCustomDate]);

  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <Box className={classes.filtersWrapper}>
        <Autocomplete
          size="small"
          id="select-user"
          options={users}
          getOptionLabel={(user) => getUserOptionLabel(user)}
          onOpen={handleFetchUsers}
          value={selectedUser}
          onChange={(_, value) => handleSelectUserChange(value)}
          onInputChange={(_, newValue) => setFieldValue("user", newValue)}
          inputValue={values.user}
          loading={loadingUsers}
          disabled={loadingUsers || isDownloading || isFetchingTickets}
          className={classes.filterItems}
          renderInput={(params) => (
            <TextField
              {...params}
              name="user"
              label={i18n.t("dashboard.filters.user")}
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          size="small"
          id="select-queue"
          options={queues}
          getOptionLabel={(queue) => queue.name}
          onOpen={handleFetchQueues}
          value={selectedQueue}
          onChange={(_, value) => handleSelectQueueChange(value)}
          onInputChange={(_, newValue) => setFieldValue("queue", newValue)}
          inputValue={values.queue}
          loading={loadingQueues}
          disabled={loadingQueues || isDownloading || isFetchingTickets}
          className={classes.filterItems}
          renderInput={(params) => (
            <TextField
              {...params}
              name="queue"
              label={i18n.t("dashboard.filters.queue")}
              variant="outlined"
            />
          )}
        />
        <FormControl
          size="small"
          variant="outlined"
          className={classes.filterItems}
          disabled={isDownloading || isFetchingTickets}
        >
          <InputLabel id="dashboard-status-label">
            {i18n.t("dashboard.filters.status.label")}
          </InputLabel>
          <Select
            id="status"
            label={i18n.t("dashboard.filters.status.label")}
            name="status"
            value={values.status}
            onChange={handleChange}
          >
            <MenuItem key="1" value="any">
              {i18n.t(`dashboard.filters.status.any`)}
            </MenuItem>
            <MenuItem key="2" value="pending">
              {i18n.t(`dashboard.filters.status.pending`)}
            </MenuItem>
            <MenuItem key="3" value="open">
              {i18n.t(`dashboard.filters.status.open`)}
            </MenuItem>
            <MenuItem key="4" value="closed">
              {i18n.t(`dashboard.filters.status.closed`)}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl
          size="small"
          variant="outlined"
          className={classes.filterItems}
          disabled={isDownloading || isFetchingTickets}
        >
          <InputLabel id="dashboard-duration-label">
            {i18n.t("dashboard.filters.duration.label")}
          </InputLabel>
          <Select
            id="duration"
            label={i18n.t("dashboard.filters.duration.label")}
            name="duration"
            value={values.duration}
            onChange={handleChange}
          >
            <MenuItem key="5" value="lastWeek">
              {i18n.t("dashboard.filters.duration.lastWeek")}
            </MenuItem>
            <MenuItem key="6" value="lastMonth">
              {i18n.t("dashboard.filters.duration.lastMonth")}
            </MenuItem>
            <MenuItem key="7" value="lastTwoMonths">
              {i18n.t("dashboard.filters.duration.lastThreeMonths")}
            </MenuItem>
            <MenuItem key="8" value="custom">
              {i18n.t("dashboard.filters.duration.custom")}
            </MenuItem>
          </Select>
        </FormControl>
        {values.duration === "custom" ? (
          <>
            <TextField
              variant="outlined"
              size="small"
              disabled={isDownloading || isFetchingTickets}
              id="startCustomDate"
              className={classes.filterItems}
              label={i18n.t("dashboard.filters.duration.startDate")}
              error={touched.startDate && Boolean(errors.startDate)}
              value={startCustomDate}
              onChange={(e) => setStartCustomDate(e.target.value)}
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
            -
            <TextField
              variant="outlined"
              disabled={isDownloading || isFetchingTickets}
              size="small"
              id="endCustomDate"
              className={classes.filterItems}
              label={i18n.t("dashboard.filters.duration.endDate")}
              value={endCustomDate}
              onChange={(e) => setEndCustomDate(e.target.value)}
              type="datetime-local"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </>
        ) : null}
      </Box>
    </form>
  );
};

export default Filters;
