import { useState, useEffect } from "react";
import { toastError } from "../../messages/toasts";

import api from "../../services/api";

const useChats = ({ pageNumber, searchParam, date, withUnreadMessages, showAll }) => {
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [chats, setChats] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchChats = async () => {
        try {
          const { data } = await api.get("/chats", {
            params: {
              searchParam,
              pageNumber,
              date,
              withUnreadMessages,
              showAll
            }
          });

          data.chats.forEach(chat => {
            chat.unreadMessages = chat.userMessage?.length;
          });

          setChats(data.chats)
          setHasMore(data.hasMore)
          setCount(data.count)
          setLoading(false)
        } catch (err) {
          setLoading(false)
          toastError(err)
        }
      };

      fetchChats();
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [pageNumber, searchParam, date, withUnreadMessages, showAll]);

  return { chats, loading, hasMore, count };
};

export default useChats;