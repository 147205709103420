import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Collapse,
  useTheme,
  useMediaQuery,
  TableBody,
  IconButton
} from "@material-ui/core";
import {
  DeleteOutline,
  Edit
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfigurationManager from "../../components/ConfigurationManager";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const useStyles = makeStyles(() => ({
  dataWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px"
  },
  description: {
    maxWidth: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

const Announcements = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [toggleConfigManager, setToggleConfigManager] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        try {
          const { data } = await api.get("announcements");
          setAnnouncements(data);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      })();
    }, 500)

    return () => {
      clearTimeout(delayDebounceFn);
    }
  }, [])

  const handleOpenConfirmationModal = (announcement) => {
    setConfirmationModalOpen(true);
    setSelectedAnnouncement(announcement);
  }

  const handleDeleteAnnouncement = async () => {
    if (!selectedAnnouncement) return;

    try {
      await api.delete(`announcements/${selectedAnnouncement.id}`);

      const filteredAnnouncements = announcements.filter(
        (announcement) => announcement.id !== selectedAnnouncement.id
      );

      setAnnouncements(filteredAnnouncements);
      toastSuccess(i18n.t("announcements.confirmationModal.success.message"));
    } catch (err) {
      toastError(err);
    }
  }

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
    setSelectedAnnouncement(null);
  }

  const handleToggleConfigManager = useCallback(() => {
		setToggleConfigManager((prevState) => !prevState);
	}, []);

  return (
    <MainContainer>
      <ConfirmationModal
        title={`${i18n.t("announcements.confirmationModal.title")} ${selectedAnnouncement?.name}`}
        open={confirmationModalOpen}
        onConfirm={handleDeleteAnnouncement}
        onClose={handleCloseConfirmationModal}
      >
        {i18n.t("announcements.confirmationModal.message")}
      </ConfirmationModal>
      
      {matches ? (
        <Collapse
          in={toggleConfigManager}
        >
          <ConfigurationManager
            onClick={handleToggleConfigManager}
          />
        </Collapse>
      ) : (
        <ConfigurationManager />
      )}
      <Box
        className={classes.dataWrapper}
      >
        <MainHeader
          title="announcements.title"
          onClick={handleToggleConfigManager}
        >
          <MainHeaderButtonsWrapper>
            <Link
              to="/announcements/new"
            >
              <Button
                variant="contained"
                color="primary"
              >
                {i18n.t("announcements.buttons.add")}
              </Button>
            </Link>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          elevation={0}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {i18n.t("announcements.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("announcements.table.description")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("announcements.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {announcements.map((announcement) => (
                <TableRow key={announcement.id}>
                  <TableCell align="center">{announcement.name}</TableCell>
                  <TableCell align="center" className={classes.description}>{announcement.description}</TableCell>
                  <TableCell align="center">
                    <Link to={`/announcements/edit/${announcement.id}`}>
                      <IconButton
                        size="small"
                      >
                        <Edit />
                      </IconButton>
                    </Link>

                    <IconButton
                      size="small"
                      onClick={() => handleOpenConfirmationModal(announcement)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </TableBody>
          </Table>
        </Paper>
      </Box> 
    </MainContainer>
  );
}

export default Announcements;