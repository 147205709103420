import React, { useRef, useState, useEffect } from "react";

import * as yup from "yup";

import {
  makeStyles,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Button,
  FormControl,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";

import { useFormik } from "formik";

import api from "../../services/api";

import { toastError } from "../../messages/toasts";

import { i18n } from "../../translate/i18n";

import { Autocomplete } from "@material-ui/lab";

import getUserOptionLabel from "../../utils/getUserOptionLabel";

const validationSchema = yup.object({
  name: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required"))
    .max(50, i18n.t("formWrapper.form.messages.errors.tooLong")),
  status: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  userId: yup
    .number()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  queueId: yup.number().nullable(),
});

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "16px",
  },

  submitButton: {
    marginTop: "16px",
  },
}));

const MonitoringForm = ({ onSubmit, monitoring }) => {
  const classes = useStyles();

  const [autocompleteUserOptions, setAutocompleteUserOptions] = useState([]);
  const [autocompleteQueueOptions, setAutocompleteQueueOptions] = useState([]);
  const [loadingUserOptions, setLoadingUserOptions] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(monitoring?.queue || null);
  const [selectedUser, setSelectedUser] = useState(monitoring?.user || null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!monitoring?.userId) return;

    (async () => {
      try {
        const { data: fetchedUser } = await api.get(`/users/${monitoring.userId}`);
        const userQueues = fetchedUser?.queues;
        if (userQueues) {
          setAutocompleteQueueOptions(userQueues);
        }
      } catch (err) {
        toastError(err);
      } finally {
        setLoadingUserOptions(false);
      }
    })();
  }, [monitoring?.userId])

  const { values, handleChange, setFieldValue, handleSubmit, touched, errors } =
    useFormik({
      initialValues: {
        name: monitoring?.name || "",
        status: monitoring?.status || "",
        user: monitoring?.user?.name || "",
        userId: monitoring?.userId || "",
        queue: monitoring?.queue?.name || "",
        queueId: monitoring?.queueId || "",
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        if (values.queueId === "") {
          values.queueId = null;
        }
        await onSubmit(values);
      },
    });

  function clearQueueValues() {
    setSelectedQueue(null);
    setFieldValue("queue", "");
    setFieldValue("queueId", "");
  }

  function clearUserValues() {
    setSelectedUser(null);
    setFieldValue("user", "");
    setFieldValue("userId", "");
  }

  function handleSelectQueueChange(queue, reason) {
    switch (reason) {
      case "select-option":

        setSelectedQueue(queue);
        setFieldValue("queueId", queue.id);
        setFieldValue("queue", queue.name);

        const queueUsers = queue?.users;
        if (queueUsers) {
          clearUserValues();
          setAutocompleteUserOptions(queueUsers);
        }
        break;
      case "clear":
        clearQueueValues();
        break;
      default:
        break;
    }
  }

  
  async function handleFetchUsers() {
    const shouldntFetchUser =
    autocompleteUserOptions.length > 0;
    if (shouldntFetchUser) {
      return;
    }

    setLoadingUserOptions(true);
    try {
      const { data } = await api.get("users");
      setAutocompleteUserOptions(data.users);
    } catch (err) {
      toastError(err);
    } finally {
      setLoadingUserOptions(false);
    }
  }

  function handleClearUserAction() {
    clearUserValues();
    clearQueueValues();
    setAutocompleteQueueOptions([]);
  }

  function handleSelectUserChange(user, reason) {
    switch (reason) {
      case "select-option":

        setSelectedUser(user);
        setFieldValue("user", getUserOptionLabel(user));
        setFieldValue("userId", user.id);

        const userQueues = user?.queues;
        if (userQueues) {
          clearQueueValues();
          setAutocompleteQueueOptions(userQueues);
        }
        break;
      case "clear":
        handleClearUserAction();
        break;
      default:
        break;
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        fullWidth
        id="name"
        label={i18n.t("formWrapper.form.label.name")}
        name="name"
        variant="outlined"
        value={values.name}
        onChange={handleChange}
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
      />
      <FormControl
        variant="outlined"
        fullWidth
        error={touched.status && Boolean(errors.status)}
      >
        <InputLabel id="monitoring-status-label">
          {i18n.t("formWrapper.form.label.monitoringStatus.label")}
        </InputLabel>
        <Select
          id="status"
          labelId="monitoring-status-label"
          label={i18n.t("formWrapper.form.label.monitoringStatus.label")}
          name="status"
          className={classes.selectField}
          value={values.status}
          onChange={handleChange}
        >
          <MenuItem key="1" value="open">
            {i18n.t(`formWrapper.form.label.monitoringStatus.open`)}
          </MenuItem>
          <MenuItem key="2" value="closed">
            {i18n.t(`formWrapper.form.label.monitoringStatus.closed`)}
          </MenuItem>
        </Select>
        <FormHelperText>{touched.status && errors.status}</FormHelperText>
      </FormControl>
      <Autocomplete
        id="select-user"
        fullWidth
        options={autocompleteUserOptions}
        getOptionLabel={(user) => getUserOptionLabel(user)}
        onOpen={handleFetchUsers}
        onChange={(_, value, reason) => handleSelectUserChange(value, reason)}
        value={selectedUser}
        onInputChange={(event) => handleChange(event)}
        inputValue={values.user}
        loading={loadingUserOptions}
        error={(touched.user && Boolean(errors.user)) || undefined}
        disabled={loadingUserOptions || isSubmitting}
        renderInput={(params) => (
          <TextField
            {...params}
            name="user"
            label={i18n.t("formWrapper.form.label.monitoringStatus.owner")}
            variant="outlined"
            error={(touched.userId && Boolean(errors.userId)) || undefined}
            helperText={touched.userId && errors.userId}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingUserOptions && <CircularProgress size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <Autocomplete
        id="select-queue"
        fullWidth
        options={autocompleteQueueOptions}
        getOptionLabel={(queue) => queue.name}
        onChange={(_, value, reason) => handleSelectQueueChange(value, reason)}
        value={selectedQueue}
        getOptionSelected={(option, value) => option.id === value.id}
        onInputChange={(event) => handleChange(event)}
        inputValue={values.queue}
        error={touched.queue && Boolean(errors.queue)}
        disabled={isSubmitting}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              name="queue"
              label={i18n.t("formWrapper.form.label.monitoringStatus.queue")}
              variant="outlined"
              error={touched.queueId && Boolean(errors.queueId) ? true : undefined}
              helperText={touched.queueId && errors.queueId}
              InputProps={{
                ...params.InputProps,
                endAdornment: <>{params.InputProps.endAdornment}</>,
              }}
            />
          </>
        )}
      />
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
      >
        {i18n.t("formWrapper.form.buttons.save")}
      </Button>
    </form>
  );
};

export default MonitoringForm;
