import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { i18n } from "../../translate/i18n";
import { toastError } from "../../messages/toasts";
import api from "../../services/api";
import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  }
});

const rowsBuilder = (tickets) => {
  const ticketsData = [];
  if (!tickets) {
    return ticketsData;
  }
  tickets.forEach((ticket) => {
    ticketsData.push({
      id: ticket.id,
      user: ticket?.user?.name || i18n.t(`dashboard.ticketList.user.noUser`),
      queue:
        ticket?.queue?.name || i18n.t(`dashboard.ticketList.queue.noQueue`),
      whatsapp: ticket?.whatsapp?.name || "",
      status: ticket?.status
        ? i18n.t(`dashboard.ticketList.status.${ticket?.status}`)
        : "",
      client: ticket?.contact
        ? `${ticket?.contact?.name} (${ticket?.contact?.number})`
        : "",
      date: new Date(ticket?.updatedAt).toLocaleString(i18n.language),
    });
  });
  return ticketsData;
};

export default function TicketsDataGrid({ filtersParams, SetIsFetchingTickets }) {

  const classes = useStyles();

  const PAGE_SIZE = 20
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  
  useEffect(() => {
    SetIsFetchingTickets(loading)
  }, [loading])

  const fetchTickets = async () => {
    try {
      const {
        data: { count, tickets },
      } = await api.get("/reports/tickets", {
        params: { ...filtersParams, pageNumber: page + 1, pageSize: PAGE_SIZE },
      });
      setCount(count);
      return tickets;
    } catch (error) {
      toastError(error);
      return null;
    }
  };

  useEffect(() => {
    if (!filtersParams?.startDate || !filtersParams?.endDate) return;

    let active = true;

    (async () => {
      setLoading(true);
      const tickets = await fetchTickets();

      if (!active || !tickets) {
        setLoading(false);
        return;
      }
      const newRows = rowsBuilder(tickets)
      setRows(newRows);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [filtersParams, page]);

  const columns = [
    {
      field: "id",
      headerName: i18n.t("dashboard.ticketList.ticket.label"),
      flex: 0.6,
    },
    {
      field: "user",
      headerName: i18n.t("dashboard.ticketList.user.label"),
      flex: 0.6,
    },
    {
      field: "queue",
      headerName: i18n.t("dashboard.ticketList.queue.label"),
      flex: 0.6,
    },
    {
      field: "whatsapp",
      headerName: i18n.t("dashboard.ticketList.whatsapp.label"),
      flex: 0.6,
    },
    {
      field: "status",
      headerName: i18n.t("dashboard.ticketList.status.label"),
      flex: 0.7,
    },
    {
      field: "client",
      headerName: i18n.t("dashboard.ticketList.client.label"),
      flex: 1,
    },
    {
      field: "date",
      headerName: i18n.t("dashboard.ticketList.date.label"),
      flex: 1,
    },
  ];

  return (
    <div className={classes.container}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={PAGE_SIZE}
        paginationMode="server"
        rowCount={count}
        loading={loading}
        onPageChange={(newPage) => setPage(newPage)}
        autoHeight
        rowHeight={25}
        headerHeight={35}
      />
      {count > 0 ? (<Typography component="h2" variant="h6" color="primary" gutterBottom>
        {`${count} ${i18n.t("dashboard.ticketList.results")}`} 
      </Typography>) : null}
    </div>
  );
}
