import React, { useContext, useEffect, useState} from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import { toastError, toastSuccess } from "../../messages/toasts";
import { AuthContext } from "../../context/Auth/AuthContext";

import ButtonWithSpinner from "../ButtonWithSpinner";
import SelectUsers from "../SelectUsers";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const AddTicketParticipantModal = ({
  modalOpen,
  onClose,
  ticketId
}) => {
  const { user } = useContext(AuthContext);
  
  const [options, setOptions] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    if (!modalOpen) {
      return;
		}
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data: userListData } = await api.get("users");

          const { data: { ticket: ticketData} }  = await api.get(`/tickets/${ticketId}`);

          const userTicketIds = ticketData.userTicket.map((user) => user.userId);
          
					const notTicketParticipantUsers = userListData.users.filter((u) => u.id !== user.id && !userTicketIds.includes(u.id));
          
					setOptions(notTicketParticipantUsers);
				} catch (err) {
					toastError(err);
				}
			};
			fetchUsers();
		}, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [modalOpen, user.id, ticketId]);

  const isButtonStartDisabled = usersToAdd.length === 0;
  
  const handleSaveChat = async () => {
    if (!usersToAdd) return;

    setLoading(true);
    
		try {
      const addParticipantsIds = usersToAdd.map(user => user.id);

			await api.post(`/tickets/${ticketId}/participant`, { 
        participantIds: addParticipantsIds
      });

      toastSuccess();
		} catch (err) {
      toastError(err);
		} finally {
      setLoading(false);
      handleClose();
    }
	};

  const handleClose = () => {
    onClose();
    setUsersToAdd([]);
    setOptions([]);
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
    >
      <DialogTitle>{i18n.t("addChatParticipant.title")}</DialogTitle>
      <DialogContent dividers>
        <SelectUsers options={options} setParticipantIds={setUsersToAdd}/>
      </DialogContent>
      <DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
            {i18n.t("addChatParticipant.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						color="primary"
            disabled={isButtonStartDisabled}
            loading={loading}
            onClick={handleSaveChat}
					>
            {i18n.t("addChatParticipant.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
    </Dialog>
  );
}

export default AddTicketParticipantModal;