import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  useFormik
} from "formik";

import {
	CircularProgress,
	DialogContent,
	DialogActions,
	DialogTitle,
	makeStyles,
	TextField,
	Dialog,
	Button,
 } from "@material-ui/core";

 import {
	Autocomplete
} from "@material-ui/lab";

import api from "../../services/api";

import { toastError } from "../../messages/toasts";

import { i18n } from "../../translate/i18n";
import useUserQueue from "../../hooks/useUserQueue";
import getUserOptionLabel from "../../utils/getUserOptionLabel";

const useStyles = makeStyles((theme) => ({
	dialogContent: {
		minWidth: "150px",
		width: "350px",
	}
}));

export default function TransferTicketModal(props) {
  const {
		modalOpen,
		onClose,
		ticket
	} = props;
  
  const onChangeSelectedQueue = (queue) => {
    const queueName = queue?.name || ""
    setFieldValue("queue", queueName);
  }

  const onChangeSelectedUser = (user) => {
    const userName = user?.name ? getUserOptionLabel(user) : ""
    setFieldValue("user", userName);
  }

  const {
    users,
    queues,
    loadingQueues,
    loadingUsers,
    selectedQueue,
    selectedUser,
    handleFetchQueues,
    handleSelectQueueChange,
    handleSelectUserChange,
    handleFetchUsers,
    clearAllValues
  } = useUserQueue({ onClose, onChangeSelectedQueue, onChangeSelectedUser });

	const history = useHistory();

	const classes = useStyles();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const { 
		values,
		setFieldValue,
		handleSubmit
	 } = useFormik({
		initialValues: {
			queue: "",
			user: ""
		},
		onSubmit: async () => {
			setIsSubmitting(true);

			try {
				const setTicketAction = selectedUser?.id ? {
					action: "transferOwner"
				} : {
					action: "pending",
					status: "pending"
				};

				let data = {
					queueId: selectedQueue?.id || null,
					userId: selectedUser?.id || null,
					...setTicketAction,
				};

				await api.put(`/tickets/${ticket.id}`, data);
			} catch (err) {
				toastError(err);
			} finally {
				setIsSubmitting(false);
				history.push("/tickets");
			}
		}
	})

	return (
		<Dialog
			open={modalOpen}
			onClose={clearAllValues}
			aria-labelledby="transfer-ticket-dialog-title"
			maxWidth="xs"
		>
			<DialogTitle
				id="transfer-ticket-dialog-title"
			>
				{i18n.t("transferTicketModal.title")}
			</DialogTitle>
			<DialogContent
				className={classes.dialogContent}
			>
				<form
					onSubmit={handleSubmit}
				>
					<Autocomplete
						id="select-queue"
						fullWidth
						options={queues}
						getOptionLabel={(queue) => queue.name}
						onOpen={handleFetchQueues}
						onChange={(_, value, reason) => handleSelectQueueChange(value, reason)}
						value={selectedQueue}
						onInputChange={(_, newValue) => setFieldValue('queue', newValue)}
						inputValue={values.queue}
						loading={loadingQueues}
						disabled={loadingQueues || isSubmitting}
						renderInput={(params) => (
							<TextField
								{...params}
								name="queue"
								label={i18n.t("transferTicketModal.fieldQueueLabel")}
								variant="outlined"
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loadingQueues && <CircularProgress size={20}/>}
											{params.InputProps.endAdornment}
										</>
									)
								}}
							/>
						)}
					/>

					<Autocomplete
						id="select-user"
						fullWidth
						options={users}
						getOptionLabel={(user) => getUserOptionLabel(user)}
						onOpen={handleFetchUsers}
						onChange={(_, value) => handleSelectUserChange(value)}
						value={selectedUser}
						onInputChange={(_, newValue) => setFieldValue('user', newValue)}
						inputValue={values.user}
						loading={loadingUsers}
						disabled={loadingUsers || isSubmitting}
						renderInput={(params) => (
							<TextField
								{...params}
								name="user"
								label={i18n.t("transferTicketModal.fieldUserLabel")}
								variant="outlined"
								margin="normal"
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loadingUsers && <CircularProgress size={20} />}
											{params.InputProps.endAdornment}
										</>
									)
								}}
							/>
						)}
					/>
					<DialogActions>
						<Button
							variant="text"
							color="inherit"
							onClick={clearAllValues}
							disabled={isSubmitting}
						>
							{i18n.t("transferTicketModal.buttons.cancel")}
						</Button>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={(!selectedQueue && !selectedUser) || isSubmitting}
						>
							{i18n.t("transferTicketModal.buttons.ok")}
						</Button>
					</DialogActions>
				</form>
			</DialogContent>
		</Dialog>
	);
};