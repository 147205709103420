import React from "react";
import {
  Snackbar
} from "@material-ui/core"

const SnackbarMessage = ({ message, open, onClose }) => {
  const handleClose = () => {
    onClose();
  }

  return (
    <Snackbar
      anchorOrigin={{ 
        vertical: "bottom",
        horizontal: "center"
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
    />
  )
}

export default SnackbarMessage;