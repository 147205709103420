import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toastError } from "../../messages/toasts";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	limitContactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
		paddingRight: 80,
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
		paddingRight: 0,
	},

	LastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 20,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 120,
	},

  limitContactName: {
    maxWidth: "60%",
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

	noLimitContactName: {
		paddingRight: 120,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
	},

	newMessagesCount: {
		alignSelf: "center",
		marginLeft: "auto",
		marginRight: 8,
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		right: 10,
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
		borderRadius: "0 7px 7px 0"
	},

	channelIcon: {
		position: "absolute",
		right: 85,
		fill: "#7C7C7C",
		width: 45,
	},

	limitChannelIcon: {
		position: "absolute",
		right: 150,
		fill: "#7C7C7C",
		width: 45,
	},

	userTag: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "5px",
    height: 14,
    fontSize: "0.8em",
    padding: "0px 10px 0px 10px",
    overflow: "Hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "40%",
    position: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
	},

  contactInfo: {
    display: "flex",
    maxWidth: "80%",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column", // Change to a column layout on small screens
      alignItems: "flex-start", // Adjust alignment for better readability
    },
  },
}));

const TicketListItem = ({ ticket, handleSwitchToOpenTab }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);


	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
				action: "assigned"
			});

			handleSwitchToOpenTab();
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};

  const shouldRenderLabel = ticket.whatsappId && ticket?.whatsapp?.label

	return (
		<div
			key={ticket.id}
		>
			<ListItem
				dense
				button
				onClick={e => {
					if (ticket.status === "pending") return;
					handleSelectTicket(ticket.id);
				}}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
			>
				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || i18n.t("noQueue")}>
					<span
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					></span>
				</Tooltip>
				<ListItemAvatar>
					<Avatar src={ticket?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={ticket.status === "pending" ? classes.limitContactNameWrapper : classes.contactNameWrapper }>
							<div className={classes.contactInfo}>
                <Typography
  								noWrap
  								component="span"
  								variant="body2"
  								color="textPrimary"
  								className={shouldRenderLabel ? classes.limitContactName : classes.noLimitContactName}
                  >
  								{ticket?.contact?.name}
  							</Typography>
                {shouldRenderLabel && (
                    <div
                      noWrap
                      className={classes.userTag}
                      title={i18n.t("ticketsList.connectionTitle")}
                    >
                      {ticket.whatsapp?.label}
                    </div>
                )}
              </div>
							{ticket.lastMessage && (
                <div>
                  <Typography
  									className={classes.LastMessageTime}
  									component="span"
  									variant="body2"
  									color="textSecondary"
                    >
  									{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
  										<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                      ) : (
                        <>{format(parseISO(ticket.updatedAt), "dd/MM/yy")}</>
                        )}
  								</Typography>
                </div>
              )}
						</span>
					}
					secondary={
						<span className={ticket.status === "pending" ? classes.limitContactNameWrapper : classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{ticket.lastMessage ? (
									<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>
              <Badge
                overlap="rectangular"
							  className={classes.newMessagesCount}
							  badgeContent={ticket.unreadMessages}
							  classes={{
								  badge: classes.badgeStyle,
							  }}
						  />

							{ticket.status === "closed" && (
								<Badge
                  overlap="rectangular"
									className={classes.closedBadge}
									badgeContent={"Finalizado"}
									color="primary"
								/>
							)}
						</span>
					}
				/>
				{ticket.status === "pending" && (
					<ButtonWithSpinner
						color="primary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleAcepptTicket(ticket.id)}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
				)}
			</ListItem>
			<Divider variant="inset" component="li" />
		</div>
	);
};

export default TicketListItem;
