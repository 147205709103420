import React, { useContext, useEffect, useMemo, useState, useRef} from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"

import {
  Badge,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Tooltip,
} from "@material-ui/core"

import {
  AssessmentOutlined,
  ContactPhoneOutlined,
  ContactSupportOutlined,
  InfoOutlined,
  QuestionAnswerOutlined,
  SettingsOutlined,
  SmsOutlined,
  WhatsApp
} from "@material-ui/icons/"

import { AuthContext } from "../context/Auth/AuthContext"

import { i18n } from "../translate/i18n"

import { Can } from "../components/Can"
import UserFeedbackModal from "../components/UserFeedbackModal"
import useTicketNotification from "../hooks/useTicketNotification";
import useChatNotification from "../hooks/useChatNotification";
import { useLocalStorage } from "../hooks/useLocalStorage";

import { getDocumentTitle } from "../config";

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 65px)'
  },
  mainList: {
    height: '100vh',
  },
  color: {
    color: theme.palette.third.main,
    opacity: '0.9',
  },
  submenu: {
    backgroundColor: '#275666',
  }
}))

const ListItemLink = props => {
  const { icon, primary, to} = props

  const renderLink = useMemo(
    () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} />
        )),
    [to]
  )

  return (
    <ul style={{width: '100%'}}>
      <ListItem button component={renderLink}>
        {icon && (<ListItemIcon>{icon}</ListItemIcon>)}
        <ListItemText primary={primary} />
      </ListItem>
    </ul>
  )
}

const ListMenuItem = props => {
  const { 
    link, 
    lastPageSelected, 
    icon, 
    primary, 
    drawerOpen, 
    submenu,
    onClick
  } = props;
  const classes = useStyles();

  return (
    <ul 
      className={ submenu && (classes.submenu) }
      onClick={onClick}
    >
      <Tooltip 
        arrow
        title={primary}
        placement="right" 
        disableFocusListener = {drawerOpen}
        disableHoverListener = {drawerOpen}
        disableTouchListener = {drawerOpen}
      >
          <ListItem
            selected={lastPageSelected === link}
            style={{padding: 0, backgroundColor: lastPageSelected === link && "#0F6380"}}
          >
            <ListItemLink
              to={`/${link}`}
              primary={primary}
              icon={icon}
              submenu = {submenu}
            />
          </ListItem>
      </Tooltip>
    </ul>
  )
}

const ListItemModal = props => {
  const { icon, primary, setFeedbackModalOpen, drawerOpen } = props

  const handleFeedbackModalOpen = () => setFeedbackModalOpen(true)

  return (
    <li>
      <Tooltip 
        arrow
        title={primary}
        placement="right" 
        disableFocusListener = {drawerOpen}
        disableHoverListener = {drawerOpen}
        disableTouchListener = {drawerOpen}
      >
        <ListItem button onClick={handleFeedbackModalOpen}>
          {icon && (<ListItemIcon>{icon}</ListItemIcon>)}
          <ListItemText primary={primary} />
        </ListItem>
      </Tooltip>
    </li>
  )
}

const ListItems = props => {
  const history = useHistory();
  const currentPageOpen = history.location.pathname.split('/')[1];
  const currentPageOpenRef = useRef(currentPageOpen);

  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)
  const {notifications: ticketNotifications} = useTicketNotification();
  const {notifications: chatNotifications} = useChatNotification();
  const [lastPageSelected, setLastPageSelected] = useLocalStorage("lastPageOpen", "0");
  const lastConfigPageOpen = JSON.parse(localStorage.getItem("lastConfigPageOpen"));

  const DYNAMICALLY_DOCUMENT_TITLE = getDocumentTitle();

  useEffect(() => {
    currentPageOpenRef.current = currentPageOpen;
    
    if (!currentPageOpenRef.current) {
      setLastPageSelected("dashboard")
      return;
    }

    setLastPageSelected(currentPageOpenRef.current);
  }, [currentPageOpen, setLastPageSelected])

	const unreadMessagesCount = (ticketNotifications.length + chatNotifications.length);
	document.title = unreadMessagesCount > 0 ? 
  `(${unreadMessagesCount}) ${DYNAMICALLY_DOCUMENT_TITLE}` : 
  DYNAMICALLY_DOCUMENT_TITLE;

  return (
    <div className={classes.container}>
      <div className={classes.mainList}>
        <ListMenuItem
          onClick={() => {
            setLastPageSelected("tickets");
          }} 
          lastPageSelected={lastPageSelected}
          link="tickets"
          drawerOpen={props.drawerOpen}
          primary={i18n.t("mainDrawer.listItems.conversations")}
          icon={ 
            //Overlap is needed to avoid errors on material ui https://stackoverflow.com/questions/72159936/material-ui-warning
            <Badge overlap="rectangular" badgeContent={ticketNotifications.length} color="secondary">
              <WhatsApp className={classes.color}/>
            </Badge>
          }
        />
        <ListMenuItem
          onClick={() => {
            setLastPageSelected("chats");
          }} 
          lastPageSelected={lastPageSelected}
          link="chats"
          drawerOpen={props.drawerOpen}
          primary={i18n.t("mainDrawer.listItems.chat")}
          icon={ 
            <Badge overlap="rectangular" badgeContent={chatNotifications.length} color="secondary" >
                  <QuestionAnswerOutlined className={classes.color}/>
            </Badge>
          }
        />
        <ListMenuItem
          onClick={() => {
            setLastPageSelected("contacts");
          }} 
          lastPageSelected={lastPageSelected}
          link="contacts"
          drawerOpen={props.drawerOpen}
          primary={i18n.t("mainDrawer.listItems.contacts")}
          icon={<ContactPhoneOutlined className={classes.color}/>}
        />
        <ListMenuItem
          onClick={() => {
            setLastPageSelected("quickAnswers");
          }} 
          lastPageSelected={lastPageSelected}
          link="quickAnswers"
          drawerOpen={props.drawerOpen}
          primary={i18n.t("mainDrawer.listItems.quickAnswers")}
          icon={<SmsOutlined className={classes.color}/>}
        />
        <Can
          role={user.profile}
          perform="drawer-admin-items:view"
          yes={() => (
            <>
              <Divider />
              <ListSubheader color="inherit" inset>
                {i18n.t("mainDrawer.listItems.administration")}
              </ListSubheader>
              <ListMenuItem
                onClick={() => {
                  setLastPageSelected("about");
                }} 
                lastPageSelected={lastPageSelected}
                link="about"
                drawerOpen={props.drawerOpen}
                primary={i18n.t("mainDrawer.listItems.about")}
                icon={<InfoOutlined className={classes.color}/>}
              />
              <ListMenuItem
                onClick={() => {
                  setLastPageSelected("dashboard");
                }} 
                lastPageSelected={lastPageSelected}
                link="dashboard"
                drawerOpen={props.drawerOpen}
                primary={i18n.t("mainDrawer.listItems.reports")}
                icon={<AssessmentOutlined className={classes.color}/>}
              />
              <ListMenuItem
                onClick={() => {
                  setLastPageSelected(lastConfigPageOpen);
                }}
                lastPageSelected={lastPageSelected}
                link={lastConfigPageOpen ?? "connections"}
                drawerOpen={props.drawerOpen}
                primary={i18n.t("mainDrawer.listItems.settings")}
                icon={<SettingsOutlined className={classes.color}/>}
              />
            </>
          )}
        />
      </div>
      <div>
        <Divider />
        <ListItemModal
          primary={i18n.t("mainDrawer.listItems.contactUs")}
          icon={<ContactSupportOutlined className={classes.color}/>}
          setFeedbackModalOpen={setFeedbackModalOpen}
          drawerOpen= {props.drawerOpen}
        />

        <UserFeedbackModal open={feedbackModalOpen} onClose={() => setFeedbackModalOpen(false)}/>
      </div>
    </div>
  )
}

export default ListItems