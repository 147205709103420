import { useCallback, useRef, useState } from "react";

const useSelectMessages = () => {
  const isSelectionEnabledRef = useRef(false);
  const checkboxRefs = useRef(new Map());

  const [selectedMessages, setSelectedMessages] = useState([]);

  const handleRemoveSelectedMessage = useCallback((id) => {
    setSelectedMessages((prevState) => prevState.filter(
      (selectedMsg) => selectedMsg.id !== id)
    );
  }, [])

  const handleChangeSelectedMessage = useCallback((message) => {
    const checkboxElementRef = checkboxRefs.current; 
    const messageId = message.id;

    const alreadySelected = selectedMessages.some(
      (selectedMsg) => selectedMsg.id === messageId);

    if (alreadySelected) {
      checkboxElementRef.delete(messageId);
      handleRemoveSelectedMessage(messageId);
      return;
    }

    checkboxElementRef.set(messageId);

    setSelectedMessages((prevState) => [
      ...prevState,
      message,
    ]);
  }, [checkboxRefs, selectedMessages, handleRemoveSelectedMessage]);

  const getIsSelectionEnabledRef = useCallback(() => isSelectionEnabledRef.current, []);

  const setIsSelectionEnabledRef = useCallback((value) => isSelectionEnabledRef.current = value, [])

  const getCheckboxRefs = useCallback(() => checkboxRefs.current, []);

  const handleResetAllProperties = useCallback(() => {
    setIsSelectionEnabledRef(false);
    setSelectedMessages([]);
    checkboxRefs.current.clear();

  }, [setIsSelectionEnabledRef])

  return {
    selectedMessages,
    handleChangeSelectedMessage,
    getIsSelectionEnabledRef,
    setIsSelectionEnabledRef,
    getCheckboxRefs,
    handleResetAllProperties,
  }
}

export default useSelectMessages;