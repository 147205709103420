import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import {
	getFrondendUrl,
  getManifestShortName,
  getManifestName
} from "./config";

const myDynamicManifest = {
	"short_name": getManifestShortName(),
	"name": getManifestName(),
	"icons": [
		{
			"src": `${getFrondendUrl()}favicon.ico`,
			"sizes": "64x64 32x32 24x24 16x16",
			"type": "image/x-icon"
		},
		{
			"src": `${getFrondendUrl()}android-chrome-192x192.png`,
			"sizes": "192x192",
			"type": "image/png"
		}
	],
	"start_url": `${getFrondendUrl()}`,
	"display": "standalone",
	"theme_color": "#000000",
	"background_color": "#ffffff"
};

const stringManifest = JSON.stringify(myDynamicManifest);
const blob = new Blob([stringManifest], {type: "application/json"});
const manifestURL = URL.createObjectURL(blob);
document.querySelector("#my-manifest-placeholder").setAttribute("href", manifestURL);

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);
