import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

export const toastError = (err, autoClose = 1000) => {
	const errorMsg = err.response?.data?.message || err.response.data.error;
	if (errorMsg) {
		if (i18n.exists(`backendErrors.${errorMsg}`)) {
			toast.error(i18n.t(`backendErrors.${errorMsg}`), {
				toastId: errorMsg,
				autoClose,
			});
		} else {
			toast.error(errorMsg, {
				toastId: errorMsg,
				autoClose,
			});
		}
	} else {
		toast.error("An error occurred!", { autoClose, });
	}
};

export const toastSuccess = (success = i18n.t("DEFAULT_SUCCESS"), autoClose = 1000) => toast.success(success, { autoClose, });

export const toastInfo = (message, autoClose = 5000) => toast.info(message, {
	position: "top-right",
	autoClose,
	hideProgressBar: false,
	closeOnClick: true,
	pauseOnHover: true,
});
