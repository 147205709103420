import React from "react";
import useMediaUrl from "../../hooks/useMediaUrl";

const Audio = ({ mediaUrl }) => {
	const [blobUrl] = useMediaUrl(mediaUrl);

	return (
    <audio src={blobUrl} controls style={{
      width: 300
    }}>
      <source type="audio/mp3" />
    </audio>
	);
};

export default Audio;