import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import ConfirmationModal from "../ConfirmationModal";
import api from "../../services/api";

import { isTicketParticipant } from "../../services/ticket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toastError } from "../../messages/toasts";
import { i18n } from "../../translate/i18n";
import { Can } from "../Can";

import {
  makeStyles,
  Tooltip,
  IconButton
 } from "@material-ui/core";

import { 
  Delete,
  ExitToAppOutlined
} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  hover: {
    "&:hover": {
      color: "#33697B",
    },
    padding: "5px",
  },
}));

const TicketOptionsMenu = ({ ticketParticipants, ticket }) => {
  const classes = useStyles();
  const history = useHistory();

  const [confirmationOpen, setConfirmationOpen] = useState({
    delete: false,
    leave: false
  });

  const { user } = useContext(AuthContext);

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleLeaveTicket = async() => {
    try {
      await api.delete(`/tickets/${ticket.id}/participant/${user.id}`);

      history.push('/tickets');
    } catch (err) {
      toastError(err);
    }
  }

  const handleOpenConfirmationModal = (currentModalName) => {
    setConfirmationOpen((prevState) => ({
      ...prevState,
      [currentModalName]: true
    }));
  };

  return (
    <>
      <Can
        role={user.profile}
        perform="ticket-options:deleteTicket"
        yes={() => (
          <Tooltip
            arrow
            placement="bottom"
            title={i18n.t("ticketOptionsMenu.delete")}
          >
            <IconButton
              component="span"
              className={classes.hover}
              onClick={() => handleOpenConfirmationModal("delete")}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
      />
      {isTicketParticipant(ticketParticipants, user.id) && (
        <Tooltip
          arrow
          placement="bottom"
          title={i18n.t("ticketParticipant.confirmationModal.leaveTicket")}
        >
          <IconButton
            component="span"
            className={classes.hover}
            style={{
              color: "red"
            }}
            onClick={() => handleOpenConfirmationModal("leave")}
          >
            <ExitToAppOutlined />
          </IconButton>
        </Tooltip>
      )}
      <ConfirmationModal
        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")} ${ticket.id}`}
        open={confirmationOpen.delete}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteTicket}
      >
        {i18n.t("ticketOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <ConfirmationModal
        title={`${i18n.t("ticketParticipant.confirmationModal.leaveTicket")} ${ticket.id}`}
        open={confirmationOpen.leave}
        onClose={setConfirmationOpen}
        onConfirm={handleLeaveTicket}
      >
        {i18n.t("ticketParticipant.confirmationModal.leaveMessage")}
      </ConfirmationModal>
    </>
  );
};

export default TicketOptionsMenu;
