import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import AnnouncementForm from "../../components/AnnouncementForm";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const NewAnnouncement = () => {
  const params = useParams();
  const history = useHistory();

  const [announcement, setAnnouncement] = useState();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (!params.id) return;

    (async () => {
      try {
        const { data } = await api.get(`/announcements/${params.id}`);
        setAnnouncement(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [params.id])

  const handleSubmit = async (dataForm) => {
    if (!params.id) return;

    try {
      await api.put(`announcements/${params.id}`, dataForm);

      toastSuccess();
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      {!loading && (
        <FormWrapper
          title={`${i18n.t("formWrapper.titles.edit")} ${announcement?.name}`}
        >
          <AnnouncementForm
            onSubmit={handleSubmit}
            announcement={announcement}
          />
        </FormWrapper>
      )}
    </>
  );
}

export default NewAnnouncement;