import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes";

import { ptBR } from "@material-ui/core/locale";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

const useGlobalStyles = makeStyles({
  "@global": {
    "*": {
      boxSizing: "border-box",
      padding: 0,
      margin: 0,
      verticalAlign: "baseline",
      listStyle: "none",
      border: 0,
      fontFamily: "Roboto",
      textDecoration: "none",
    },

    img: {
      maxWidth: "100%",
    },
  },
});

const App = () => {
  useGlobalStyles();

  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#e8e8e8",
        },
      },
      palette: {
        primary: {
          main: "#0d2f39",
        },
        third: {
          main: "#FFF",
        },
        text: {
          primary: "#303030",
          light: "#F2F2F7",
        },
        grey: {
          500: "#F8F8F8",
          600: "#E1E3E6",
          700: "#757D8A",
        },
        error: {
          main: "#A82525",
          light: "#FFE8E8",
          300: "#E2B6B6",
        },
      },
      typography: {
        fontSize: 13,
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 480,
          md: 768,
          lg: 1024,
          xl: 1536,
        },
      },
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

export default App;
