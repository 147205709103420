import React from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import TicketsDataGrid from "./ticketsDataGrid";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "8px",
    border: "1px solid #AEAEAE",
    padding: "16px",
    height: "fit-content",
    gap: "8px",
    display: "flex",
    flexDirection: "column"
  },
}));

const TicketList = ({ filtersParams, GetIsFetchingTickets }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        {i18n.t("dashboard.ticketList.title")}
      </Typography>
      <TicketsDataGrid SetIsFetchingTickets={GetIsFetchingTickets} filtersParams={filtersParams} />
    </Box>
  );
};

export default TicketList;
