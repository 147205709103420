import React, { useContext, useEffect, useState} from "react";
import { useHistory } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { toastError, toastSuccess } from "../../messages/toasts";

import ButtonWithSpinner from "../ButtonWithSpinner";
import SelectUsers from "../SelectUsers";

const NewChatModal = ({
  modalOpen,
  onClose
}) => {
  const { user } = useContext(AuthContext);

  const history = useHistory();
  
  const [options, setOptions] = useState([]);
  const [groupParticipants, setGroupParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatName, setChatName] = useState("");

  
  useEffect(() => {
    if (!modalOpen) {
      return;
		}
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("users");
          
					const users = data.users.filter(u => u.id !== user.id);
          
					setOptions(users);
				} catch (err) {
					toastError(err);
				}
			};
			fetchUsers();
		}, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [modalOpen, user.id]);

  const isButtonStartDisabled = groupParticipants.length === 0 || (groupParticipants.length >= 2 && chatName.length < 3)
  
  const handleSaveChat = async () => {
    setLoading(true);
    
		try {
      const participantsIds = groupParticipants.map(user => user.id);

			const { data: chat } = await api.post("/chats", {
        participantIds:  [user.id, ...participantsIds],
        chatName: chatName
      });
      history.push(`/chats/${chat.id}`);
      
      toastSuccess();
		} catch (err) {
      toastError(err);
		} finally {
      setLoading(false);
      handleClose();
    }
	};

  const handleClose = () => {
    onClose();

    setGroupParticipants([])
    setChatName("")
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
    >
      <DialogTitle>{i18n.t("newTicketModal.title")}</DialogTitle>
      <DialogContent dividers>
        <SelectUsers options={options} setParticipantIds={setGroupParticipants}/>
        {groupParticipants.length > 1 && (
          <TextField
          required
          label={i18n.t("newTicketModal.groupName")}
          variant="outlined"
          style={{ width: 350, marginTop: 15 }}
          onChange={(e) => setChatName(e.target.value)}
          value={chatName}
          inputProps={{maxLength: 25}}
        />
        )}
      </DialogContent>
      <DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						color="primary"
            disabled={isButtonStartDisabled}
            loading={loading}
            onClick={handleSaveChat}
					>
					  {i18n.t("newTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
    </Dialog>
  );
}

export default NewChatModal;