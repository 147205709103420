import React, { useState } from "react";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { InfoOutlined, GetApp, CloudUploadOutlined } from "@material-ui/icons";

import { toastError, toastSuccess } from "../../messages/toasts";

const useStyles = makeStyles({
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 64,
  },
  templateWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  templateTitle: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  formControl: {
    width: 180,
  },
  downloadButton: {
    width: 180,
    textTransform: "none",
  },
  uploadButton: {
    width: 180,
    textTransform: "none",
  },
});

const ImportContactsModal = ({ open, onClose, onSuccess }) => {
  const classes = useStyles();

  const [template, setTemplate] = useState("");
  const [selectedCsv, setSelectedCsv] = useState();
  const [isValidCsv, setIsValidCsv] = useState(false);
  const [importing, setImporting] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const cannotImport = !isValidCsv || !template;

  const downloadTemplate = async () => {
    if (!template) {
      return;
    }
    const i18nLocale = localStorage.getItem("i18nextLng") || "en";
    try {
      setDownloading(true);
      const { data } = await api.get("contact-import/download", {
        params: { templateType: template, language: i18nLocale },
      });
      const blob = new Blob([data]);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = `${template}.csv`;
      link.click();
    } catch (error) {
      toastError(error);
    } finally {
      setDownloading(false);
    }
  };

  const importContacts = async () => {
    if (cannotImport) {
      return;
    }
    try {
      setImporting(true);
      const formData = new FormData();

      formData.append("csv", selectedCsv);
      const i18nLocale = localStorage.getItem("i18nextLng") || "en";

      await api.post("contact-import", formData, {
        params: { templateType: template, language: i18nLocale },
      });
      toastSuccess(i18n.t("importContactsModal.successMessage"), 5000);
      closeModal();
      onSuccess(true);
    } catch (error) {
      toastError(error, 5000);
    } finally {
      setImporting(false);
    }
  };

  const checkIsValidCsv = (csv) => {
    return csv.size < 1000000;
  };

  const handleChangeCsv = (e) => {
    if (!e.target.files) {
      return;
    }

    const csvs = Array.from(e.target.files);

    if (csvs.length > 0) {
      setIsValidCsv(checkIsValidCsv(csvs[0]));
      setSelectedCsv(csvs[0]);
    }
  };

  const handleChangeTemplate = (e) => {
    if (!e.target.value) {
      return;
    }
    setTemplate(e.target.value);
  };

  const closeModal = () => {
    onClose(false);
    setTemplate("");
    setSelectedCsv(null);
    setIsValidCsv(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle id="confirm-dialog">
        {i18n.t("importContactsModal.title")}
        <Typography>{i18n.t("importContactsModal.description")}</Typography>
      </DialogTitle>

      <DialogContent dividers className={classes.contentWrapper}>
        <Box className={classes.templateWrapper}>
          <Box className={classes.templateTitle}>
            <Typography>
              {" "}
              {i18n.t("importContactsModal.template.title")}
            </Typography>
            <InfoOutlined fontSize="small" />
          </Box>

          <FormControl
            variant="outlined"
            className={classes.formControl}
            size="small"
          >
            <InputLabel id="template-label">
              {i18n.t("importContactsModal.template.select")}
            </InputLabel>
            <Select
              id="template"
              labelId="template-label"
              label="Selecionar modelo"
              value={template}
              name="template"
              placeholder="Selecionar modelo"
              onChange={handleChangeTemplate}
            >
              <MenuItem key="1" value="Bradial">
                {i18n.t("importContactsModal.template.types.bradial")}
              </MenuItem>
              <MenuItem key="2" value="Google">
                {i18n.t("importContactsModal.template.types.google")}
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            className={classes.downloadButton}
            startIcon={<GetApp />}
            variant="outlined"
            disabled={!template || downloading}
            onClick={downloadTemplate}
          >
            {i18n.t("importContactsModal.template.download")}
          </Button>
        </Box>
        <Box className={classes.templateWrapper}>
          <Typography>{i18n.t("importContactsModal.upload.title")}</Typography>

          <input
            type="file"
            accept="text/csv"
            id="upload-button"
            style={{ display: "none" }}
            onChange={handleChangeCsv}
          />
          <label htmlFor="upload-button">
            <Button
              className={classes.uploadButton}
              startIcon={selectedCsv ? null : <CloudUploadOutlined />}
              variant="outlined"
              component="span"
            >
              {selectedCsv
                ? selectedCsv.name
                : i18n.t("importContactsModal.upload.chooseFile")}
            </Button>
            <FormHelperText>
              {(!isValidCsv && selectedCsv) && i18n.t("importContactsModal.invalidCSV")}
            </FormHelperText>
          </label>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeModal()}>
          {i18n.t("confirmationModal.buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            importContacts();
          }}
          color="primary"
          disabled={cannotImport || importing}
        >
          {i18n.t("confirmationModal.buttons.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportContactsModal;
