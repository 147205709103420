import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import Filters from "./filters";
import api from "../../services/api";
import { toastError } from "../../messages/toasts";
import { i18n } from "../../translate/i18n";

import { GetAppRounded } from "@material-ui/icons";
import { SuccessButton } from "../../components/buttons/SuccessButton";
import TicketList from "./ticketList";
import { differenceInDays } from "date-fns";
import CustomToolTip from "../../components/CustomToolTip";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  applyFilters: {
    width: "fit-content",
  },
  downloadWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const Dashboard = () => {
  const classes = useStyles();

  const [filtersParams, setFiltersParams] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isFetchingTickets, setIsFetchingTickets] = useState(true);
  const [cannotDownload, setCannotDownload] = useState(false);

  const getReqParams = (params) => {
    setFiltersParams(params);
  };

  const GetIsFetchingTickets = (params) => {
    setIsFetchingTickets(params);
  };

  const downloadTickets = async () => {
    if (cannotDownload) return;
    try {
      setIsDownloading(true);

      const currentDate = new Date();

      const i18nLocale = localStorage.getItem("i18nextLng") || "en";
      const { data } = await api.get("/reports/tickets", {
        params: { ...filtersParams, action: "download", language: i18nLocale },
        responseType: "blob",
      });
      const blob = new Blob([data]);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      link.download = `${i18n.t("dashboard.reportFileName")}-${currentDate.toLocaleString(i18nLocale)}.csv`;
      link.click();
    } catch (error) {
      toastError(error);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (filtersParams?.startDate || filtersParams?.endDate) {
      setCannotDownload(true);
    }

    const daysPeriod = differenceInDays(
      new Date(filtersParams.endDate),
      new Date(filtersParams.startDate)
    );
    if (daysPeriod > 60) {
      setCannotDownload(true);
    } else {
      setCannotDownload(false);
    }
  }, [filtersParams]);

  return (
    <div>
      <Box className={classes.container}>
        <Box className={classes.downloadWrapper}>
          <CustomToolTip content={i18n.t("dashboard.cannotDownload")} disabled={!cannotDownload}>
            <Box>
              <SuccessButton
                variant="contained"
                color="primary"
                size="large"
                onClick={downloadTickets}
                startIcon={<GetAppRounded />}
                disabled={cannotDownload || isDownloading}
              >
                {i18n.t("dashboard.download")}
              </SuccessButton>
            </Box>
          </CustomToolTip>
        </Box>
        <Filters
          setReqParams={getReqParams}
          isDownloading={isDownloading}
          isFetchingTickets={isFetchingTickets}
        />
        <TicketList
          GetIsFetchingTickets={GetIsFetchingTickets}
          filtersParams={filtersParams}
        />
      </Box>
    </div>
  );
};

export default Dashboard;
