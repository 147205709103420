import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { toastError, toastSuccess } from "../../messages/toasts";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import URAForm from "../../components/URAForm";

import api from "../../services/api";

const EditURA = () => {
  const history = useHistory();
  const params = useParams();

  const [ura, setUra] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`uras/${params.id}`);
        setUra(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [params.id])

  const handleSubmit = async (formData) => {
    try {
      const data = {
        name: formData.name,
        directDial: formData.directDial,
        description: formData.description,
        uraItems: formData.destinations,
        action: "update"
      };

      await api.put(`/uras/${params.id}`, data);
      toastSuccess(i18n.t("URA.success.message"));
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <>
      {!loading && (
        <FormWrapper
          title={`${i18n.t("formWrapper.titles.edit")} ${ura.name}`}
        >
          <URAForm
            onSubmit={handleSubmit}
            ura={ura}
          />
        </FormWrapper>
      )}
    </>
  )
}

export default EditURA;