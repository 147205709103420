import React, { useContext, useState } from "react";

import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import isEmailValid from "../../utils/isEmailValid";
import { getLoginPageLogo } from "../../config";
import useImage from "../../hooks/useImage";

import Detail from "../../assets/Detail.svg";
import CloseEye from "../../assets/icons/Eye.svg";
import OpenEye from "../../assets/icons/EyeSlash.svg";
import Notebook from "../../assets/Notebook.png";
import title from "../../assets/Title.svg";
import RightWave from "../../assets/Waveright.svg";
import TopWave from "../../assets/Wavetop.svg";

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  main: {
    width: "100%",
    height: "100vh",
    display: "flex",
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  title: {
    maxWidth: "48%",
    marginLeft: "15%",
    [breakpoints.up("xl")]: {
      maxWidth: "40%",
    },
  },
  detail: {
    maxWidth: "48%",
    margin: "5% 0",
    [breakpoints.up("xl")]: {
      maxWidth: "40%",
    },
  },
  notebook: {
    maxWidth: "60%",
    marginLeft: "10%",
    [breakpoints.up("xl")]: {
      maxWidth: "48%",
    },
  },
  rightContent: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    marginBottom: "3rem",
    width: "100%",
    [breakpoints.between("xs", "md")]: {
      maxWidth: "60%",
    },
    [breakpoints.up("md")]: {
      maxWidth: "40%",
    },
    [breakpoints.up("xl")]: {
      maxWidth: "30%",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [breakpoints.between("xs", "md")]: {
      maxWidth: "80%",
    },
    [breakpoints.up("md")]: {
      maxWidth: "50%",
    },
    [breakpoints.up("xl")]: {
      maxWidth: "40%",
    },
  },
  formGroup: {
    marginBottom: "1rem",
  },
  label: {
    color: palette.text.primary,
    marginBottom: "0.5rem",
  },
  input: {
    height: "3rem",
    width: "100%",
    backgroundColor: palette.grey[500],
    border: `2px solid ${palette.grey[500]}`,
    color: palette.text.primary,
    borderRadius: "0.5rem",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.08)",
    padding: "0 1rem",
    transition: "box-shadow 0.3s",
  },
  inputFocused: {
    boxShadow: "none",
  },
  inputError: {
    backgroundColor: palette.error.light,
    color: palette.error.main,
    border: `2px solid ${palette.error.main}`,
    boxShadow: "none",
  },
  icon: {
    cursor: "pointer",
  },
  helperText: {
    color: palette.error.main,
    marginTop: "0.2rem",
  },
  submitButton: {
    height: "3rem",
    background: palette.primary.main,
    color: palette.text.light,
    marginTop: "1rem",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: palette.primary.main,
      opacity: "0.9",
    },
    borderRadius: "8px",
  },
  topWave: {
    position: "absolute",
    top: 0,
    right: 0,
    [breakpoints.down("md")]: {
      width: "100%",
    },
    height: "15vh",
  },
  rightWave: {
    position: "absolute",
    right: 0,
    bottom: 0,
    maxWidth: "7vw",
    height: "67vh",
  },
}));

const Login = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width: 1024px)");

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const DYNAMICALLY_LOGO = getLoginPageLogo();

  const { loading, image } = useImage(DYNAMICALLY_LOGO);

  const isButtonDisabled =
    hasError || user.email.length === 0 || user.password.length === 0;

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });

    if (e.target.name === "email") {
      if (e.target.value && !isEmailValid(e.target.value)) {
        setHasError(true);
      } else {
        setHasError(false);
      }
    }
  };

  const handleChangeTypePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.main}>
      {matches && (
        <Grid xs={6} className={classes.leftContent} item>
          <img src={title} alt="Title" className={classes.title} />

          <img src={Detail} alt="Detail" className={classes.detail} />

          <img
            src={Notebook}
            alt="Bradial ticket chat preview screen"
            className={classes.notebook}
          />
        </Grid>
      )}
      <Grid xs={matches ? 6 : 12} className={classes.rightContent} item>
        <img src={TopWave} alt="Wave top" className={classes.topWave} />

        {!loading && (
          <img src={image} alt={DYNAMICALLY_LOGO} className={classes.logo} />
        )}

        <form className={classes.form} onSubmit={handlSubmit}>
          <Box className={classes.formGroup}>
            <InputLabel className={classes.label} htmlFor="Email">
              {i18n.t("login.form.email")}
            </InputLabel>
            <InputBase
              name="email"
              id="Email"
              type="email"
              placeholder={i18n.t("login.form.placeholder.email")}
              className={classes.input}
              autoFocus
              classes={{
                focused: classes.inputFocused,
                error: classes.inputError,
              }}
              value={user.email}
              onChange={handleChangeInput}
              error={hasError}
            />
            {hasError && (
              <FormHelperText className={classes.helperText}>
                {i18n.t("login.form.error.email")}
              </FormHelperText>
            )}
          </Box>
          <InputLabel className={classes.label} htmlFor="Password">
            {i18n.t("login.form.password")}
          </InputLabel>
          <Box className={classes.formGroup}>
            <InputBase
              name="password"
              id="Password"
              type={showPassword ? "text" : "password"}
              placeholder={i18n.t("login.form.placeholder.password")}
              className={classes.input}
              classes={{ focused: classes.inputFocused }}
              value={user.password}
              onChange={handleChangeInput}
              endAdornment={
                <InputAdornment
                  position="end"
                  className={classes.icon}
                  onClick={handleChangeTypePassword}
                >
                  <img
                    src={showPassword ? CloseEye : OpenEye}
                    alt={showPassword ? "Close eye icon" : "Open eye icon"}
                  />
                </InputAdornment>
              }
            />
          </Box>
          <Button
            variant="contained"
            size="large"
            disableElevation
            className={classes.submitButton}
            type="submit"
            disabled={isButtonDisabled}
          >
            {i18n.t("login.buttons.submit")}
          </Button>
        </form>

        {matches && (
          <img src={RightWave} alt="Wave right" className={classes.rightWave} />
        )}
      </Grid>
    </div>
  );
};

export default Login;
