import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { toastError } from "../../messages/toasts";
import api from "../../services/api";

import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { AuthContext } from "../../context/Auth/AuthContext";

import { Button, Divider, } from "@material-ui/core";

import ShowTicketOwnerModal from '../ShowTicketOwnerModal';
import getWhatsappId from '../../utils/getWhatsappId';
import useWhatsApps from '../../hooks/useWhatsApps';
import { i18n } from '../../translate/i18n';
import CustomToolTip from '../CustomToolTip';

const VcardPreview = ({ name, number }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const { whatsApps } = useWhatsApps();

    const [selectedContact, setContact] = useState({
        name: "",
        number: 0,
        profilePicUrl: ""
    });
    const [ticketExists, setTicketExists] = useState({});
    const [showTicketOwnerModalOpen, setShowTicketOwnerModalOpen] = useState(false);

    const setContactName = (newName) => {
      setContact({
        name: newName
      })
    }

    const isNoInfoContact = !name && !number
    const isNoNumberContact = name && !number

    useEffect(() => {
      if (isNoNumberContact) {
        setContactName(name)
        return;
      }
      if (isNoInfoContact){
        setContactName(i18n.t("VcardPreview.errorMessages.noInfoContact.name"))
        return;
      }

      const delayDebounceFn = setTimeout(() => {
        const fetchContacts = async () => {
          try {
            let contactObj = {
              name: name,
              number: number.replace(/\D/g, ""),
              email: "",
            };
            const { data } = await api.post("/contact", contactObj);
            setContact(data);
          } catch (err) {
            console.log(err);
            toastError(err);
          }
        };
        fetchContacts();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }, [name, number]);

    const handleNewChat = async() => {
        const whatsappId = getWhatsappId(whatsApps, user.whatsappId);

        try {
            const { data: { ticket, ticketIsOpen }} = await api.post("/tickets", {
                contactId: selectedContact.id,
                userId: user.id,
                status: "open",
                whatsappId,
            });

            const amITicketOwner = ticket.userId === user.id;
            if (ticketIsOpen && !amITicketOwner) {
                setTicketExists(ticket);
                setShowTicketOwnerModalOpen(true);
                return;
            }

            history.push(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    }

    const handleCloseShowTicketOwnerModal = () => {
        setShowTicketOwnerModalOpen(false);
        setTicketExists({});
	};

  const getTooltipContentKey = () => {
    if (isNoInfoContact) {
      return i18n.t("VcardPreview.errorMessages.noInfoContact.tooltip")
    }
    return i18n.t("VcardPreview.errorMessages.noNumberContact.tooltip")
  }

    return (
      <>
        <ShowTicketOwnerModal
          open={showTicketOwnerModalOpen}
          onClose={handleCloseShowTicketOwnerModal}
          ticket={ticketExists}
        />
        <CustomToolTip
          content={getTooltipContentKey()}
          disabled={!isNoInfoContact && !isNoNumberContact}
        >
          <div
            style={{
              minWidth: "250px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Avatar src={selectedContact.profilePicUrl} />
              </Grid>

              <Grid item xs={9}>
                <Typography
                  style={{ marginTop: "12px", marginLeft: "10px" }}
                  variant="subtitle1"
                  color="primary"
                  gutterBottom
                >
                  {selectedContact.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Button
                  fullWidth
                  color="primary"
                  onClick={handleNewChat}
                  disabled={!selectedContact.number}
                >
                  Conversar
                </Button>
              </Grid>
            </Grid>
          </div>
        </CustomToolTip>
      </>
    );

};

export default VcardPreview;