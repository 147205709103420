import React from "react";
import { useHistory } from 'react-router-dom';

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import QueueForm from "../../components/QueueForm";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const NewQueue = () => {
  const history = useHistory();

    const handleSubmit = async (formData) => {
      try {
        await api.post("/queue", formData);

        history.goBack();
        toastSuccess(i18n.t("queues.success"));
      } catch (err) {
        toastError (err);
      }
    } 

  return (
    <FormWrapper
      title={i18n.t("formWrapper.titles.new.queue")}
    >
      <QueueForm
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  );
}

export default NewQueue;