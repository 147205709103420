import React, { useContext, useEffect, useState } from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { chatName, isChatParticipant } from "../../services/chat";

const ChatInfo = ({ chat, onClick }) => {
  const { user } = useContext(AuthContext);

  const [subheader, setSubheader] = useState("");

  useEffect(() => {
    let usersName = chat.userChat.map(userChat => userChat.user.id === user.id ? "Você" : userChat.user.name);

    const index = usersName.findIndex(name => name === "Você");
  
    if (index >= 0) {
      usersName.splice(index, 1);
      usersName.unshift("Você");
    }
  
    setSubheader(usersName.join(", "));
  }, [chat, user.id]);

  const showSubheader = (chat.isGroup || isChatParticipant(chat, user.id));

  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={<Avatar src={chat.pictureUrl} alt="contact_image" />}
      title={chatName(chat, user)}
      subheader={showSubheader && subheader}
    />
  );
};

export default ChatInfo;
