import React from "react";

import {
	makeStyles,
	FormControl,
	Select,
	Checkbox,
	MenuItem,
	ListItemText
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: "0.5rem 0.5rem 0 0.5rem",
		minWidth: 140
  },
}))

const TicketsQueueSelect = ({ 
	userQueues,
	selectedQueueIds = [],
	onChange
 }) => {
	const classes = useStyles();

	return (
		<FormControl
			variant="outlined" 
			className={classes.formControl}
			size="small"
		>
			<Select
				multiple
				displayEmpty
				value={selectedQueueIds}
				onChange={(e) => onChange(e.target.value)}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					getContentAnchorEl: null,
				}}
				renderValue={() => i18n.t("ticketsQueueSelect.placeholder")}
			>
				{userQueues.map(({ id, name, color }) => (
					<MenuItem key={id} value={id}>
            <Checkbox
							style={{
								color: color
							}}
							checked={selectedQueueIds.indexOf(id) > -1}
							size="small"
						/>
						<ListItemText primary={name}/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
};

export default TicketsQueueSelect;
