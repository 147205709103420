const messages = {
  en: {
    translations: {
      DEFAULT_SUCCESS: "Sucess",
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          extension: "Extension",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
          placeholder: {
            email: "E-mail here...",
            password: "Password here...",
          },
          error: {
            email: "Invalid e-mail",
          },
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        filters: {
          user: "User",
          queue: "Queue",
          status: {
            any: "Any",
            label: "Status",
            open: "In progress",
            closed: "Closed",
            pending: "Pending",
          },
          duration: {
            label: "Duration",
            lastWeek: "Last 7 days",
            lastMonth: "Last month",
            lastThreeMonths: "Last 2 months",
            custom: "Custom date range",
            startDate: "Since",
            endDate: "Until"
          }
        },
        download: "Download reports",
        cannotDownload: "It's not possible to download a report that are more than 2 months old!",
        ticketList: {
          title: "Tickets",
          user: {
            label: "Attendant",
            noUser: "Pending ticket"
          },
          ticket: {
            label: "Ticket id"
          },
          queue: {
            label: "Queue",
            noQueue: "No queue"
          },
          whatsapp: {
            label: "Connection"
          },
          status: {
            label: "Status",
            open: "In progress",
            closed: "Closed",
            pending: "Pending",
          },
          client: {
            label: "Client"
          },
          date: {
            label: "Date"
          },
          results: "results"
        },
        reportFileName: "report"
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: {
            part1: "Are you sure? It cannot be reverted.",
            part2: "This connection has",
            part3: "that have not yet been closed.",
            part4:
              "Upon confirmation, all these tickets will be automatically closed.",
          },
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
          disconnecting: "Disconnecting",
        },
        toolTips: {
          cantAdd: "Exceeded number of connections",
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
          onDisconnect: {
            content: "This action can take up to two minutes",
          },
        },
        table: {
          name: "WhatsApp number",
          label: "Connection name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          number: "WhatsApp number",
          label: "Connection name",
          default: "Default Connection",
          canCloseTicketByMessage: "Close ticket by messsage",
          farewellMessage: "Farewell message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted successfully!",
          unableWhatsAppId:
            "There must be a default connection or your user is connected to a connection. Report an admin if necessary.",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "WhatsApp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      importContactsModal: {
        title: "Import contacts",
        description: "Import contacts from a csv file",
        template: {
          title: "Csv template",
          select: "Select template",
          download: "Download template",
          types: {
            bradial: "Bradial",
            google: "Google"
          }
        },
        upload: {
          title: "Upload csv",
          chooseFile: "Choose file",
        },
        successMessage: "Import process initiated!",
        invalidCSV: "Imports are limited to 1mb files (around 10,000 contacts)"
      },
      importContactsProgressModal: {
        title: "Import progress",
        description: "See the current import progress",
        info: {
          successCount: "Imported contacts: ",
          failedCount: "Failed contacts:",
          repeatedCount: "Duplicate contacts: ",
          totalCount: "Total: "
        },
        successMessage: "Import complete!"
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "select a color",
          greetingMessage: "Greeting Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
          extension: "Extension",
          whatsapp: "Default Connection",
          alert:
            "This email is being used by a deactivated user. Do you want to reactivate it using the updated form information?",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          okEnable: "Enable",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      ticket: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      chat: {
        noTicketMessage: "Select a chat to start chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldUserLabel: "Type to search for users",
        fieldQueueLabel: "Type to search for queues",
        fieldConnectionLabel: "Transfer to connection",
        fieldQueuePlaceholder: "Please select a queue",
        fieldConnectionPlaceholder: "Please select a connection",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketParticipant: {
        confirmationModal: {
          deleteTitle: "Delete participant",
          leaveTicket: "Leave ticket",
          leaveMessage:
            "Request ticket owner if you want to be a participant again",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        closingHeader: "Closed tickets",
        noTicketsMessage: "No tickets found with this status or searched term.",
        connectionTitle: "Connection that is currently being used.",
        buttons: {
          accept: "Accept",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        groupName: "Group name",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      showTicketOwnerModal: {
        title: "Attention: Ticket already exists",
        owner: "Attendant: ",
        ticket: "Ticket: ",
        status: "Status: ",
        pending: "Pending",
        queue: "Queue: ",
        noQueue: "None",
        buttons: {
          ok: "Close",
        },
      },
      messageList: {
        historyButton: "See previous history",
        editedMessage: "Edited",
        forwardedMessage: "Forwarded",
      },
      selectUsers: {
        title: "Type to search the user",
      },
      formWrapper: {
        buttons: {
          back: "Back",
        },
        titles: {
          new: {
            connection: "Register Connection",
            announcement: "Register Announcement",
            URA: "Register IVR",
            queue: "Register Queue",
            user: "Register User",
            monitoring: "Register Monitoring",
          },
          edit: "Edit",
        },
        form: {
          label: {
            name: "Name",
            description: "Description",
            selector: "Selector",
            menuName: "Menu name",
            destinationType: "Destiny",
            destinationItem: "Value",
            nullSelection: "No connection",
            selectColor: "Select a color",
            monitoringStatus: {
              label: "Status",
              open: "Open",
              closed: "Closed",
              owner: "Owner",
              queue: "Queue",
            },
            monitoringUser: "User",
            monitoringQueue: "Queue",
          },
          buttons: {
            save: "Save",
          },
          messages: {
            errors: {
              required: "This field is required",
              tooLong: "Too long!",
            },
          },
          destinationSelection: {
            UserDestination: "User",
            QueueDestination: "Queue",
            AnnouncementDestination: "Announcement",
            UraDestination: "IVR",
            MonitoringDestination: "Monitoring",
          },
          defaultConnectionTooltip:
            "It is mandatory to have a default connection. To change the default connection, click edit on the connection you want to update and switch to default connection. After that, this connection will automatically cease to be the default.",
          defaultConnectionTooltipWarn:
            "There is already a connection set as default. This change may impact the use of the system. Are you sure you want to change?",
        },
      },
      mainDrawer: {
        listItems: {
          title: "Settings",
          reports: "Reports",
          connections: "Connections",
          conversations: "Conversations",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          queues: "Queues",
          administration: "Administration",
          users: "Users",
          settings: "Settings",
          contactUs: "Contact us",
          about: "About",
          chat: "Chat",
          URA: "IVR",
          announcements: "Announcements",
          monitoring: "Monitoramento",
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
        success: "Queue successfully saved.",
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          whatsapp: "Default Connection",
          actions: "Actions",
          canSeeAllTicketHistory: {
            label: "See all history",
            tooltip: "When activating this option, the user will be able to see the full history for the current ticket"
          },
          extension: "Extension",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted successfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
      },
      about: {
        messages: {
          plain: {
            title: "Monthly plan",
            caption: "Automatic renovation",
          },
          userInfo: {
            users: "Users",
            administrators: "Administrators",
          },
          payment: {
            title: "Form of payment",
            form: "Monthly Ticket (post paid)",
            charge: "Charge by e-mail",
          },
          version: {
            title: "Version",
            lib: "WhatsApp Web lib: ",
            system: "System tag: ",
          },
          terms: {
            title: "Terms of use",
            click: "Click ",
            here: "here ",
            terms: "to read our terms of use.",
          },
          config: {
            title: "Chat configs",
            close: "Close chats",
            start: "After",
            end: "minutes of inactivity",
          },
        },
        URA: {
          title: "IVR",
          table: {
            name: "Name",
            description: "Description",
            actions: "Actions",
          },
          buttons: {
            add: "Add IVR",
          },
          success: {
            message: "IVR registered",
          },
          directDial: "Direct Dialing Extension",
          confirmationModal: {
            deleteTitle: "Delete",
            deleteMessage:
              "Are you sure you want to delete this IVR? This action cannot be reversed.",
            success: {
              message: "IVR deleted successfully",
            },
          },
        },
      },
      announcements: {
        title: "Announcements",
        table: {
          name: "Name",
          description: "Description",
          actions: "Actions",
        },
        buttons: {
          add: "add announcement",
        },
        confirmationModal: {
          title: "Delete",
          message:
            "Are you sure you want to delete this announcement? This action cannot be reversed.",
          success: {
            message: "Announcement deleted successfully",
          },
        },
      },
      monitoring: {
        title: "Monitoring",
        buttons: {
          add: "Add monitoring",
        },
        table: {
          name: "Name",
          status: "Status",
          owner: "Owner",
          queue: "Queue",
          actions: "Actions",
        },
        confirmationModal: {
          title: "Delete",
          message:
            "Are you sure you want to delete this monitoring? This action cannot be reversed.",
          success: {
            message: "Monitoring successfully deleted",
          },
        },
      },
      contactUs: {
        title: "Contact us",
        email: "Email",
        extension: "Extension",
        department: "Department",
        message: "Message",
        submit: "Submit",
        subtitle: "Your opinion is very important for us.",
        phone: "(19) 3909-9662",
        contact: "contato@bradial.com.br",
        rua: "Rua Leila Diniz, 395, Jd. Amanda II",
        cep: "Hortolândia, CEP: 13188-165 - SP",
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            addParticipant: "Add participant",
            return: "Return",
            resolve: {
              title: "Resolve",
              actions: {
                withFarewellMessage: "Resolve with farewell",
                withoutFarewellMessage: "Resolve without farewell",
              },
            },
            reopen: "Reopen",
            accept: "Accept",
            addAttendant: "Add Attendant",
          },
        },
      },
      messagesInput: {
        placeholderOpen:
          "Type a message or press ''/'' to use the registered quick responses",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
        media: {
          sizeExceeded:
            "file you tried to upload failed. For photos, videos and audios the limit is 16MB and documents 100MB.",
          sizeExceededPlural:
            "files you tried to upload failed. For photos, videos and audios the limit is 16MB and documents 100MB.",
        },
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        confirmationModal: {
          title: "Delete ticket #",
          message: "Attention! All ticket's related messages will be lost.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        forward: "Forward message",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
        hasntSameReplyingMessageWhatsappId:
          "The referenced message is not part of this ticket connection, to reply to it, open a ticket from the source connection.",
        hasntSameDeletingMessageWhatsappId:
          "The referenced message is not part of this ticket connection, to delete it, open a ticket from the source connection.",
      },
      messageOptionsFooter: {
        selected: " selected",
        selectedMultiple: " selecteds",
        tooltip: {
          forward: "Forward",
        },
        formardMessageModal: {
          title: "Formard message to",
          search: "Type to search",
          cancel: "cancel",
          actions: {
            confirm: "forward",
          },
          errorFetchListContacts: "An error occurred while fetching contacts",
          selectedMaximumReached:
            "You can only share with up to 5 conversations",
        },
      },
      feedbackUserModal: {
        bug: {
          title: "Problem",
          label: "Tell us in detail what is happening...",
        },
        idea: {
          title: "Idea",
          label: "Tell us your idea.",
        },
        other: {
          title: "Others",
          label: "What do you have to say for us?",
        },
        title: "Leave you feedback",
        success: "Feedback submitted with success",
        buttons: {
          confirm: "Confirm",
          cancel: "Cancel",
        },
      },
      managerHeader: {
        searchInput: {
          placeholder: "Type to start searching...",
        },
        showAllTicketsSwitch: {
          activeToolTip: "All tickets",
          disabledTooltip: "Your tickets",
        },
        showAllChatsSwitch: {
          activeToolTip: "All chats",
          disabledTooltip: "Your chats",
        },
        closedTickets: "Resolved",
      },
      chatList: {
        noChatsTitle: "Nothing here!",
        noChatsMessage: "No chats found with this status or searched term.",
      },
      formMessages: {
        input: {
          tooShort: "Too short!",
          tooLong: "Too long!",
        },
        textArea: {
          tooShort: "Please, give us more details",
          tooLong: "Please, be more succinct",
        },
        requiredField: "Required field",
        invalidEmail: "Invalid email",
      },
      detailsDrawer: {
        title: "Details",
        members: "Members",
      },
      addChatParticipant: {
        title: "Add new participants",
        buttons: {
          ok: "Add",
          cancel: "Cancel",
        },
      },
      VcardPreview: {
        errorMessages: {
          noNumberContact: {
            tooltip: "There's no numbers in this sent contact",
          },
          noInfoContact: {
            tooltip:
              "⚠️ We couldn't get this contact info, please, check the message in your phone app.⚠️",
            name: "Invalid contact",
          },
        },
        talk: "Talk",
      },
      noQueue: "no queue",
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_QUEUE_FOUND: "Queue not found",
        ERR_NO_MONITORING_FOUND: "Monitoring not found",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there is more than one queue.",
        ERR_MISSING_VALUES: "Incomplete information",
        ERR_SEND_MAIL: "Error",
        ERR_NO_CHAT_FOUND: "No chat found with this ID.",
        ERR_CREATING_CHAT: "Error while creating chat",
        ERR_OTHER_OPEN_CHAT:
          "There's already an open chat for this participants.",
        ERR_NO_URA_FOUND: "No ura found with this ID.",
        ERR_NO_URAITEM_FOUND: "No ura item found with this ID.",
        ERR_NO_DESTINATION_FOUND: "No destination found with this ID.",
        ERR_DUPLICATED_URAITEM_SELECTOR:
          "Duplicated selected value for Ura item.",
        ERR_INBOUND_CONFIGURATION: "Connection wihtou an Inbound route!",
        ERR_QUEUE_NOT_FOUND: "Queue not found!",
        ERR_ANNOUNCEMENT_NAME_EXISTS: "Announcement name already exists",
        ERR_NO_ANNOUNCEMENT_FOUND: "No announcement found with this ID",
        ERR_DISCONNECT_WPP: "Failed to disconnect",
        ERR_NO_DATE_SPECIFIED: "No date filter specified!",
        FILE_NOT_SUPPORTED: "File not supported!",
        WPP_NOT_CONNECTED: "WhatsApp is not connected!",
        ERR_INVALID_CSV_TEMPLATE: "Invalid CSV template!",
        ERR_EMPTY_CSV: "CSV is empty!",
        ERR_INVALID_BRADIAL_CSV: "CSV do not correspond to Bradial's template!",
        ERR_INVALID_GOOGLE_CSV: "CSV do not correspond to Google's template!"
      },
    },
  },
};

export { messages };
