import { parse } from "vcard-parser"

export const fetchName = (parsedContact) => {
  const rawName = parsedContact?.fn;
  if (rawName && rawName.length >= 0) {
    return rawName[0]?.value;
  }

  return null;
}

export const fetchNumber = (parsedContact) => {
  const rawNumber = parsedContact?.tel;
  if (rawNumber && rawNumber.length >= 0) {
    return rawNumber[0]?.value;
  }

  return null;
}

export const parseContact = (vcard) => {
  const parsedContact = parse(vcard)

  return {
    name: fetchName(parsedContact),
    number: fetchNumber(parsedContact)
  }
}