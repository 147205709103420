const clearPermitedMediaList = (medias) => {
  return medias.filter((media) => !!media);
}

export const controllMediasSize = (medias) => {

  const kb = 1024;
  const decimals = 0;
  const mediaTypeFor15Mb = ["image", "video", "audio"];
  let mediaSizeExceededCount = 0;
  const maxVideoSizePermited = 15;
  const maxDocumentSizePermited = 99;
  const baseLogMb = 2;
  
  const permitedMedias = medias.map((media) => {
    const bytes = media.size;
    const getMediaSizeBaseLog = Math.round(Math.log(bytes) / Math.log(kb));
    const mediaSizeConverted = parseFloat((bytes / Math.pow(kb, getMediaSizeBaseLog)).toFixed(decimals));
    const mediaType = media.type.split("/")[0];

    const checkMediaSizeAndTypeFor15Mb = (
      getMediaSizeBaseLog === baseLogMb &&
      mediaSizeConverted > maxVideoSizePermited && 
      mediaTypeFor15Mb.includes(mediaType)
    );

    const checkMediaSizeAndTypeFor99Mb = (
      getMediaSizeBaseLog === baseLogMb &&
      mediaSizeConverted > maxDocumentSizePermited && 
      !mediaTypeFor15Mb.includes(mediaType)
    );

    const fileExceeded = getMediaSizeBaseLog > baseLogMb || checkMediaSizeAndTypeFor15Mb || checkMediaSizeAndTypeFor99Mb;

    if (fileExceeded) {
      mediaSizeExceededCount++;
    } else {
      return media;
    }
  });

  return [ mediaSizeExceededCount, clearPermitedMediaList(permitedMedias) ];
}