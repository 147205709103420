import React, { useContext, useState } from "react";

import { 
  makeStyles,
  Paper,
  Tab,
  Badge,
  Tabs,
  AppBar,
  Typography,
 } from "@material-ui/core";

import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import ManagerHeader from "../ManagerHeader";

import { AuthContext } from "../../context/Auth/AuthContext";
import { ToggleShowAll } from "../../services/toggleShowAll";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    flex: "auto",
    flexDirection: "column",
    overflow: "hidden",
  },
  badge: {
    right: "-10px",
  }
}));

const TicketsManager = () => {
  const classes = useStyles();

  const isKeyFromTicket = true;
  const {showAll, handleShowAllList} = ToggleShowAll(isKeyFromTicket);
  const { user } = useContext(AuthContext);

  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  let searchTimeout;

  const handleSearch = (searchedTerm) => {
    clearTimeout(searchTimeout);

    if (searchedTerm === "" || searchedTerm?.length < 3) {
      setSearchParam("");
      setTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tab !== status) {
      return { width: 0, height: 0 };
    }
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
        handleSwitchToOpenTab={() => setTab("open")}
      />
      <ManagerHeader
        handleSearch={handleSearch}
        handleOpenModal={() => setNewTicketModalOpen(true)}
        isTicket
        handleShowAllList={handleShowAllList}
        showAll={showAll}
        selectedQueueIds={selectedQueueIds}
        onChange={(values) => setSelectedQueueIds(values)}
        currentTab={tab}
        setCurrentTab={setTab}
      />
      <AppBar 
        position="static" 
        color="inherit" 
        className={classes.ticketsWrapper}
        elevation={0}
      >
        {(tab !== "closed" && tab !== "search") ? (
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={
                <Badge
                  overlap="rectangular"
                  className={classes.badge}
                  badgeContent={pendingCount}
                  color="secondary"
                >
                  {i18n.t("ticketsList.pendingHeader")}
                </Badge>
              }
              value={"pending"}
            />
            <Tab
              label={
                <Badge
                  overlap="rectangular"
                  className={classes.badge}
                  badgeContent={openCount}
                  color="primary"
                >
                  {i18n.t("ticketsList.assignedHeader")}
                </Badge>
              }
              value={"open"}
            />
          </Tabs>
        ) : (tab === "closed") && (
          <Typography variant="h6" align="center">{i18n.t("ticketsList.closingHeader")}</Typography>
        )}
        <Paper className={classes.ticketsWrapper}>
          <TicketsList
            status="open"
            showAll={showAll}
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setOpenCount(val)}
            style={applyPanelStyle("open")}
          />
          <TicketsList
            status="pending"
            selectedQueueIds={selectedQueueIds}
            updateCount={(val) => setPendingCount(val)}
            style={applyPanelStyle("pending")}
            setTab={setTab}
          />
          <TicketsList
            status="closed"
            showAll={showAll}
            selectedQueueIds={selectedQueueIds}
            style={applyPanelStyle("closed")}
          />
          <TicketsList
            searchParam={searchParam}
            showAll={true}
            selectedQueueIds={selectedQueueIds}
            style={applyPanelStyle("search")}
          />
        </Paper>
      </AppBar>
    </Paper>
  );
};

export default TicketsManager;
