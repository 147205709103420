import React, { useEffect, useState } from "react";

import { toastError } from "../../messages/toasts";

import {
  useFormik,
} from "formik";

import * as yup from "yup";

import {
  FormControlLabel,
  FormHelperText,
  FormControl,
  InputLabel,
  IconButton,
  makeStyles,
  TextField, 
  MenuItem,
  Select,
  Button,
  Switch
} from "@material-ui/core";
import {
  DeleteOutline,
  AddCircleOutline
} from "@material-ui/icons"

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import getDestinationItems from "../../services/getDestinationItems";

const validationSchema = yup.object({
  name: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  description: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  destinations: yup
    .array().of(
      yup.object().shape(
        {
          selector: yup
            .string()
            .max(2, i18n.t("formMessages.input.tooLong"))
            .required(i18n.t("formWrapper.form.messages.errors.required")),
          menuName: yup
            .string()
            .required(i18n.t("formWrapper.form.messages.errors.required")),
          destination: yup.object().shape(
            {
              destinationType: yup
              .string()
              .required(i18n.t("formWrapper.form.messages.errors.required")),
              destinationTypeId: yup
              .number()
              .required(i18n.t("formWrapper.form.messages.errors.required"))
            }
          )
        }
      )
    )
});

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "700px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "16px"
  },
  
  field: {
    maxWidth: "450px",
  },
  
  destinationWrapper: {
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
    gap: "8px"
  },

  submitButton: {
    marginTop: "16px"
  },

  selectField: {
    maxWidth: "170px",
    width: "100%",
  }
}));

const URAForm = ({ onSubmit, ura }) => {
  const classes = useStyles();
  
  const [destinationTypes, setDestinationTypes] = useState([]);
  const [destinationItems, setDestinationItems] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/destinationtype");
      setDestinationTypes(data);
    })();
  }, []);

  useEffect(() => {
    if (!ura) return;

    let prevDestinationItems = [];
    ura.uraItems.forEach(async ({ destination }, index) => {
      const data = await getDestinationItems(destination.destinationType);
      
      prevDestinationItems[index] = data;
      setDestinationItems([...prevDestinationItems]);
    });
  }, [ura]);
  
  const formik = useFormik({
    initialValues: {
      name: ura?.name || "",
      directDial: ura?.directDial || false,
      description: ura?.description || "",
      destinations: ura?.uraItems || [{
        selector: "",
        menuName: "",
        destination: {
          destinationType: "",
          destinationTypeId: ""
        },
        ...(ura && { action: "create" })
      }]
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  const destinations = formik.values.destinations;

  const addNewDestination = () => {
    destinations.push({
      selector: "",
      menuName: "",
      destination: {
        destinationType: "",
        destinationTypeId: ""
      },
      ...(ura && { action: "create" })
    });
    
    formik.setValues({
      ...formik.values,
      destinations
    });
  };

  const removeDestination = (index) => {
    const currentDestination = destinations[index];

    currentDestination.action !== "create" && ura ? currentDestination.action = "delete" : destinations.splice(index, 1);

    formik.setValues({
      ...formik.values,
      destinations,
    });
  }

  const handleAddUpdateAction = (index) => {
    if (formik.values.destinations[index].action !== "create" && ura) {
      formik.values.destinations[index].action = "update";
    }
  }

  const handleSelectDestinationType = (event, index) => {
    handleAddUpdateAction(index);

    destinations[index].destination.destinationTypeId = "";

    formik.setValues({
      ...formik.values,
      destinations,
    })

    formik.handleChange(event);
    fetchDestinationItems(event.target.value, index);
  }

  const fetchDestinationItems = async (destinationTypeSelected, index) => {
    try {
      const data = await getDestinationItems(destinationTypeSelected);

      let newDestinationItems = [...destinationItems]
      newDestinationItems[index] = data;
      setDestinationItems(newDestinationItems);
    } catch (err) {
      toastError(err);
    }
  }

  const handleDestinationChange = (event, index) => {
    handleAddUpdateAction(index);
    formik.handleChange(event);
  }

  const notDeletedDestinations = formik.values.destinations.filter((destination) => (
    destination.action !== "delete"
  ));

  return (
    <form
      className={classes.form}
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        id="name"
        label={i18n.t("formWrapper.form.label.name")}
        name="name"
        className={classes.field}
        variant="outlined"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <FormControlLabel
        control={
          <Switch
            id="direct-dial"
            name="directDial"
            color="primary"
            checked={formik.values.directDial}
            onChange={formik.handleChange}
          />
        }
        label={i18n.t("URA.directDial")}
      />
      <TextField
        fullWidth
        id="description" 
        label={i18n.t("formWrapper.form.label.description")}
        name="description"
        className={classes.field}
        multiline
        minRows={4}
        variant="outlined"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
      />
      {formik.values.destinations.map(({ action }, index) => (
        <div
          key={index}
          className={classes.destinationWrapper}
          style={{
            display: action === "delete" && "none"
          }}
        >
          <TextField
            id="selector"
            label={i18n.t("formWrapper.form.label.selector")}
            name={`destinations.[${index}].selector`}
            variant="outlined"
            value={formik.values.destinations[index].selector}
            onChange={(event) => handleDestinationChange(event, index)}
            error={formik.touched.destinations?.[index]?.selector && Boolean((formik.errors.destinations?.[index]?.selector))}
            helperText={formik.touched.destinations?.[index]?.selector && formik.errors.destinations?.[index]?.selector}
            style={{
              maxWidth: "100px"
            }}
          />
          <TextField
            fullWidth
            id="menuName"
            label={i18n.t("formWrapper.form.label.menuName")}
            name={`destinations.[${index}].menuName`}
            variant="outlined"
            value={formik.values.destinations[index].menuName}
            onChange={(event) => handleDestinationChange(event, index)}
            error={formik.touched.destinations?.[index]?.menuName && Boolean((formik.errors.destinations?.[index]?.menuName))}
            helperText={formik.touched.destinations?.[index]?.menuName && formik.errors.destinations?.[index]?.menuName}
            style={{
              maxWidth: "200px"
            }}
          />
          <FormControl
            variant="outlined"
            error={formik.touched.destinations?.[index]?.destination?.destinationType && Boolean((formik.errors.destinations?.[index]?.destination?.destinationType))}
            className={classes.selectField}
          >
            <InputLabel id="destination-type-label">
              {i18n.t("formWrapper.form.label.destinationType")}
            </InputLabel>
            <Select
              fullWidth
              id="destination-type"
              labelId="destination-type-label"
              label={i18n.t("formWrapper.form.label.destinationType")}
              name={`destinations.[${index}].destination.destinationType`}
              value={formik.values.destinations[index].destination.destinationType}
              onChange={(event) => handleSelectDestinationType(event, index)}
            >
              {destinationTypes.map(({id, name}) => (
                <MenuItem
                  key={id}
                  value={name}
                >
                  {i18n.t(`formWrapper.form.destinationSelection.${name}`)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.destinations?.[index]?.destination.destinationType && formik.errors.destinations?.[index]?.destination?.destinationType}</FormHelperText>
          </FormControl>
          <FormControl
            variant="outlined"
            error={formik.touched.destinations?.[index]?.destination?.destinationTypeId && Boolean((formik.errors.destinations?.[index]?.destination?.destinationTypeId ))}
            className={classes.selectField}
          >
            <InputLabel id="destination-item-label">
              {i18n.t("formWrapper.form.label.destinationItem")}
            </InputLabel>
            <Select
              fullWidth
              id="destination-item"
              labelId="destination-item-label"
              label={i18n.t("formWrapper.form.label.destinationItem")}
              name={`destinations.[${index}].destination.destinationTypeId`}
              value={formik.values.destinations[index].destination.destinationTypeId }
              onChange={(event) => handleDestinationChange(event, index)}
            >
              {destinationItems[index]?.map(({id, name}) => (
                <MenuItem
                  key={id}
                  value={id}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.touched.destinations?.[index]?.destination?.destinationTypeId && formik.errors.destinations?.[index]?.destination?.destinationTypeId }</FormHelperText>
          </FormControl>
          <IconButton
            style={{
              alignSelf: "center",
            }}
            color="secondary"
            disabled={notDeletedDestinations?.length === 1}
            onClick={() => removeDestination(index)}
          >
            <DeleteOutline />
          </IconButton>
        </div>
      ))}
      <IconButton
        onClick={addNewDestination}   
      >
        <AddCircleOutline />
      </IconButton>
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
      >
        {i18n.t("formWrapper.form.buttons.save")}
      </Button>
    </form>
  )
}

export default URAForm;