import React, { useState, useEffect, useReducer, useContext } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import openSocket from "../../services/socket-io";
import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  InputAdornment,
  IconButton,
  TextField,
  Box,
} from "@material-ui/core";
import { WhatsApp, Search, Edit, DeleteOutline } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ShowTicketOwnerModal from "../../components/ShowTicketOwnerModal";
import ConfirmationModal from "../../components/ConfirmationModal/";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MainContainer from "../../components/MainContainer";
import ContactModal from "../../components/ContactModal";
import MainHeader from "../../components/MainHeader";
import { Can } from "../../components/Can";

import getWhatsappId from "../../utils/getWhatsappId";

import useWhatsApps from "../../hooks/useWhatsApps";

import { AuthContext } from "../../context/Auth/AuthContext";
import ImportContactsModal from "../../components/ImportContactsModal";
import ImportContactsProgressModal from "../../components/ImportContactsProgressModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  dataWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px",
  },

  tableWrapper: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);
  const { whatsApps } = useWhatsApps();

  const [loading, setLoading] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importProgressModalOpen, setImportProgressModalOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [ticketExists, setTicketExists] = useState({});
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [showTicketOwnerModalOpen, setShowTicketOwnerModalOpen] =
    useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("contact", (data) => {
      if (!searchParam) return;

      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [searchParam]);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };


  const handleImportButton = async () => {
    try {
      const { data: { canImport }} = await api.get("contact-import/can-import");
      if (canImport){
        setImportModalOpen(true);
        return;
      }
      setImportProgressModalOpen(true);
    } catch (error) {
      toastError(error);
    }
  }

  const whatsappId = getWhatsappId(whatsApps, user.whatsappId);

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);

    if (!whatsappId) {
      toast.info(`${i18n.t("contacts.toasts.unableWhatsAppId")}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return setLoading(false);
    }

    try {
      const {
        data: { ticket, ticketIsOpen },
      } = await api.post("/tickets", {
        contactId: contactId,
        userId: user?.id,
        status: "open",
        whatsappId,
      });

      if (ticketIsOpen) {
        setTicketExists(ticket);
        setShowTicketOwnerModalOpen(true);
        return;
      }

      history.push(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseShowTicketOwnerModal = () => {
    setShowTicketOwnerModalOpen(false);
    setTicketExists({});
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toastSuccess(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ShowTicketOwnerModal
        open={showTicketOwnerModalOpen}
        onClose={handleCloseShowTicketOwnerModal}
        ticket={ticketExists}
      />

      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      />
      <ConfirmationModal
        title={i18n.t("contacts.confirmationModal.deleteTitle")}
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) => handleDeleteContact(deletingContact.id)}
      >
        {i18n.t("contacts.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ImportContactsModal
        open={importModalOpen}
        onClose={setImportModalOpen}
        onSuccess={setImportProgressModalOpen}
      />
      <ImportContactsProgressModal open={importProgressModalOpen} onClose={setImportProgressModalOpen}/>
      <Box className={classes.dataWrapper}>
        <MainHeader title="contacts.title">
          <MainHeaderButtonsWrapper>
            <TextField
              variant="outlined"
              size="small"
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Can
              role={user.profile}
              perform="contacts-page:importContacts"
              yes={() => (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handleImportButton()}
                >
                  {i18n.t("contacts.buttons.import")}
                </Button>
              )}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenContactModal}
            >
              {i18n.t("contacts.buttons.add")}
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          elevation={0}
          className={classes.tableWrapper}
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>{i18n.t("contacts.table.name")}</TableCell>
                <TableCell align="center">
                  {i18n.t("contacts.table.whatsapp")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("contacts.table.email")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("contacts.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {contacts.map((contact) => (
                  <TableRow key={contact.id}>
                    <TableCell style={{ paddingRight: 0 }}>
                      {<Avatar src={contact.profilePicUrl} />}
                    </TableCell>
                    <TableCell>{contact.name}</TableCell>
                    <TableCell align="center">{contact.number}</TableCell>
                    <TableCell align="center">{contact.email}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => handleSaveTicket(contact.id)}
                      >
                        <WhatsApp />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => hadleEditContact(contact.id)}
                      >
                        <Edit />
                      </IconButton>
                      <Can
                        role={user.profile}
                        perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setConfirmOpen(true);
                              setDeletingContact(contact);
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton avatar columns={4} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </MainContainer>
  );
};

export default Contacts;
