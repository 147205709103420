import { useState, useEffect } from "react";
import api from "../../services/api";

const useMediaUrl = (mediaUrl) => {
  const [blobUrl, setBlobUrl] = useState("");

  useEffect(() => {
    if (!mediaUrl) return;

    const fetchMediaUrl = async () => {
      const config = { responseType: "blob" };
      const { data, headers } = await api.get(mediaUrl, config);
      const url = URL.createObjectURL(
        new Blob([data], { type: headers["content-type"]})
      );
      setBlobUrl(url);
    }

    fetchMediaUrl();
  }, [mediaUrl]);

  return [blobUrl];
}

export default useMediaUrl;