import React, { useContext, useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../messages/toasts";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import AddChatParticipantModal from "../AddChatParticipantModal";
import ConfirmationModal from "../ConfirmationModal";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

import {
	Typography, 
	IconButton, 
	Drawer,  
	Avatar,  
	makeStyles, 
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
	InputBase
} from "@material-ui/core";

import CloseIcon from '../../assets/icons/Close.svg';
import UsersIcon from '../../assets/icons/Users.svg';
import { chatName, userChatting } from "../../services/chat";
import GroupActions from "../GroupActions";
import ChatParticipantInfo from "../ChatParticipantInfo";
import getFirstLetterName from "../../utils/getFirstLetterName";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4
	},
	header: {
		margin: "0 1rem 1rem 1rem",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		maxHeight: "70px",
		height: "100%"
	},
	title: {
		fontSize: "1.5rem",
		fontWeight: 700,
		color: theme.palette.text.primary
	},
	content: {
		margin: "0 1rem",
		display: "flex",
		flexDirection: "column",
		color: theme.palette.text.primary
	},
	chatAvatar: {
		maxWidth: "106px",
		maxHeight: "106px",
		width: "100%",
		height: "100%",
		alignSelf: "center"
	},
	chatName: {
		textAlign: "center",
		fontSize: "1rem",
		fontWeight: 600,
		margin: "0.5rem 0 4px 0",
	},
	form: {
		margin: 0,
		textAlign: "center",
	},
	groupNameInput: {
		backgroundColor: theme.palette.grey[500],
		margin: "0.5rem 0 4px 0",
		maxWidth: "70%",
		padding: "0 1rem",
		alignSelf: "center",
		fontWeight: 600,
		borderRadius: "4px"
	},
	membersCount: {
		alignSelf: "center",
		fontSize: theme.typography.fontSize,
		color: theme.palette.grey[700]
	},
	memberListHeader: {
		display: "flex",
		alignItems: "center"
	},
	memberListTitle: {
		fontSize: "12px",
		marginLeft: "4px"
	},
	memberItem: {
		padding: 0,
		marginTop: "0.5rem"
	},
	memberAvatar: {
		minWidth: 42
	}
}));

const DetailsDrawer = ({ 
	open, 
	handleDrawerClose, 
	loading, 
	chat
}) => {
	const classes = useStyles();

	const {isGroup, userChat} = chat;
	
	const history = useHistory();

	const { user } = useContext(AuthContext);

	const [modalOpen, setModalOpen] = useState(false);
	const [leaveGroupModalOpen, setLeaveGroupModalOpen] = useState(false);
	const [groupName, setGroupName] = useState("");
	const [isGroupNameEditable, setIsGroupNameEditable] = useState(false);

	useEffect(() => {
		isGroupNameEditable && setGroupName(chat.name)
	}, [chat.name, isGroupNameEditable])

	const handleChangeGroupNameState = () => {
		if (isGroupNameEditable) return;

		setIsGroupNameEditable(true)
	}

	const handleSaveGroupName = async (e) => {

		if (e.target.id !== "groupChatName" && groupName.length >= 3 && isGroupNameEditable) {
			try {
				await api.put(`/chats/${chat.id}`, {
					name: groupName,
				});

				toastSuccess()
			} catch(err) {
				toastError(err.message)
			} finally {
				setIsGroupNameEditable(false);
			}
		}
	}

	const handleLeaveGroup = async () => {
    try {
			await api.delete(`/chats/${chat.id}/participant/${user.id}`, );
			
			history.push(`/chats`);
			toastSuccess();
		} catch (err) {
			setLeaveGroupModalOpen(false);
			toastError(err);
		}
	}
	
	const handleSubmit = (e) => {
    e.preventDefault();
    handleSaveGroupName(e);
  };
	
	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<Box className={classes.header}>
				<Typography className={classes.title}>{i18n.t("detailsDrawer.title")}</Typography>
				<IconButton onClick={handleDrawerClose} disabled={isGroupNameEditable}>
					<img src={CloseIcon} alt="Close Icon button"/>
				</IconButton>
			</Box>
			{loading ? (
				<ContactDrawerSkeleton classes={classes}/>
			) : (
				<Box 
					className={classes.content} 
					onClick={handleSaveGroupName}
				>
					<Avatar
						alt={chat.name}
						className={classes.chatAvatar}
					/>
					{isGroupNameEditable ? (
					<form 
						onSubmit={handleSubmit}
						className={classes.form}
					>
						<InputBase
							id="groupChatName"
							autoFocus
							value={groupName} 
							onChange={(e) => setGroupName(e.target.value) }
							className={classes.groupNameInput}
							inputProps={{maxLength: 25}}
						/>
					</form>
					) : (
						<Typography 
							className={classes.chatName}
						>
							{chatName(chat, user)}
						</Typography>
					)}
					{isGroup ? (
						<>
							<Typography className={classes.membersCount}>
								{userChat?.length} {i18n.t("detailsDrawer.members")}
							</Typography>
							<GroupActions
								chat={chat}
								isGroupNameEditable={isGroupNameEditable}
								setModalOpen={setModalOpen}
								handleChangeGroupNameState={handleChangeGroupNameState}
								setLeaveGroupModalOpen={setLeaveGroupModalOpen}
							/>
							<Box>
								<Box className={classes.memberListHeader}>
									<img src={UsersIcon} alt="Users icon"/>
									<Typography 
										className={classes.memberListTitle} 
										style={{textTransform: "uppercase"}} 
									>
										{i18n.t("detailsDrawer.members")}
									</Typography>
								</Box>
								<List dense>
									{userChat?.map(({user}) => (
										<ListItem key={user.id} className={classes.memberItem}>
											<ListItemAvatar className={classes.memberAvatar}>
												<Avatar style={{width: 30, height: 30, fontSize: "1rem"}}>{getFirstLetterName(user)}</Avatar>
											</ListItemAvatar>
											<ListItemText primary={user.name}/>
										</ListItem>
									))}
								</List>
							</Box>
						</>
					) : (
						<ChatParticipantInfo
							chat={chat}
							userChatting={userChatting}
							userChat={userChat}
						/>
					)}
						<ConfirmationModal
							title={`Sair do grupo`}
							open={leaveGroupModalOpen}
							onClose={setLeaveGroupModalOpen}
							onConfirm={handleLeaveGroup}
						>
							{`Tem certeza que deseja sair do grupo ${chat.name}?`}
						</ConfirmationModal>
						<AddChatParticipantModal
							modalOpen={modalOpen}
							onClose={() => setModalOpen(false)}
							chatId={chat?.id}
						/>
				</Box>
			)}
		</Drawer>
	);
};

export default DetailsDrawer;
