import React from "react";
import { useHistory } from "react-router-dom";

import { toastError, toastSuccess } from "../../messages/toasts";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import URAForm from "../../components/URAForm";

import api from "../../services/api";

const NewURA = () => {
  const history = useHistory();

  const handleSubmit = async (formData) => {
    try {
      const data = {
        name: formData.name,
        directDial: formData.directDial,
        description: formData.description,
        uraItems: formData.destinations
      };

      await api.post("/uras", data);
      toastSuccess(i18n.t("URA.success.message"));
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <FormWrapper
      title={i18n.t("formWrapper.titles.new.URA")}
    >
      <URAForm
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  )
}

export default NewURA;