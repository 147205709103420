import React from "react"

import { 
  FormControl,
  InputLabel, 
  MenuItem,
  Select
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const SelectedQueues = ({queues, setSelectedQueue, selectedQueue}) => {
  return (
    <>
      {(queues?.length > 0) && (
				<FormControl variant="outlined">
					<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
					<Select
						value={selectedQueue}
						onChange={(e) => setSelectedQueue(e.target.value)}
						style={{ width: 300 }}
						label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
					>
						{queues.map((queue) => (
							<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
						))}
					</Select>
			  </FormControl>
			)}
    </>
  )
}

export default SelectedQueues;