import React from "react";

import {
  useFormik
} from "formik";

import * as yup from "yup";

import {
  makeStyles,
  TextField,
  Button,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import DestinationSelection from "../../components/DestinationSelection";

const validationSchema = yup.object({
  name: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  description: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  destinationType: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  destinationItem: yup
    .number()
    .required(i18n.t("formWrapper.form.messages.errors.required"))
});

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "16px"
  },

  submitButton: {
    marginTop: "16px"
  }
}));

const AnnouncementForm = ({ onSubmit, announcement }) => {
  const classes = useStyles();

  const destination = announcement?.destination;

  const formik = useFormik({
    initialValues: {
      name: announcement?.name || "",
      description: announcement?.description || "",
      destinationType: destination?.destinationType || "",
      destinationItem: destination?.destinationTypeId || ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    }
  });

  return (
    <form
      className={classes.form}
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        id="name"
        label={i18n.t("formWrapper.form.label.name")}
        name="name"
        variant="outlined"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        fullWidth
        id="description" 
        label={i18n.t("formWrapper.form.label.description")}
        name="description"
        multiline
        minRows={4}
        variant="outlined"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && Boolean(formik.errors.description)}
        helperText={formik.touched.description && formik.errors.description}
      />
      <DestinationSelection 
        formik={formik}
      />
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
      >
        {i18n.t("formWrapper.form.buttons.save")}
      </Button>
    </form>
  );
}

export default AnnouncementForm;