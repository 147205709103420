import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Box,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery,
  Collapse,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton
} from "@material-ui/core";

import {
  DeleteOutline,
  Edit
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import MainContainer from "../../components/MainContainer";
import ConfigurationManager from "../../components/ConfigurationManager";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfirmationModal from "../../components/ConfirmationModal";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const useStyles = makeStyles(() => ({
  dataWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px",
  }
}));

const Monitoring = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [toggleConfigManager, setToggleConfigManager] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [monitorings, setMonitoring] = useState([]);
  const [selectedMonitoring, setSelectedMonitoring] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        try {
          const { data } = await api.get("monitorings");
          setMonitoring(data);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      })();
    }, 500)

    return () => {
      clearTimeout(delayDebounceFn);
    }
  }, [])


  const handleOpenConfirmationModal = (monitoring) => {
    setConfirmationModalOpen(true);
    setSelectedMonitoring(monitoring);
  }

  const handleDeleteMonitoring = async () => {
    if (!selectedMonitoring) return;

    try {
      await api.delete(`monitorings/${selectedMonitoring.id}`);

      const filteredMonitorings = monitorings.filter(
        (monitoring) => monitoring.id !== selectedMonitoring.id
      );

      setMonitoring(filteredMonitorings);
      toastSuccess(i18n.t("monitoring.confirmationModal.success.message"));
    } catch (err) {
      toastError(err);
    }
  }

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
    setSelectedMonitoring(null);
  }

  const handleToggleConfigManager = useCallback(() => {
    setToggleConfigManager((prevState) => !prevState);
  }, []);

  return (
    <MainContainer>
      <ConfirmationModal
        title={`${i18n.t("monitoring.confirmationModal.title")} ${selectedMonitoring?.name}`}
        open={confirmationModalOpen}
        onConfirm={handleDeleteMonitoring}
        onClose={handleCloseConfirmationModal}
      >
        {i18n.t("monitoring.confirmationModal.message")}
      </ConfirmationModal>
      {matches ? (
        <Collapse in={toggleConfigManager}>
          <ConfigurationManager onClick={handleToggleConfigManager} />
        </Collapse>
      ) : (
        <ConfigurationManager />
      )}
      <Box className={classes.dataWrapper}>
        <MainHeader
          title="monitoring.title"
          onClick={handleToggleConfigManager}
        >
          <MainHeaderButtonsWrapper>
            <Link to="/monitoring/new">
              <Button variant="contained" color="primary">
                {i18n.t("monitoring.buttons.add")}
              </Button>
            </Link>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {i18n.t("monitoring.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("monitoring.table.status")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("monitoring.table.owner")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("monitoring.table.queue")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("monitoring.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {monitorings.map((monitoring) => (
                <TableRow key={monitoring.id}>
                  <TableCell align="center">{monitoring.name}</TableCell>
                  <TableCell align="center"> {i18n.t(`formWrapper.form.label.monitoringStatus.${monitoring.status}`)} </TableCell>
                  <TableCell align="center"> {monitoring?.user?.name} </TableCell>
                  <TableCell align="center"> {monitoring?.queue?.name} </TableCell>
                  <TableCell align="center">
                    <Link to={`/monitoring/edit/${monitoring.id}`}>
                      <IconButton size="small">
                        <Edit />
                      </IconButton>
                    </Link>

                    <IconButton
                      size="small"
                      onClick={() => handleOpenConfirmationModal(monitoring)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={5} />}
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </MainContainer>
  );
};

export default Monitoring;
