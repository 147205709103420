import React from "react";
import { useHistory } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import MonitoringForm from "../../components/MonitoringForm"

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const NewMonitoring = () => {
  const history = useHistory();

  const handleSubmit = async (dataForm) => {
    try {
      await api.post("monitorings", dataForm);

      toastSuccess();
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <FormWrapper
      title={i18n.t("formWrapper.titles.new.monitoring")}
    >
      <MonitoringForm
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  );
}

export default NewMonitoring;