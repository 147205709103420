import React, { useContext } from "react";
import {Box, IconButton, makeStyles} from "@material-ui/core";
import { isChatParticipant } from "../../services/chat";
import { AuthContext } from "../../context/Auth/AuthContext";

import AddParticipantIcon from '../../assets/icons/AddParticipant.svg';
import EditGroupNameIcon from '../../assets/icons/Edit.svg';
import LeaveGroupIcon from '../../assets/icons/Leave.svg';

const useStyles = makeStyles(() => ({
  actions: {
		margin: "1rem 0 1.5rem 0",
		alignSelf: "center"
	},
}))

const GroupActions = ({
    chat,
    isGroupNameEditable,
    setModalOpen,
    handleChangeGroupNameState, 
    setLeaveGroupModalOpen
  }) => {
  const classes = useStyles();
  const {user} = useContext(AuthContext);

  return (
    <Box className={classes.actions}>
      <IconButton onClick={() => setModalOpen(true)} disabled={isGroupNameEditable}>
        <img src={AddParticipantIcon} alt="Add new participant icon"/>
      </IconButton>
      {isChatParticipant(chat, user.id) && (
        <>
          <IconButton onClick={handleChangeGroupNameState} style={{margin: "0 1rem"}}>
            <img src={EditGroupNameIcon} alt="Edit group name icon"/>
          </IconButton>
          <IconButton onClick={() => setLeaveGroupModalOpen(true)} disabled={isGroupNameEditable}>
            <img src={LeaveGroupIcon} alt="Leave group icon"/>
          </IconButton>
        </>
      )}
    </Box>
  );
}

export default GroupActions;