import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const useCurrentUrlId = () => {
  const location = useLocation();
  const currentUrlId = location.pathname.split('/')[2];
  const currentUrlIdRef = useRef(currentUrlId);

  useEffect(() => {
    currentUrlIdRef.current = currentUrlId;
  }, [currentUrlId])

  return { currentUrlIdRef };
}

export default useCurrentUrlId;
