import { useCallback, useEffect, useState, useMemo } from "react";

import api from "../../../services/api";

import useIsMounted from "../../../hooks/useIsMounted";

const isReceiverSelected = (id, items) => {
  return items?.some((item) => item.id === id);
}

const hasFiveReceiversSelected = (items) => {
  return items?.length === 5;
}

const useForwardReceivers = (path, searchTerm) => {
  const [receivers, setReceivers] = useState([]);
  const [selectedReceivers, setSelectedReceivers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  
  const isMounted = useIsMounted();

  useEffect(() => {
    const fetchReceiversByChatType = async () => {
      try {
        const { data } = await api.get(`/forwardmessage/${path}`);
        
        if (isMounted()) {
          setReceivers(data);
          setIsLoading(false);
        }
      } catch {
        if (isMounted()) {
          setIsLoading(false);
          setHasError(true);
        }
      }
    }

    fetchReceiversByChatType();

    return () => {
      setReceivers([]);
      setIsLoading(false);
      setHasError(false);
    }
  }, [path, isMounted])

  const filteredReceivers = useMemo(() => (
    receivers.filter(receiver => (
      receiver.contact.name.toLowerCase().startsWith(
        searchTerm.toLowerCase()
      )
    ))
  ), [receivers, searchTerm]);

  const handleRemoveSelectedReceiver = (id) => {
    setSelectedReceivers((prevState) => prevState.filter(
      (item) => item.id !== id)
    );
  }

  const handleSelectReceiver = (receiver) => {
    const id = receiver.id;
    const isSelected = isReceiverSelected(id, selectedReceivers);

    if (isSelected) {
      handleRemoveSelectedReceiver(id);
      return;
    }

    if (hasFiveReceiversSelected(selectedReceivers)) {
      setShowSnackbar(true);
      return;
    }

    setSelectedReceivers((prevState) => [...prevState, receiver]);
  }

  const renderList = useCallback((renderItem) => (
    filteredReceivers.map((item) => {
      const id = item.id;
      const isSelected = isReceiverSelected(id, selectedReceivers);
      const hasFiveSelectedAndIsNotSelected = (
        hasFiveReceiversSelected(selectedReceivers) && !isSelected
      );

      return renderItem(item, {
        isSelected,
        hasFiveSelectedAndIsNotSelected
      });
    })
  ), [filteredReceivers, selectedReceivers]);

  const hideSnackbar = () => {
    setShowSnackbar(false);
  }

  const cleanUpProperties = () => {
    setSelectedReceivers([]);
    hideSnackbar();
  }

  return {
    isLoading,
    hasError,
    showSnackbar,
    receivers,
    selectedReceivers,
    hideSnackbar,
    handleSelectReceiver,
    renderList,
    cleanUpProperties,
  }
}

export default useForwardReceivers;