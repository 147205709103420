import React from "react";

import {
	makeStyles,
	Hidden,
	IconButton,
	Typography
} from "@material-ui/core";
import {
	ChevronRight
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import Title from "../Title";

const useStyles = makeStyles((theme) => ({
	header: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "32px",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
			alignItems: "flex-start",
			gap: "8px"
		}
	},
}));

const MainHeader = ({ children, title, onClick }) => {
	const classes = useStyles();

	return (
		<header className={classes.header}>
			<Hidden mdUp>
				<IconButton
					onClick={onClick}
					size="small"
				>
					<ChevronRight />
					<Typography
						variant="button"
					>
						{i18n.t("mainDrawer.listItems.settings")}
					</Typography>
				</IconButton>
			</Hidden>
			<Title>{i18n.t(title)}</Title>
			{children}
		</header>
	);
};

export default MainHeader;
