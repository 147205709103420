import React, { useState } from "react";

import { Paper, makeStyles} from "@material-ui/core";

import NewChatModal from "../NewChatModal";
import ChatsList from "../ChatsList";

import { ToggleShowAll } from "../../services/toggleShowAll";
import ManagerHeader from "../ManagerHeader";

const useStyles = makeStyles(theme => ({
  chatsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  }
}));

const ChatsManager = () => {
  const classes = useStyles();

  const isKeyFromTicket = false;
  const {showAll, handleShowAllList} = ToggleShowAll(isKeyFromTicket);

  const [newChatModalOpen, setNewChatModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  let searchTimeout;

  const handleSearch = (searchedTerm) => {
    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam("");
      return;
    }
    
    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  return (
    <Paper
      elevation={0}
      variant="outlined"
      className={classes.chatsWrapper}
    >
      <NewChatModal
        modalOpen={newChatModalOpen}
        onClose={() => setNewChatModalOpen(false)}
      />
      <ManagerHeader
        handleSearch={handleSearch}
        handleOpenModal={() => setNewChatModalOpen(true)}
        isTicket={false}
        handleShowAllList={handleShowAllList}
        showAll={showAll}
      />
      <Paper className={classes.chatsWrapper}>
        <ChatsList 
          searchParam={searchParam}
          showAll={showAll}
        />
      </Paper>
    </Paper>
  );
}

export default ChatsManager;