import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import openSocket from "../../services/socket-io";
import api from "../../services/api";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ProgressBar } from "../ProgressBar";
import { SuccessButton } from "../buttons/SuccessButton";
import { toastError, toastSuccess } from "../../messages/toasts";

import { i18n } from "../../translate/i18n";

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
      <Box width="100%" mr={1}>
        <ProgressBar variant="determinate" {...props} />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    minWidth: 450,
  },
});

const ImportContactsProgressModal = ({ open, onClose }) => {
  const classes = useStyles();

  const history = useHistory();

  const [currentContactsImport, setCurrentContactsImport] = useState(null);

  useEffect(() => {
    const socket = openSocket();

    socket.on("ContactsImports", (data) => {
      switch (data.action) {
        case "progressEvent":
          setCurrentContactsImport(data.ContactsImport);
          break;
        case "finishedImport":
          toastSuccess(i18n.t("importContactsProgressModal.successMessage"), 5000);
          break;
        default:
          break;
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [history]);

  useEffect(() => {
    if (!open) {
      return;
    }

    const fetchOnGoing = async () => {
      try {
        const { data: ongoingImport } = await api.get("contact-import/ongoing");
        setCurrentContactsImport(ongoingImport);
      } catch (error) {
        toastError(error);
      }
    };
    fetchOnGoing();
  }, [open]);

  return (
    <Dialog open={open}>
      <DialogTitle id="confirm-dialog">
        {i18n.t("importContactsProgressModal.title")}
        <Typography>
          {i18n.t("importContactsProgressModal.description")}
        </Typography>
      </DialogTitle>

      <DialogContent dividers className={classes.contentWrapper}>
        <LinearProgressWithLabel
          value={
            (currentContactsImport?.progress /
              currentContactsImport?.totalOfContacts) *
              100 || 0
          }
        />
        <Box>
          <Typography>
            {i18n.t("importContactsProgressModal.info.successCount")}
            {currentContactsImport?.successCount || 0}
          </Typography>
          <Typography>
            {i18n.t("importContactsProgressModal.info.failedCount")}
            {currentContactsImport?.failedCount || 0}
          </Typography>
          <Typography>
            {i18n.t("importContactsProgressModal.info.repeatedCount")}
            {currentContactsImport?.repeatedCount || 0}
          </Typography>
          <Typography>
            {i18n.t("importContactsProgressModal.info.totalCount")}
            {currentContactsImport?.progress || 0}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <SuccessButton onClick={() => onClose(false)}>Ok</SuccessButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImportContactsProgressModal;
