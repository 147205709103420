import React from "react";
import { makeStyles } from "@material-ui/core";

import useMediaUrl from "../../hooks/useMediaUrl";

const useStyles = makeStyles(theme => ({
  messageMedia: {
    "&:fullscreen": { 
      objectFit: 'revert' 
    },

    width: 300,
    maxHeight: 300,
    minHeight: 80,
    objectFit: 'cover',
    borderRadius: 8,
  },
}));

const Video = ({ mediaUrl }) => {
  const classes = useStyles();
	const [blobUrl] = useMediaUrl(mediaUrl);

	return (
    <video
      className={classes.messageMedia}
      src={blobUrl}
      controls
    />
	);
};

export default Video;
