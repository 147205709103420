import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Collapse,
  useTheme,
  useMediaQuery,
  TableBody,
  IconButton,
} from "@material-ui/core";
import {
  Edit,
  DeleteOutline
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import { toastError, toastSuccess } from "../../messages/toasts";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfigurationManager from "../../components/ConfigurationManager";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";

import api from "../../services/api";

const useStyles = makeStyles(() => ({
  dataWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px"
  },
  
  description: {
    maxWidth: "250px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

const URA = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [uras, setUras] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedUra, setselectedUra] = useState(null);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      (async () => {
        try {
          const { data } = await api.get("uras");
          setUras(data?.uras);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      })();
    }, 500)

    return () => {
      clearTimeout(delayDebounceFn);
    }
  }, [])

  const [toggleConfigManager, setToggleConfigManager] = useState(false);

  const handleToggleConfigManager = useCallback(() => {
		setToggleConfigManager((prevState) => !prevState);
	}, []);

  const handleOpenConfirmationModal = (ura) => {
    setOpenConfirmationModal(true);
    setselectedUra(ura);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setselectedUra(null);
  }

  const handleDeleteUra = async () => {
    if (!selectedUra) return;

    try {
      await api.delete(`uras/${selectedUra.id}`);
      
      setUras((prevState) => prevState.filter((ura) => ura.id!== selectedUra.id));
      toastSuccess(i18n.t("URA.confirmationModal.success.message"));
    } catch (err) {
      toastError(err);
    } finally {
      setselectedUra(false);
    }
  }

  return (
    <MainContainer>
      <ConfirmationModal
        title={`${i18n.t("URA.confirmationModal.deleteTitle")} ${selectedUra?.name}`}
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDeleteUra}
      >
        {i18n.t("URA.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      {matches ? (
        <Collapse
          in={toggleConfigManager}
        >
          <ConfigurationManager
            onClick={handleToggleConfigManager}
          />
        </Collapse>
      ) : (
        <ConfigurationManager />
      )}
      <Box
        className={classes.dataWrapper}
      >
        <MainHeader
          title="URA.title"
          onClick={handleToggleConfigManager}
        >
          <MainHeaderButtonsWrapper>
            <Link
              to="/URA/new"
            >              
              <Button
                variant="contained"
                color="primary"
              >
                {i18n.t("URA.buttons.add")}
              </Button>
            </Link>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          elevation={0}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  {i18n.t("URA.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("URA.table.description")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("URA.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uras.map((ura) => (
                <TableRow key={ura.id}>
                  <TableCell align="center">{ura.name}</TableCell>
                  <TableCell align="center" className={classes.description}>{ura.description}</TableCell>
                  <TableCell align="center">
                    <Link to={`/URA/edit/${ura.id}`}>
                      <IconButton
                        size="small"
                      >
                        <Edit />
                      </IconButton>
                    </Link>

                    <IconButton
                      size="small"
                      onClick={() => handleOpenConfirmationModal(ura)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </TableBody>
          </Table>
        </Paper>
      </Box> 
    </MainContainer>
  );
}

export default URA;