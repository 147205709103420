import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";
import useMediaUrl from "../../hooks/useMediaUrl";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: 300,
		height: 200,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
}));

const ModalImageCors = ({ mediaUrl }) => {
	const classes = useStyles();
	const [blobUrl] = useMediaUrl(mediaUrl);

	return (
		<ModalImage
			className={classes.messageMedia}
			smallSrcSet={blobUrl}
			medium={blobUrl}
			large={blobUrl}
			alt="image"
		/>
	);
};

export default ModalImageCors;
