import React, { 
  useState,
  useEffect,
  useReducer,
  useRef,
  useContext
 } from "react";

import { i18n } from "../../translate/i18n";
import openSocket from "../../services/socket-io";

import { green } from "@material-ui/core/colors";
import {
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";
import Messages from "../Messages";

import api from "../../services/api";
import { toastError } from "../../messages/toasts";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontSize: theme.typography.fontSize
  },

  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  historyButton: {
    alignSelf: "center",
    marginBottom: 10
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesListWrapper = ({
  contactId,
  status,
  userId,
  isGroup,
  onChangeSelectedMessage,
  getIsSelectionEnabledRef,
  setIsSelectionEnabledRef,
  getCheckboxRefs,
}) => {
  const classes = useStyles();

  const { ticketId } = useParams();
  const { user } = useContext(AuthContext);
  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageHistoryNumber, setPageHistoryNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreHistory, setHasMoreHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [historyIds, setHistoryIds] = useState([]);
  const [loadHistoryMessages, setLoadHistoryMessages] = useState(false);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const lastMessageRef = useRef();
  const lastHistoryMessageRef = useRef();
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(ticketId);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
    setPageHistoryNumber(1);
    setCurrentHistoryIndex(0);
    setLoadHistoryMessages(false);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  const fetchMessages = async () => {
    try {
      return api.get("/messages/" + ticketId, {
        params: { pageNumber },
      });
    } catch (err) {
      toastError(err);
    }
  }

  const fetchHistoryIds = async () => {
    try {
      return api.get("/tickets/history", {
        params: { ticketId, contactId },
      });
    } catch (err) {
      toastError(err);
    }
  }

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
      try {
        const [{data: messagesData}, {data: historyData}] = await Promise.all([fetchMessages(), fetchHistoryIds()]);

        if (currentTicketId.current === ticketId) {
          dispatch({ type: "LOAD_MESSAGES", payload: messagesData.messages });
          setHasMore(messagesData.hasMore);
        }
  
        if (pageNumber === 1 && messagesData.messages.length > 1) {
          scrollToBottom();
        }

        setHistoryIds(historyData);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
      setHistoryIds([]);
    };
  }, [pageNumber, ticketId, contactId, status]);

  const setPreviousHistoryProperties = (hasMoreMessagesInCurrentHistoryId, historyTicketId) => {
    const lastHistoryId = historyIds.slice(-1)[0]?.id;

    if (!hasMoreMessagesInCurrentHistoryId && lastHistoryId !== historyTicketId) {
      setCurrentHistoryIndex((prevHistoryIndex) => prevHistoryIndex + 1);
      setPageHistoryNumber(1);
      setLoadHistoryMessages(false);
    }
  }

  useEffect(() => {
    if (!loadHistoryMessages) return;

    setLoading(true);
    const delayDebounceFn = setTimeout(async () => {
      try {
        const { data } = await api.get(`/messages/${historyIds[currentHistoryIndex].id}`,  {
          params: { pageNumber: pageHistoryNumber }
        })
        const messages = data.messages;

        dispatch({ type: "LOAD_MESSAGES", payload: messages });
        setHasMoreHistory(data.hasMore);
        setPreviousHistoryProperties(data.hasMore, data.ticket.id);

        if (lastHistoryMessageRef.current) {
          lastHistoryMessageRef.current.scrollIntoView();
        }
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    }, 500)

    return () => {
      clearTimeout(delayDebounceFn);
    }
  }, [loadHistoryMessages, pageHistoryNumber])

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (data.action === "update" || data.action === "edit") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const loadMoreHistoryMessages = () => {
    setPageHistoryNumber((prevPageHistoryNumber) => prevPageHistoryNumber + 1);
  }

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore && !hasMoreHistory) return;

    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (scrollTop < 50 && !loading) {
      e.currentTarget.scrollTop = scrollTop + 100;
      loadHistoryMessages ? loadMoreHistoryMessages() : loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const handleSelectForwardMessage = () => {
    onChangeSelectedMessage(selectedMessage);
    setIsSelectionEnabledRef(true);
  }

  const shouldShowHistoryButton = (
    !loadHistoryMessages &&
    !loading && 
    userId === user.id &&
    !!historyIds.length &&
    status === "open"
  );

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
        onSelectForwardMessage={handleSelectForwardMessage}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {shouldShowHistoryButton ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.historyButton}
            onClick={() => setLoadHistoryMessages(true)}
          >
            {i18n.t("messageList.historyButton")}
          </Button>
        ) : null}
        {messagesList.length > 0 ? 
          <Messages
            messagesList={messagesList}
            handleOpenMessageOptionsMenu={handleOpenMessageOptionsMenu}
            isGroup={isGroup}
            lastMessageRef={lastMessageRef}
            lastHistoryMessageRef={lastHistoryMessageRef}
            onChangeSelectedMessage={onChangeSelectedMessage}
            getIsSelectionEnabledRef={getIsSelectionEnabledRef}
            getCheckboxRefs={getCheckboxRefs}
          /> : null}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesListWrapper;