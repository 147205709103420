import React, { useState } from "react";

import {
  Tooltip,
  ListItem,
  Menu,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";

import {
  People,
  Delete
} from "@material-ui/icons"

import { toastError } from "../../messages/toasts";
import { i18n } from "../../translate/i18n";
import ConfirmationModal from "../../components/ConfirmationModal"
import api from "../../services/api";

const TicketParticipantList = ({ participants }) => {
  const [removeData, setRemoveData] = useState({
    ticketId: null,
    participantId: null
  })
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setRemoveData({});
  }

  const handleOpenModal = (ticketId, participantId) => {
    setRemoveData({
      ticketId,
      participantId
    });
    setOpenModal(true);
  }

  const handleRemoveParticipant = async () => {
    const ticketId = removeData.ticketId;
    const participantId = removeData.participantId;

    if (!openModal && !ticketId && !participantId) return;

    try {
      await api.delete(`/tickets/${ticketId}/participant/${participantId}`)
    } catch (err) {
      toastError(err);
    } finally {
      if (participants.length === 0) {
        handleCloseMenu();
      }
    }
  }

  return (
    <>
      <ConfirmationModal
        title={i18n.t("ticketParticipant.confirmationModal.deleteTitle")}
        open={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={handleRemoveParticipant}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <Tooltip
        arrow
        title="Participantes"
        placement="bottom"
      >
        <IconButton
          arias-controls="filters-menu"
          arias-haspopup="true"
          onClick={handleOpenMenu}
          size="small"
        >
          <People />
        </IconButton>
      </Tooltip>
      <Menu
        elevation={0}
        arias-controls="filters-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
      >
        <List dense>
          {participants?.map((participant) => (
            <ListItem key={participant.id}>
              <ListItemText
                primary={participant.user.name}
              />
              <ListItemSecondaryAction>
                <IconButton 
                  edge="end" 
                  aria-label="delete"
                  onClick={() => handleOpenModal(participant.ticketId, participant.user.id)}
                  size="small"
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Menu>
    </>
  )
}

export default TicketParticipantList;