import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import {
	makeStyles,
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	TextField,
	InputAdornment,
	IconButton,
	Typography,
	Paper,
  FormControlLabel,
  Switch
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import { toastError, toastSuccess } from "../../messages/toasts";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexDirection: "column",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	alert: {
		marginTop: theme.spacing(1),
		padding: "0.5rem",
		background: theme.palette.error.light,
		color: theme.palette.error.main
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, i18n.t("formMessages.input.tooShort"))
		.max(50, i18n.t("formMessages.input.tooLong"))
		.required(i18n.t("formMessages.requiredField")),
	extension: Yup.string()
		.min(3, i18n.t("formMessages.input.tooShort"))
		.max(5, i18n.t("formMessages.input.tooLong"))
		.nullable(true),
	password: Yup.string().min(5, i18n.t("formMessages.input.tooShort")).max(50, i18n.t("formMessages.input.tooLong")),
	email: Yup.string()
	.email(i18n.t("formMessages.invalidEmail"))
	.matches(/^[^áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+$/g, i18n.t("formMessages.invalidEmail"))
	.required(i18n.t("formMessages.requiredField")),
});

const UserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		extension: "",
		email: "",
		password: "",
		profile: "user"
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [showPassword, setShowPassword] = useState(false);
	const [whatsappId, setWhatsappId] = useState(false);
  const [canSeeAllTicketHistory, setCanSeeAllTicketHistory] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [userDeletedId, setUserDeletedId] = useState(null);
	const {loading, whatsApps} = useWhatsApps();

	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
        setCanSeeAllTicketHistory(data?.canSeeAllTicketHistory);
				setSelectedQueueIds(userQueueIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
		setShowAlert(false);
		setUserDeletedId(null);
	};

	const handleUpdateUser = async (values) => {
		const currentUserId = userDeletedId ?? userId;

		const userData = {
			...values,
			id: currentUserId,
			whatsappId,
			queueIds: selectedQueueIds,
			isDeleted: false,
      canSeeAllTicketHistory
		};

		try {
			await api.put(`/users/${currentUserId}`, userData);

			toastSuccess(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		} finally {
			handleClose();
		}
	}
	
	const handleSaveUser = async (values) => {
		const { email } = values;
		const userData = {
      ...values,
      whatsappId,
      queueIds: selectedQueueIds,
      canSeeAllTicketHistory,
    };

		try {
			const { data: userDataFound } = await api.get("/users/search/items", {params: { email }});

			if (userDataFound.length === 0 || !userDataFound[0]?.isDeleted) {
				await api.post("/users", userData);

				toastSuccess(i18n.t("userModal.success"));
				handleClose();
			} else {
				setUserDeletedId(userDataFound[0].id);

				if (showAlert) {
					await handleUpdateUser(values);
					return;
				}

				setShowAlert(true);
			}
		} catch (err) {
			toastError(err);
		}
	};

	const handleChangeForm = (e) => {
		if (e.target.name === "email" && showAlert) {
			setUserDeletedId(null);
			setShowAlert(false);
		}
	}

  const handleCanSeeAllTicketHistoryChange = () => {
    setCanSeeAllTicketHistory(prevState => !prevState)
  }

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							if (userId) {
								handleUpdateUser(values);
							} else {
								handleSaveUser(values);
							}
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form onChange={handleChangeForm}>
							<DialogContent dividers>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.name")}
											InputProps={{
												readOnly: loggedInUser.profile === "user" ? true : false,
												autoFocus: loggedInUser.profile === "user" ? false : true
											}}
											name="name"
											error={touched.name && Boolean(errors.name)}
											helperText={touched.name && errors.name}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</Grid>
									<Grid item xs>
										<Field
											as={TextField}
											label={i18n.t("userModal.form.extension")}
											InputProps={{
												readOnly: loggedInUser.profile === "user" ? true : false,
											}}
											name="extension"
											error={touched.extension && Boolean(errors.extension)}
											helperText={touched.extension && errors.extension}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</Grid>
									<Grid item xs>
										<FormControl
											variant="outlined"
											margin="dense"
											fullWidth
										>
											<Can
												role={loggedInUser.profile}
												perform="user-modal:editProfile"
												yes={() => (
													<>
														<InputLabel id="profile-selection-input-label">
															{i18n.t("userModal.form.profile")}
														</InputLabel>

														<Field
															as={Select}
															label={i18n.t("userModal.form.profile")}
															name="profile"
															labelId="profile-selection-label"
															id="profile-selection"
															required
														>
															<MenuItem value="admin">Admin</MenuItem>
															<MenuItem value="user">User</MenuItem>
														</Field>
													</>
												)}
											/>
										</FormControl>
									</Grid>
								</Grid>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										InputProps={{
											readOnly: loggedInUser.profile === "user" ? true : false,
										}}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										label={i18n.t("userModal.form.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
										endAdornment: (
											<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() => setShowPassword((e) => !e)}
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
											</InputAdornment>
										),autoFocus: loggedInUser.profile === "user" ? true : false
										}}
										fullWidth
									/>

								</div>
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelect
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>
                <Can
                  role={loggedInUser.profile}
                  perform="user-modal:editCanSeeAllTicketHistory"
                  yes={() => (
                    <FormControlLabel
                      control={
                        <Switch
                          id="canSeeAllTicketHistory"
                          name="canSeeAllTicketHistory"
                          color="primary"
                          checked={canSeeAllTicketHistory}
                          onChange={handleCanSeeAllTicketHistoryChange}
                        />
                      }
                      label={i18n.t("users.table.canSeeAllTicketHistory.label")}
                    />
                  )}
                />
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (!loading &&
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
											<Field
												as={Select}
												value={whatsappId}
												onChange={(e) => setWhatsappId(e.target.value)}
												label={i18n.t("userModal.form.whatsapp")}
											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{whatsApps.map((whatsapp) => (
													<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
												))}
											</Field>
										</FormControl>
									)}
								/>
								<Paper variant="outlined" className={classes.alert} style={{display: showAlert ? "block" : "none"}}>
									<Typography>{i18n.t("userModal.form.alert")}</Typography>
								</Paper>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{showAlert ? 
										i18n.t("userModal.buttons.okEnable") : 
										userId
											? `${i18n.t("userModal.buttons.okEdit")}`
											: `${i18n.t("userModal.buttons.okAdd")}`
									}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
