import api from "./api";

const requestEndpoints = {
  UserDestination: "users",
  QueueDestination: "queue",
  AnnouncementDestination: "announcements",
  UraDestination: "uras",
  MonitoringDestination: "monitorings"
};

const getDestinationItems = async (destinationTypeSelected) => {
  const endpoint = requestEndpoints[destinationTypeSelected];
  const { data } = await api.get(endpoint);
  
  return !!data?.length ? data : data[endpoint];
}

export default getDestinationItems;