import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";

import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import useCurrentUrlId from "../../hooks/useCurrentUrlID";
import MessagesListWrapper from "../MessagesListWrapper";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { toastError, toastSuccess } from "../../messages/toasts";
import ShowTicketOwnerModal from "../ShowTicketOwnerModal";
import useSelectMessages from "../../hooks/useSelectMessages";
import MessageOptionsFooter from "../MessageOptionsFooter";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  ticketInfo: {
    display: "flex",
    maxWidth: "50%",
    flexBasis: "50%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      flexBasis: "80%",
    },
  },
  ticketActionButtons: {
    maxWidth: "50%",
    flexBasis: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      flexBasis: "100%",
      marginBottom: "5px",
    },
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Ticket = () => {
  const { currentUrlIdRef } = useCurrentUrlId();
  const { ticketId } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [partialTicket, setPartialTicket] = useState({});
  const [showTicketOwnerModal, setShowTicketOwnerModal] = useState(false);

  const openShowTicketOwnerModal = () => {
    setShowTicketOwnerModal(true);
  };
  
  const {
    selectedMessages,
    handleChangeSelectedMessage,
    getIsSelectionEnabledRef,
    setIsSelectionEnabledRef,
    getCheckboxRefs,
    handleResetAllProperties
  } = useSelectMessages();
  
  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const {
            data: { ticket, hasPermission },
          } = await api.get("/tickets/" + ticketId);

          if (hasPermission) {
            setTicket(ticket);
            setContact(ticket.contact);
            setLoading(false);
          } else {
            setPartialTicket(ticket);
            openShowTicketOwnerModal();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
          history.push("/tickets");
        }
      };
      fetchTicket();
    }, 500);

    return () => {
      clearTimeout(delayDebounceFn);
      setTicket({});
      setContact({});
      handleResetAllProperties();
    }
  }, [ticketId, history, handleResetAllProperties]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("ticket", (data) => {
      if (data.action === "delete" && +currentUrlIdRef.current === data.ticketId) {
        toastSuccess();
        history.push("/tickets");
        return;
      }

      setTicket(data.ticket);
    });

    socket.on("contact", (data) => {
      if (data.action === "update") {
        setContact((prevState) => {
          if (prevState.id === data.contact?.id) {
            return { ...prevState, ...data.contact };
          }
          return prevState;
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, history, currentUrlIdRef]);

  const handleCloseTicketOwnerModal = () => {
    setShowTicketOwnerModal(false);
    history.push("/tickets");
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root} id="drawer-container">
      <ShowTicketOwnerModal
        open={showTicketOwnerModal}
        onClose={handleCloseTicketOwnerModal}
        ticket={partialTicket}
      />
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <TicketHeader loading={loading}>
          <div className={classes.ticketInfo}>
            <TicketInfo
              contact={contact}
              ticket={ticket}
              onClick={handleDrawerOpen}
            />
          </div>
          <div className={classes.ticketActionButtons}>
            <TicketActionButtons ticket={ticket} />
          </div>
        </TicketHeader>
        <ReplyMessageProvider>
          <MessagesListWrapper
            contactId={ticket.contactId}
            status={ticket.status}
            userId={ticket.userId}
            isGroup={ticket.isGroup}
            onChangeSelectedMessage={handleChangeSelectedMessage}
            getIsSelectionEnabledRef={getIsSelectionEnabledRef}
            setIsSelectionEnabledRef={setIsSelectionEnabledRef}
            getCheckboxRefs={getCheckboxRefs}
          />
          {getIsSelectionEnabledRef() ? (
              <MessageOptionsFooter
                onResetAllProperties={handleResetAllProperties}
                selectedMessages={selectedMessages}
              />
            ) : (
              <MessageInput ticketStatus={ticket.status} />
            )}
        </ReplyMessageProvider>
      </Paper>
      <ContactDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        contact={contact}
        loading={loading}
      />
    </div>
  );
};

export default Ticket;
