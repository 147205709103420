import React, { useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import { toastError, toastSuccess } from "../../messages/toasts";
import SelectUsers from "../SelectUsers";
import ButtonWithSpinner from "../ButtonWithSpinner";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const AddChatParticipantModal = ({
  modalOpen,
  onClose,
  chatId
}) => {
  const [options, setOptions] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!modalOpen) return;

    const delayDebounceFn = setTimeout(() => {
			const fetchUsersToAdd = async () => {
				try {
					const { data: dataUsers } = await api.get("/users");

          const { data: dataChat } = await api.get(`/chats/${chatId}`);

          const usersChat = dataChat.userChat.map(user => user.userId);

          const users = dataUsers.users.filter(user => !usersChat.includes(user.id));

					setOptions(users);
				} catch (err) {
					toastError(err);
				}
			};

			fetchUsersToAdd();
		}, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [modalOpen, chatId]);

  const handleAddParticipants = async () => {
    if (!usersToAdd) return;

		setLoading(true);

		try {
      const addParticipantsIds = usersToAdd.map(user => user.id);

			await api.post(`/chats/${chatId}/participant`, { 
        participantIds: addParticipantsIds 
      });

      toastSuccess();
		} catch (err) {
			toastError(err);
		} finally {
      setLoading(false);
      handleClose();
    }
  }

  const handleClose = () => {
    onClose(false);
    setUsersToAdd([]);
    setOptions([]);
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
    >
      <DialogTitle>{i18n.t("addChatParticipant.title")}</DialogTitle>
      <DialogContent dividers>
        <SelectUsers options={options} setParticipantIds={setUsersToAdd}/>
      </DialogContent>
      <DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
						{i18n.t("addChatParticipant.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="button"
						color="primary"
            disabled={usersToAdd.length === 0}
            loading={loading}
            onClick={handleAddParticipants}
					>
						{i18n.t("addChatParticipant.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
    </Dialog>
  );
}

export default AddChatParticipantModal;