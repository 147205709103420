import React from "react";
import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";

import { parseContact } from "../../services/parseVcard"
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  ReplyRounded,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import Audio from "../Audio";
import Video from "../Video";

import useDownload from "../../hooks/useDownload";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    display: "flex",
    flexDirection: "column",
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 500,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: "5px 5px 16px 5px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  checkboxLabel: {
    display: "flex",
    margin: 0,
    transition: "all 0.2s ease-in",
  },

  enabledCheckboxLabel: {
    "&:hover": {
      backgroundColor: "#00000014",
    },
  },

  selectedMessage: {
    backgroundColor: "#00000014",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 500,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    padding: "5px 5px 16px 5px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "#ffffff",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#ffffff" },
  },

  rightMessageActionsButton: {
    backgroundColor: "#dcf8c6",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#dcf8c6" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },
  textContentItemMedia: {
    overflowWrap: "break-word",
    display: "flex",
    flexDirection: "column",
    gap: 6,
    maxWidth: 300,
  },
  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    paddingRight: "40px",
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  deletedTimestamp: {
    fontStyle: "italic",
  },

  editedMessage: {
    marginRight: 4,
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    backgroundColor: "#e1f3fb",
    margin: "10px 0",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
    color: "#808888",
    padding: 8,
    fontSize: theme.typography.fontSize,
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  forwarded: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    color: "#999",
    fontStyle: "italic",
  },
}));

const Messages = ({
  messagesList,
  handleOpenMessageOptionsMenu,
  isGroup,
  lastMessageRef,
  lastHistoryMessageRef,
  onChangeSelectedMessage,
  getIsSelectionEnabledRef,
  getCheckboxRefs,
}) => {
  const classes = useStyles();
  const [setUrl] = useDownload();

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
        </span>
      );
    }

    let messageDay = parseISO(messagesList[index].createdAt);
    let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

    if (!isSameDay(messageDay, previousMessageDay)) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message.id}`}
        >
          {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
        </span>
      );
    }

    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index].fromMe;
      let previousMessageUser = messagesList[index - 1].fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const checkMessageMedia = (message) => {
    if (
      message.mediaType === "location" &&
      message.body.split("|").length >= 2
    ) {
      let locationParts = message.body.split("|");
      let imageLocation = locationParts[0];
      let linkLocation = locationParts[1];
      let nameLocation = locationParts[2] || "";
      let addrLocation = locationParts[3] || "";

      let descriptionLocation = null;

      if (locationParts.length > 2)
        descriptionLocation = message.body.split("|")[2];

      return (
        <LocationPreview
          image={imageLocation}
          link={linkLocation}
          description={descriptionLocation}
          nameLocation={nameLocation}
          addrLocation={addrLocation}
        />
      );
    } else if (message.mediaType === "vcard") {
      const parsedContact = parseContact(message.body)

      return <VcardPreview name={parsedContact?.name} number={parsedContact?.number}/>;
    } else if (message.mediaType === "image") {
      return <ModalImageCors mediaUrl={message.mediaUrl} />;
    } else if (message.mediaType === "audio") {
      return <Audio mediaUrl={message.mediaUrl} />;
    } else if (message.mediaType === "video") {
      return <Video mediaUrl={message.mediaUrl} />;
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              onClick={() =>
                setUrl({
                  url: message.mediaUrl,
                  name: message.body,
                })
              }
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          {message.quotedMsg?.mediaType === "chat"
            ? message.quotedMsg?.body
            : checkMessageMedia(message.quotedMsg)}
        </div>
      </div>
    );
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if ([4, 5].includes(message.ack)) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const isCheckboxEnabled = (mediaType) => {
    return getIsSelectionEnabledRef() && mediaType !== "video";
  };
  return messagesList?.map((message, index) => (
    <div className={classes.messagesListWrapper}>
      {renderDailyTimestamps(message, index)}
      {renderMessageDivider(message, index)}
      <FormControl>
        <FormControlLabel
          disabled={!isCheckboxEnabled(message.mediaType)}
          style={{
            justifyContent:
              isCheckboxEnabled(message.mediaType) && message.fromMe
                ? "space-between"
                : message.fromMe
                ? "flex-end"
                : "flex-start",
          }}
          className={`
                  ${classes.checkboxLabel}
                  ${
                    isCheckboxEnabled(message.mediaType) &&
                    classes.enabledCheckboxLabel
                  }
                  ${
                    getCheckboxRefs()?.has(message.id) &&
                    message.mediaType !== "video" &&
                    classes.selectedMessage
                  }
                `}
          control={
            <Checkbox
              name={`checked ${message.id}`}
              color="primary"
              onChange={() => onChangeSelectedMessage(message)}
              checked={getCheckboxRefs()?.has(message.id)}
              style={{
                display: !isCheckboxEnabled(message.mediaType) && "none",
              }}
            />
          }
          label={
            <div
              ref={lastHistoryMessageRef}
              className={
                message.fromMe ? classes.messageRight : classes.messageLeft
              }
            >
              {message.isForwarded && (
                <small className={classes.forwarded}>
                  <ReplyRounded
                    fontSize="small"
                    style={{ transform: "rotateY(180deg)" }}
                  />
                  {i18n.t("messageList.forwardedMessage")}
                </small>
              )}
              {!getIsSelectionEnabledRef() && (
                <IconButton
                  variant="contained"
                  size="small"
                  id="messageActionsButton"
                  disabled={message.isDeleted}
                  className={classes.messageActionsButton}
                  onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                >
                  <ExpandMore />
                </IconButton>
              )}
              {message.isDeleted ? (
                <div className={classes.textContentItemDeleted}>
                  <Block
                    color="disabled"
                    fontSize="small"
                    className={classes.deletedIcon}
                  />
                  <MarkdownWrapper>{message.body}</MarkdownWrapper>
                </div>
              ) : (
                <div
                  className={
                    message.mediaUrl
                      ? classes.textContentItemMedia
                      : classes.textContentItem
                  }
                >
                  <IconButton
                    variant="contained"
                    size="small"
                    id="messageActionsButton"
                    className={clsx(classes.messageActionsButton, {
                      [classes.rightMessageActionsButton]: message.fromMe,
                    })}
                    onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {!message.fromMe && isGroup && (
                    <span className={classes.messageContactName}>
                      {message.contact?.name}
                    </span>
                  )}
                  {message.quotedMsg && renderQuotedMessage(message)}
                  {(message.mediaUrl ||
                    ["location", "vcard"].includes(message.mediaType)) &&
                    checkMessageMedia(message)}
                  <MarkdownWrapper>{message.body}</MarkdownWrapper>
                </div>
              )}
              <span className={classes.timestamp}>
                {message.isEdited && (
                  <span className={classes.editedMessage}>
                    {i18n.t("messageList.editedMessage")}
                  </span>
                )}
                {format(parseISO(message.createdAt), "HH:mm")}
                {message.fromMe
                  ? !message.isDeleted && renderMessageAck(message)
                  : null}
              </span>
            </div>
          }
        />
      </FormControl>
    </div>
  ));
};

export default Messages;
