import React from 'react';
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core"
import { i18n } from '../../translate/i18n';

const ShowTicketOwnerModal = (props) => {
  const { open, onClose, ticket } = props;

  const dialogTicketInfos = {
    id: ticket?.id,
    userName: ticket?.user?.name,
    queue: ticket?.queue?.name,
  }

  const handleClose = () => {
    onClose();
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='xs' fullWidth >
      <DialogTitle>
        {i18n.t("showTicketOwnerModal.title")}
      </DialogTitle>
      <DialogContent dividers>
        {dialogTicketInfos?.userName ? (
          <Typography gutterBottom color="textSecondary">
            {i18n.t("showTicketOwnerModal.owner")}
            <Typography component='span' color='textPrimary'>{dialogTicketInfos.userName}</Typography>
          </Typography>
        ) : (
          <Typography gutterBottom color="textSecondary">
            {i18n.t("showTicketOwnerModal.status")}
            <Typography component='span' color='textPrimary'>
              {i18n.t("showTicketOwnerModal.pending")}
            </Typography>
          </Typography>
        )}
        <Typography gutterBottom color="textSecondary">
          {i18n.t("showTicketOwnerModal.ticket")}
          <Typography component='span' color='textPrimary' >#{dialogTicketInfos?.id}</Typography>
        </Typography>
        <Typography gutterBottom color="textSecondary">
          {i18n.t("showTicketOwnerModal.queue")}
          <Typography component='span' color='textPrimary'>
            {dialogTicketInfos?.queue ? dialogTicketInfos.queue : i18n.t("showTicketOwnerModal.noQueue")}
          </Typography>
        </Typography>
        </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose} color="default">
          {i18n.t("showTicketOwnerModal.buttons.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ShowTicketOwnerModal;