import React, { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { useLocalStorage } from "../../hooks/useLocalStorage";

import { 
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Divider,
  makeStyles,
  IconButton,
  Hidden,
  Typography
 } from "@material-ui/core";
 import { ChevronLeft } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  configWrapper: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "400px",
    },
    width: "100%",
    height: "calc(100vh - 48px)",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      zIndex: 9999
    }
  },

  subHeader: {
    fontSize: "1.2rem",
  },
}))

const ConfigurationManager = ({ onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const currentPageOpen = history.location.pathname.split('/')[1];

  const currentPageOpenRef = useRef(currentPageOpen);
  const [lastConfigPageOpen, setLastConfigPageOpen] = useLocalStorage("lastConfigPageOpen", "connections");

  useEffect(() => {
    currentPageOpenRef.current = currentPageOpen;
    setLastConfigPageOpen(currentPageOpenRef.current);
  }, [currentPageOpen, setLastConfigPageOpen]);

  return (
    <Paper
      elevation={3}
      className={classes.configWrapper}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-configuration"
        subheader={
          <>
            <Hidden mdUp>
              <IconButton onClick={onClick}>
                <ChevronLeft />
                <Typography
                  variant="button"
                >
                  {i18n.t("showTicketOwnerModal.buttons.ok")}
                </Typography>
              </IconButton>
            </Hidden>
            <ListSubheader
              component="h3"
              className={classes.subHeader}
            >
              {i18n.t("mainDrawer.listItems.title")}
            </ListSubheader>
          </>
        }
      >
        <ListItem
          component={Link}
          to="/connections"
          button
          selected={lastConfigPageOpen === "connections"}
        >
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.connections")}
          />
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to="/users"
          button
          selected={lastConfigPageOpen === "users"}
        >
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.users")}
          />
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to="/queues"
          button
          selected={lastConfigPageOpen === "queues"}
        >
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.queues")}
          />
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to="/URA"
          button
          selected={lastConfigPageOpen === "URA"}
        >
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.URA")}
          />
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to="/announcements"
          button
          selected={lastConfigPageOpen === "announcements"}
        >
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.announcements")}
          />
        </ListItem>
        <Divider />
        <ListItem
          component={Link}
          to="/monitoring"
          button
          selected={lastConfigPageOpen === "monitoring"}
        >
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.monitoring")}
          />
        </ListItem>
        <Divider />
      </List>
    </Paper>
  );
}

export default ConfigurationManager;