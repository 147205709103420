const userChatting = (chat, userId) => {
  return chat?.userChat?.find(u => u.user.id !== userId );
}

const isChatParticipant = (chat, userId) => {
  const isChatParticipant = chat?.userChat?.findIndex((uChat) => uChat?.userId === userId);
  
  return isChatParticipant !== -1;
}

const chatName = (chat, user) => {
  if (!chat?.isGroup && !isChatParticipant(chat, user?.id) && user?.profile === "admin") {
    const chatParticipantNames = chat?.userChat?.map((participant) => participant?.user?.name)

    return chatParticipantNames?.join(", ");
  }

  return chat?.isGroup ? chat.name : userChatting(chat, user?.id).user?.name;
}

export {userChatting, isChatParticipant, chatName}