import React, { useEffect, useState } from "react";

import {
  useFormik
} from "formik";

import * as yup from "yup";

import {
  makeStyles,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import {
  Colorize
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import ColorPicker from "../../components/ColorPicker";
import SelectUsers from "../../components/SelectUsers";

import api from "../../services/api";

import { toastError } from "../../messages/toasts";

const validationSchema = yup.object({
  name: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  color: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  greetingMessage: yup
    .string(),
});

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "16px"
  },

  submitButton: {
    marginTop: "16px"
  },

  showColor: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  colorBox: {
    width: "25px",
    height: "25px",
    borderRadius: "50%"
  }
}));

const QueueForm = ({ onSubmit, queue }) => {
  const classes = useStyles();

  const [selectedUsersIds, setSelectedUsersIds] = useState(queue?.users || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/users");
        setUsers(data.users);
      } catch (err) {
        toastError();
      }
    })();
  }, []);
  
  const formik = useFormik({
    initialValues: {
      name: queue?.name || "",
      color: queue?.color || "",
      greetingMessage: queue?.greetingMessage || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data = {
        ...values,
        userIds: selectedUsersIds.map((user) => user.id),
      }

      onSubmit(data);
    }
  });
  
  const handleOpenColorPicker = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <form
      className={classes.form}
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        id="name"
        label={i18n.t("queues.table.name")}
        name="name"
        variant="outlined"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <Button
        onClick={handleOpenColorPicker}
        startIcon={<Colorize />}
      >
        {i18n.t("formWrapper.form.label.selectColor")}
      </Button>
      <ColorPicker
        anchorEl={anchorEl}
        handleChange={(color) => {
          formik.values.color = color;
        }}
        handleClose={handleClose}
      />
      {formik.values.color ? (
        <Box
          className={classes.showColor}
        >
          <span
            style={{
              backgroundColor: formik.values.color
            }}
            className={classes.colorBox}
          />
          <span>
            {formik.values.color.toUpperCase()}
          </span>
        </Box>
      ) : null}
      <TextField
        fullWidth
        id="greetingMessage" 
        label={i18n.t("queues.table.greeting")}
        name="greetingMessage"
        multiline
        minRows={4}
        variant="outlined"
        value={formik.values.greetingMessage}
        onChange={formik.handleChange}
        error={formik.touched.greetingMessage && Boolean(formik.errors.greetingMessage)}
        helperText={formik.touched.greetingMessage && formik.errors.greetingMessage}
      />
      <SelectUsers
        options={users}
        setParticipantIds={setSelectedUsersIds}
        value={selectedUsersIds}
      />
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
      >
        {i18n.t("formWrapper.form.buttons.save")}
      </Button>
    </form>
  );
}

export default QueueForm;