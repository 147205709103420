import { Button, Card, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ChatHeaderSkeleton from "../ChatHeaderSkeleton";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import api from "../../services/api";
import { toastError } from "../../messages/toasts";

const useStyles = makeStyles((theme) => ({
  chatHeader: {
    display: "flex",
    backgroundColor: "#eee",
    flex: "none",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    maxHeight: "4.1em",
  },
}));

const ChatHeader = ({ loading, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const handleBack = () => {
    history.push("/chats");
  };

  const handleResetUnreadMessages = useCallback(async () => {
    const { chatId } = params;

    try {
      chatId && await api.put(`/chatmessages/${+chatId}`);
    } catch (err) {
      toastError(err);
    }
  }, [params]);

  useEffect(() => {
    return () => {
      handleResetUnreadMessages();
    }
  }, [handleResetUnreadMessages]);

  return (
    <>
      {loading ? (
        <ChatHeaderSkeleton />
      ) : (
        <Card square className={classes.chatHeader}>
          <Button color="primary" onClick={handleBack}>
            <ArrowBackIos />
          </Button>
          {children}
        </Card>
      )}
    </>
  );
}

export default ChatHeader;