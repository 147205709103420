import React, { useContext } from "react";
import {Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, makeStyles} from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";

import EmailIcon from '../../assets/icons/Email.svg';
import RamalIcon from '../../assets/icons/Ramal.svg';
import getFirstLetterName from "../../utils/getFirstLetterName";
import { isChatParticipant } from "../../services/chat";

const useStyles = makeStyles((theme) => ({
  memberListHeader: {
    marginTop: "1.5rem",
		display: "flex",
		alignItems: "center"
	},
	memberListTitle: {
		fontSize: "12px",
		marginLeft: "4px"
	},
	memberItem: {
		padding: 0,
		marginTop: "0.5rem"
	},
	memberAvatar: {
		minWidth: 42
	},
  contactInfos: {
		fontSize: "12px",
		color: theme.palette.grey[700],
		marginLeft: '4px'
	}
}))

const ChatParticipantInfo = ({
  chat, 
  userChatting, 
  userChat,
}) => {
  const classes = useStyles();
  const {user} = useContext(AuthContext);

  return (
    <>
      {isChatParticipant(chat, user.id) ? (
        <List>
          <ListItem>
            <img src={EmailIcon} alt="Email icon"/>
              <ListItemText 
                className={classes.contactInfos}
                primary={userChatting(chat, user.id).user?.email}
              />
          </ListItem>
          <ListItem>
            <img src={RamalIcon} alt="Ramal icon"/>
              <ListItemText
                className={classes.contactInfos}
                primary={userChatting(chat, user.id).user?.extension}
              />
          </ListItem>
        </List>
      ) : (
        <Box>
          <List dense>
            {userChat?.map(({user}) => (
              <ListItem key={user.id} className={classes.memberItem}>
                <ListItemAvatar className={classes.memberAvatar}>
                  <Avatar style={{width: 30, height: 30, fontSize: "1rem"}}>{getFirstLetterName(user)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={user?.email} secondary={user?.extension}/>
              </ListItem>
            ))}
          </List>
				</Box>
      )}
    </>
  );
}

export default ChatParticipantInfo;