import React, { useContext, useState } from "react";
import {
  IconButton,
  List,
  ListItem,
  makeStyles,
  Menu,
  Tooltip
} from "@material-ui/core";
import { 
  ChatBubble,
  CheckCircleOutlineOutlined,
  SpeakerNotesOff 
} from "@material-ui/icons";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(() => ({
  actionList: {
    padding: 0,
  },
}));

function CloseTicketOptions({loading, handleUpdateTicketStatus}) {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const sendFarewellMessage = true;

  return (
    <>
      <Tooltip
        arrow
        placement="bottom"
        title={i18n.t("messagesList.header.buttons.resolve.title")}
      >
        <IconButton
          loading={loading}
          onClick={handleOpenMenu}
          size="small"
        >
          <CheckCircleOutlineOutlined />
        </IconButton>
      </Tooltip>
      <Menu
        elevation={0}
        arias-controls="filters-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        getContentAnchorEl={null}
      >
        <List dense className={classes.actionList}>
          <Tooltip 
            arrow 
            placement="left"
            title={i18n.t("messagesList.header.buttons.resolve.actions.withFarewellMessage")}>
            <ListItem>
              <IconButton size="small" onClick={(e) => handleUpdateTicketStatus(e, "closed", user.id, "closed", sendFarewellMessage)}>
                <ChatBubble/>
              </IconButton>
            </ListItem>
          </Tooltip>
          <Tooltip 
            arrow 
            placement="left"
            title={i18n.t("messagesList.header.buttons.resolve.actions.withoutFarewellMessage")}>
            <ListItem>
              <IconButton size="small" onClick={(e) => handleUpdateTicketStatus(e, "closed", user.id, "closed")}>
                <SpeakerNotesOff/>
              </IconButton>
            </ListItem>
          </Tooltip>
        </List>
      </Menu>
    </>
  );
}

export default CloseTicketOptions;