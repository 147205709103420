import React from "react";
import { useHistory } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import ConnectionForm from "../../components/ConnectionForm";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const NewConnection = () => {
  const history = useHistory();

  const handleSubmit = async (formData) => {
    try {
      await api.post("/whatsapp", formData);

      toastSuccess();
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <FormWrapper
      title={i18n.t("formWrapper.titles.new.connection")}
    >
      <ConnectionForm
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  );
}

export default NewConnection;