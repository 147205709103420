import React, { useContext, useEffect, useReducer, useState } from "react";

import { i18n } from "../../translate/i18n";

import openSocket from "../../services/socket-io";
import useChats from "../../hooks/useChats";

import { makeStyles, Paper } from "@material-ui/core";

import ChatListItem from "../ChatListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import isUserLoggedAdmin from "../../utils/isUserLoggedAdmin";
import useCurrentUrlID from "../../hooks/useCurrentUrlID";

const useStyles = makeStyles(theme => ({
	chatsListWrapper: {
		position: "relative",
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflow: "hidden",
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
	chatsList: {
		flex: 1,
		overflowY: "scroll",
		...theme.scrollbarStyles,
		borderTop: "2px solid rgba(0, 0, 0, 0.12)",
	},
	chatsListHeader: {
		color: "rgb(67, 83, 105)",
		zIndex: 2,
		backgroundColor: "white",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	chatsCount: {
		fontWeight: "normal",
		color: "rgb(104, 121, 146)",
		marginLeft: "8px",
		fontSize: "14px",
	},
	noChatsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},
	noChatsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},
	noChatsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
}));

const reducer = (state, action) => {
	const userLogged = action?.user;

	if (action.type === "LOAD_CHATS") {
		const newChats = action.payload;
		const searchParam = action.searchParam;
		const pageNumber = action.pageNumber;

		if(!!searchParam && pageNumber === 1){
			return [...newChats]
		}

		newChats.forEach(chat => {
			const chatIndex = state.findIndex(c => c.id === chat.id);
			if (chatIndex !== -1) {
				state[chatIndex] = chat;
				if (chat.unreadMessages > 0) {
					state.unshift(state.splice(chatIndex, 1)[0]);
				}
			} else {
				state.push(chat);
			}
		});

		return [...state];
	}

	if (action.type === "RESET_UNREAD") {
		const chatId = action.payload;

		const chatIndex = state.findIndex(c => c.id === chatId);
		if (chatIndex !== -1) {
			state[chatIndex].unreadMessages = 0;
		}

		return [...state];
	}

	if (action.type === "UPDATE_CHAT") {
		const chat = action.payload;
		const chatIndex = state.findIndex(c => c.id === chat.id);

		if (chatIndex !== -1) {
			state[chatIndex] = chat;
		} else {
			state.unshift(chat);
		}

		return [...state];
	}

	if (action.type === "UPDATE_CHAT_UNREAD_MESSAGES") {
		const chat = action.payload.chat;
		const userMessages = action.payload?.unreadUserMessages?.filter(msg => msg.userId === userLogged?.id);
		const chatIndex = state.findIndex(c => c.id === chat.id);
		const shouldCount = action.shouldCount;

		if (userMessages?.length && shouldCount) {
			chat.unreadMessages = userMessages.length;
		}

		if (chatIndex !== -1) {
			state[chatIndex] = chat;
			state.unshift(state.splice(chatIndex, 1)[0]);
		}

		return [...state];
	}

	if (action.type === "UPDATE_CHAT_CONTACT") {
		const contact = action.payload;
		const chatIndex = state.findIndex(c => c.contactId === contact.id);
		if (chatIndex !== -1) {
			state[chatIndex].contact = contact;
		}
		return [...state];
	}

	if (action.type === "DELETE_CHAT") {
		const chatId = action.payload;
		const chatIndex = state.findIndex(c => c.id === chatId);
		if (chatIndex !== -1) {
			state.splice(chatIndex, 1);
		}

		return [...state];
	}

	if (action.type === "ADD_CHAT_PARTICIPANT") {
		const chat = action.payload.chat
		const addedParticipantsIds = action.payload.participantIds
		const shouldAddNewChat = addedParticipantsIds.indexOf(userLogged.id) > -1;
		const chatAlreadyExists = state.findIndex((chatListItem) => chatListItem.id === chat.id);

		if (!shouldAddNewChat || (chatAlreadyExists !== -1 && isUserLoggedAdmin(userLogged))) {
			return [...state];
		}

		state.unshift(chat)
		return [...state];
	}

	if (action.type === "DELETE_CHAT_PARTICIPANT") {
		const chat = action.payload.chat
		const removedParticipantId = action.payload.participantId
		const chatIndex = state.findIndex(c => c.id === chat.id);
		const showAll = action.showAll;

		if (chatIndex === -1) {
			return [...state];
		}

		if (userLogged?.id === +removedParticipantId && !showAll) {
			state.splice(chatIndex, 1);
			return [...state];
		}

		state[chatIndex] = chat;
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
}

const ChatsList = ({
	searchParam,
	showAll
}) => {
	const classes = useStyles();

	const [pageNumber, setPageNumber] = useState(1);
	const [chatsList, dispatch] = useReducer(reducer, []);

	const { user } = useContext(AuthContext);

	const { currentUrlIdRef } = useCurrentUrlID();

	const { chats, hasMore, loading } = useChats({
		pageNumber,
		searchParam,
		showAll
	});

	useEffect(() => {
		dispatch({ type: "RESET" });
		setPageNumber(1);
	}, [searchParam, dispatch, showAll]);

	const loadMore = () => {
		setPageNumber(prevState => prevState + 1);
	};

	useEffect(() => {
		dispatch({
			type: "LOAD_CHATS",
			payload: chats,
			searchParam: searchParam,
			pageNumber: pageNumber,			
		});
	}, [chats, searchParam, pageNumber]);

	useEffect(() => {
		const socket = openSocket();

		const shouldUpdateChat = (chat) => {
			const chatParticipantes = chat.userChat || [];
			const isChatParticipant = chatParticipantes.map(obj => obj.userId).indexOf(user?.id);

			return (!chat.userId || 
							chat.userId === user?.id || 
							isChatParticipant > -1 || 
							(isUserLoggedAdmin(user) && showAll));
		};

		socket.on("connect", () => {
			socket.emit("joinTickets", "open");
		});

		socket.on("chat", data => {

			if (data.action === "updateUnread" && (data.userId === user.id)) {
				dispatch({
					type: "RESET_UNREAD",
					payload: data.chatId,
				});
			}

			if (data.action === "created" && shouldUpdateChat(data.chat)) {
				dispatch({
					type: "UPDATE_CHAT",
					payload: data.chat,
				});
			}

			if (data.action === "delete") {
				dispatch({
					type: "DELETE_CHAT",
					payload: data.chatId
				});
			}

			if (data.action === "addParticipant") {
				dispatch({
					type: "ADD_CHAT_PARTICIPANT",
					payload: data,
					user: user
				});
			}			

			if (data.action === "removeParticipant") {
				dispatch({
					type: "DELETE_CHAT_PARTICIPANT",
					payload: data,
					user: user,
					showAll: showAll,
				});
			}
		});

		socket.on("appChatMessage", async data => {
			if (data.action === "create") {
				const chatId = data.chat.id;
				const shouldBlockNotificationCount = +currentUrlIdRef.current === chatId;

				dispatch({
					type: "UPDATE_CHAT_UNREAD_MESSAGES",
					payload: data,
					user: user,
					shouldCount: !shouldBlockNotificationCount
				});
				
			}

			if (data.action === "update" && shouldUpdateChat(data.chat)) {
				dispatch({
					type: "UPDATE_CHAT",
					payload: data.chat
				})
			}
		});

		socket.on("contact", data => {
			if (data.action === "update") {
				dispatch({
					type: "UPDATE_CHAT_CONTACT",
					payload: data.contact,
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [showAll, user, currentUrlIdRef]);

	const handleScroll = e => {
		if (!hasMore || loading) return;

		const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

		if (scrollHeight - (scrollTop + 100) < clientHeight) {
			loadMore();
		}
	};

	return (
		<Paper
			className={classes.chatsListWrapper}
		>
			<Paper
				square
				elevation={0}
				className={classes.chatsList}
				onScroll={handleScroll}
			>
				{chatsList.length === 0 && !loading ? (
					<div className={classes.noChatsDiv}>
						<span className={classes.noChatsTitle}>
							{i18n.t("chatList.noChatsTitle")}
						</span>
						<p className={classes.noChatsText}>
						{i18n.t("chatList.noChatsMessage")}
						</p>
					</div>
				) : (
					<>
						{chatsList.map(chat => (
							<ChatListItem chat={chat} key={chat.id} />
						))}
					</>
				)}
				{loading && <TicketsListSkeleton />}
			</Paper>
		</Paper>
	);
}

export default ChatsList;