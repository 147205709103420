import React, { useEffect, useState } from "react";

import { toastError } from "../../messages/toasts";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
  FormHelperText
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

import getDestinationItems from "../../services/getDestinationItems";
import api from "../../services/api";

const useStyles = makeStyles(() => ({
  destinationWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "16px"
  },

  selectField: {
    maxWidth: "217px",
    width: "100%",
  }
}))

const DestinationSelection = ({ formik }) => {
  const classes = useStyles();
  const { values, touched, errors, handleChange, setValues }= formik;

  const [destinationTypes, setDestinationTypes] = useState([]);
  const [destinationItems, setDestinationItems] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await api.get("/destinationtype");
      setDestinationTypes(data);
    })();
  }, []);

  useEffect(() => {
    if (!values.destinationType) return;

    (async () => {
      const data = await getDestinationItems(values.destinationType);
      setDestinationItems(data);
    })();
  }, [values.destinationType])

  const handleSelectDestinationType = (event) => {
    setValues({
      ...values,
      destinationItem: "",
    });

    handleChange(event);
    fetchDestinationItems(event.target.value);
  }

  const fetchDestinationItems = async (destinationTypeSelected) => {
    try {
      const data = await getDestinationItems(destinationTypeSelected);

      setDestinationItems(data);
    } catch (err) {
      toastError(err);
    }
  }

  const showDestinationItemSelect = !!values.destinationType && !!destinationTypes.length;

  return (
    <div
      className={classes.destinationWrapper}
    >
      <FormControl
        variant="outlined"
        fullWidth
        error={touched.destinationType && Boolean(errors.destinationType)}
      >
        <InputLabel id="destination-type-label">
          {i18n.t("formWrapper.form.label.destinationType")}
        </InputLabel>
        <Select
          id="destinationType"
          labelId="destination-type-label"
          label={i18n.t("formWrapper.form.label.destinationType")}
          name="destinationType"
          className={classes.selectField}
          value={values.destinationType}
          onChange={handleSelectDestinationType}
        >
          {destinationTypes.map(({id, name}) => (
            <MenuItem
              key={id}
              value={name}
            >
              {i18n.t(`formWrapper.form.destinationSelection.${name}`)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{touched.destinationType && errors.destinationType}</FormHelperText>
      </FormControl>
      {showDestinationItemSelect ? (
        <FormControl
          variant="outlined"
          fullWidth
          error={touched.destinationItem && Boolean(errors.destinationItem)}
        >
          <InputLabel id="destination-item-label">
            {i18n.t("formWrapper.form.label.destinationItem")}
          </InputLabel>
          <Select
            id="destination-item"
            labelId="destination-item-label"
            label={i18n.t("formWrapper.form.label.destinationItem")}
            name="destinationItem"
            className={classes.selectField}
            value={values.destinationItem}
            onChange={handleChange}
          >
            {destinationItems?.map(({id, name}) => (
              <MenuItem
                key={id}
                value={id}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.destinationItem && errors.destinationItem}</FormHelperText>
        </FormControl>
      ) : null}
    </div>
  )
}

export default DestinationSelection;