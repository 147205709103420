import React, { useEffect } from 'react';

import { makeStyles } from "@material-ui/core";
import { toastError } from "../../messages/toasts";

import imageMap from "../../assets/map.svg"

const useStyles = makeStyles(() => ({
	container: {
		minWidth: "200px",
		marginBottom: "10px",
		overflow: "hidden"
	},
	button: {
		cursor: "pointer",
		background: "transparent"
	},
	img: {
		maxWidth: "100%",
		height: "200px"
	},
	addrLocation: {
		color: "grey",
		wordWrap: "normal",
		maxWidth: "200px"
	},
}))

const LocationPreview = ({ image, link, nameLocation, addrLocation }) => {
	const classes = useStyles();

	useEffect(() => {}, [image, link, nameLocation, addrLocation]);

	const handleLocation = async() => {
			try {
					window.open(link);
			} catch (err) {
					toastError(err);
			}
	}

	return (
		<div className={classes.container}>
			<button onClick={handleLocation} disabled={!link} className={classes.button}>
				<img className={classes.img} src={imageMap} alt="Location" />
			</button>
			{nameLocation !== "" && (
				<p>{nameLocation}</p>
			)}
			{addrLocation !== "" && (
				<p className={classes.addrLocation}>{addrLocation}</p>
			)}
		</div>
	);
};

export default LocationPreview;