import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  useFormik
} from "formik";

import * as yup from "yup";

import {
  CircularProgress,
  FormHelperText,
  FormControl,
  makeStyles,
  InputLabel,
  IconButton,
  Typography,
  TextField,
  MenuItem,
  Button,
  Select,
  Paper,
  Chip,
  FormControlLabel,
  Switch,
  Box,
} from "@material-ui/core";
import {
  InfoOutlined,
  Palette,
  Visibility,
  VisibilityOff
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";

import {
  toastError,
  toastSuccess
} from "../../messages/toasts";

import useWhatsApps from "../../hooks/useWhatsApps";
import CustomToolTip from "../CustomToolTip";

const validationSchema = yup.object({
  name: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  email: yup
    .string()
    .email()
    .required(i18n.t("formWrapper.form.messages.errors.required")),
  password: yup
    .string()
    .min(8, i18n.t("formMessages.input.tooShort")),
  extension: yup
    .string()
		.min(3, i18n.t("formMessages.input.tooShort"))
		.max(5, i18n.t("formMessages.input.tooLong"))
		.nullable(true),
  profile: yup
    .string()
    .required(i18n.t("formWrapper.form.messages.errors.required"))
});

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "16px"
  },

  formGroup: {
    width: "100%",
    display: "flex",
    gap: "16px"
  },

  field: {
    maxWidth: "217px",
    width: "100%",
  },

  submitButton: {
    marginTop: "16px"
  },

  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px"
  },

  alert: {
		padding: "0.5rem",
		background: theme.palette.error.light,
		color: theme.palette.error.main
	},

  progress: {
    position: "absolute",
    right: "32px",
    top: "0",
    bottom: "0",
    margin: "auto 0"
  }
}));

const UserForm = ({ onSubmit, user }) => {
  const classes = useStyles();

  const history = useHistory();

  const [queues, setQueues] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
	const [userDeletedId, setUserDeletedId] = useState(null);
  const { whatsApps, loading: loadingFetchWhatsapp } = useWhatsApps();

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/queue");
				setQueues(data);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

  const queueIds = user?.queues.map(queue => queue.id);

  const formik = useFormik({
    initialValues: {
      name: user?.name || "",
      email: user?.email || "",
      password: "",
      extension: user?.extension || "",
      profile: user?.profile || "",
      queueIds: queueIds || [],
      canSeeAllTicketHistory: user?.canSeeAllTicketHistory || false,
      whatsappId: user?.whatsappId || ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const { email } = values;
        const { data: userDataFound } = await api.get("/users/search/items", {params: { email }});
  
        if (userDataFound.length === 0 || !userDataFound[0]?.isDeleted) {
          setShowAlert(false);
          setUserDeletedId(null);
          await onSubmit(values);
        } else {
          setUserDeletedId(userDataFound[0].id);
  
          if (showAlert) {
            await handleUpdateUser(values);
            return;
          }
          setShowAlert(true);
        }
      } catch (err) {
        toastError(err);
      }
    }
  });

	const handleUpdateUser = async (values) => {
		const userData = {
			...values,
			id: userDeletedId,
			isDeleted: false
		};

		try {
			await api.put(`/users/${userDeletedId}`, userData);

			toastSuccess(i18n.t("userModal.success"));
      history.goBack();
		} catch (err) {
			toastError(err);
		} finally {
      setShowAlert(false);
      setUserDeletedId(null);
    }
	}

  const handleChangeEmailField = (e) => {
    formik.handleChange(e);
		if (e.target.name === "email" && showAlert) {
			setUserDeletedId(null);
			setShowAlert(false);
		}
	}

  return (
    <form
      className={classes.form}
      onSubmit={formik.handleSubmit}
    >
      <TextField
        fullWidth
        id="name"
        label={i18n.t("users.table.name")}
        name="name"
        variant="outlined"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        fullWidth
        id="email"
        label={i18n.t("users.table.email")}
        name="email"
        type="email"
        variant="outlined"
        value={formik.values.email}
        onChange={handleChangeEmailField}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <TextField
        fullWidth
        id="password"
        label={i18n.t("userModal.form.password")}
        name="password"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        InputProps={{
          endAdornment: 
            <IconButton
              onClick={() => setShowPassword((prevState) => !prevState)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
        }}
      />
      <div
        className={classes.formGroup}
      >
        <TextField
          id="extension"
          label={i18n.t("users.table.extension")}
          name="extension"
          variant="outlined"
          className={classes.field}
          value={formik.values.extension}
          onChange={formik.handleChange}
          error={formik.touched.extension && Boolean(formik.errors.extension)}
          helperText={formik.touched.extension && formik.errors.extension}
        />
        <FormControl
          fullWidth
          variant="outlined"
          error={formik.touched.profile && Boolean(formik.errors.profile)}
        >
          <InputLabel
            id="profile-select"
          >
            {i18n.t("users.table.profile")}
          </InputLabel>
          <Select
            id="profile"
            labelId="profile-select"
            label={i18n.t("users.table.profile")}
            name="profile"
            className={classes.field}
            value={formik.values.profile}
            onChange={formik.handleChange}
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
          </Select>
          <FormHelperText>{formik.touched.profile && formik.errors.profile}</FormHelperText>
        </FormControl>
      </div>
      <div
        className={classes.formGroup}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel id="select-queues">
            {i18n.t("queueSelect.inputLabel")}
          </InputLabel>
          <Select
            id="queueIds"
            labelId="select-queues"
            label={i18n.t("queueSelect.inputLabel")}
            name="queueIds"
            multiple
            value={formik.values.queueIds}
            onChange={formik.handleChange}
            renderValue={(selectedQueueIds) => (
              <div className={classes.chips}>
                {selectedQueueIds.map((id) => {
                  const queue = queues.find((q) => q.id === id);

                  return (
                    <Chip
                      style={{ textOverflow: "ellipsis", width: "100%" }}
                      icon={
                        <Palette
                          style={{
                            color: queue?.color
                          }}
                        />
                      }
                      variant="outlined"
                      key={id}
                      label={queue?.name}
                    />
                  )
                })}
              </div>
            )}
          >
            {queues.map((queue) => (
              <MenuItem
                key={queue.id}
                value={queue.id}
              >
                {queue.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <CustomToolTip
          content={i18n.t("users.table.canSeeAllTicketHistory.tooltip")}
        >
          <Box
            display="flex"
            alignItems="center"
            width="100%"        
          >  
              <FormControlLabel
                control={
                  <Switch
                    id="canSeeAllTicketHistory"
                    name="canSeeAllTicketHistory"
                    color="primary"
                    checked={formik.values.canSeeAllTicketHistory}
                    onChange={formik.handleChange}
                  />
                }
                label={i18n.t("users.table.canSeeAllTicketHistory.label")}
              />
              <InfoOutlined 
                color="inherit"
              />
          </Box>
        </CustomToolTip>
      </div>
      <FormControl
        fullWidth
        variant="outlined"
      >
        <InputLabel id="select-whatsapp">
          {i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
        </InputLabel>
        <Select
          id="whatsappId"
          labelId="select-whatsapp"
          label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
          name="whatsappId"
          value={formik.values.whatsappId}
          onChange={formik.handleChange}
          disabled={loadingFetchWhatsapp}
        >
          <MenuItem value="">
            {i18n.t("formWrapper.form.label.nullSelection")}
          </MenuItem>
          {whatsApps.map((whatsapp) => (
            <MenuItem
              key={whatsapp.id}
              value={whatsapp.id}
            >
              {whatsapp.name}
            </MenuItem>
          ))}
        </Select>
        {loadingFetchWhatsapp && (
          <CircularProgress 
            size={16} 
            className={classes.progress}
          />
        )}
      </FormControl>
      <Paper 
        variant="outlined" 
        className={classes.alert} 
        style={{display: showAlert ? "block" : "none"}}
      >
				<Typography>{i18n.t("userModal.form.alert")}</Typography>
			</Paper>
      <Button
        className={classes.submitButton}
        type="submit"
        variant="contained"
        color="primary"
        size="large"
      >
        {showAlert ? 
          i18n.t("userModal.buttons.okEnable") :
          i18n.t("formWrapper.form.buttons.save")
        }
      </Button>
    </form>
  );
}

export default UserForm;