import { useState, useRef, useEffect, useContext, useCallback } from "react";
import openSocket from "../../services/socket-io";
import useSound from "use-sound";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import useCurrentUrlId from "../useCurrentUrlID";
import useChats from "../useChats";

const useChatNotification = () => {
	const {currentUrlIdRef} = useCurrentUrlId();

	const { user } = useContext(AuthContext);
	const [notifications, setNotifications] = useState([]);

	const { chats } = useChats({ withUnreadMessages: "true" });
	const [play] = useSound(alertSound);
	const soundAlertRef = useRef();

	useEffect(() => {
		soundAlertRef.current = play;

		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play]);

	useEffect(() => {
		setNotifications(chats);
	}, [chats]);

	const shouldNotify = useCallback((data) => {
		return (data?.message?.mediaType !== "chat-automatic") && (data?.chat?.id !== +currentUrlIdRef?.current);
	}, [currentUrlIdRef])

	useEffect(() => {
		const socket = openSocket();

		socket.on("connect", () => socket.emit("joinNotification"));

		socket.on("chat", data => {
			if (data.action === "updateUnread" && data.userId === user.id) {
				setNotifications(prevState => {
					const chatIndex = prevState.findIndex(c => c.id === data.chatId);
					if (chatIndex !== -1) {
						prevState.splice(chatIndex, 1);
						return [...prevState];
					}
					return prevState;
				});
			}
		});

		socket.on("appChatMessage", data => {			
			const hasMessageToRead = data.message.userMessage.filter(um => um.userId === user.id && !um.read && um.userSenderId !== user.id);
			
			if(data.action === "create" && hasMessageToRead.length && shouldNotify(data)){
				loadPreviewStateFromData(data);
				handleNotifications();
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [shouldNotify, user]);

	const loadPreviewStateFromData = (data) => {
		setNotifications(prevState => {
			const chatIndex = prevState.findIndex(t => t.id === data.chat.id);
			if (chatIndex !== -1) {
				prevState[chatIndex] = data.chat;
				return [...prevState];
			}
			return [data.chat, ...prevState];
		});
	};

	const handleNotifications = () => {
		soundAlertRef.current();
	};

	return {notifications}
};

export default useChatNotification;