import React, { useState, useContext } from "react";

import { useParams } from "react-router-dom";

import MenuItem from "@material-ui/core/MenuItem";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { toastError, toastInfo } from "../../messages/toasts";

const MessageOptionsMenu = ({
  message,
  menuOpen,
  handleClose,
  anchorEl,
  onSelectForwardMessage
  }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { ticketId } = useParams();

  const hasReferencedMessageSameWhatsappId = async() => {

    const getCurrentMessageTicket = async () => {
      try {
        return await api.get(`/tickets/${ticketId}`);
      } catch (error) {
        toastError(error)
      }
    }
    
    const getCurrentReferencedMessageTicket = async () => {
      try {
        return await api.get(`/tickets/${message?.ticketId}`);
      } catch (error) {
        toastError(error)
      }
    }

    const [
      {
        data: { ticket: currentMessageTicket },
      },
      {
        data: { ticket: currentReferencedMessageTicket },
      },
    ] = await Promise.all([
      getCurrentMessageTicket(),
      getCurrentReferencedMessageTicket(),
    ]);
    return currentMessageTicket.whatsappId === currentReferencedMessageTicket.whatsappId;
  }

  const handleDeleteMessage = async () => {
    try {
      const hasSameWhatsappId = await hasReferencedMessageSameWhatsappId();

      if (hasSameWhatsappId){
        await api.delete(`/messages/${message.id}`, {data: {ticketId} });
        return;
      }

      toastInfo(`${i18n.t("messageOptionsMenu.hasntSameDeletingMessageWhatsappId")}`);
    } catch (err) {
      toastError(err);
    }
  };

  const hanldeReplyMessage = async() => {
    const hasSameWhatsappId = await hasReferencedMessageSameWhatsappId();
    
    if (hasSameWhatsappId){
      setReplyingMessage(message);
      return;
    }
    
    setReplyingMessage(null);
    toastInfo(`${i18n.t("messageOptionsMenu.hasntSameReplyingMessageWhatsappId")}`);
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationOpen(true);
  };

  const handleSelectMenuOption = (functionToBeExecuted) => {
    functionToBeExecuted();
    handleClose();
  }

  return (
    <>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={setConfirmationOpen}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={menuOpen}
        onClose={handleClose}
      >
        {message.fromMe && (
          <MenuItem onClick={() => handleSelectMenuOption(handleOpenConfirmationModal)}>
            {i18n.t("messageOptionsMenu.delete")}
          </MenuItem>
        )}
          <MenuItem onClick={() => handleSelectMenuOption(hanldeReplyMessage)}>
          {i18n.t("messageOptionsMenu.reply")}
        </MenuItem>
        {message.mediaType !== "video" && (
          <MenuItem onClick={() => handleSelectMenuOption(onSelectForwardMessage)}>
            {i18n.t("messageOptionsMenu.forward")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default MessageOptionsMenu;
