import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import QueueForm from "../../components/QueueForm";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const EditQueue = () => {
  const history = useHistory();
  const params = useParams();

  const [queue, setQueue] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get(`/queue/${params.id}`);
        setQueue(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [params.id]);

  const handleSubmit = async (formData) => {
    if (!params.id) return;

    try {
      await api.put(`/queue/${params.id}`, formData);
      toastSuccess(i18n.t("queues.success"));
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <>
      {!loading && (
        <FormWrapper
          title={`${i18n.t("formWrapper.titles.edit")} ${queue?.name}`}
        >
          <QueueForm
            onSubmit={handleSubmit}
            queue={queue}
          />
        </FormWrapper>
      )}
    </>
  );
}

export default EditQueue;