import { useRef, useState } from "react";
import api from "../../services/api";
import { toastError } from "../../messages/toasts";

const optionalCallBack = (callback) => {
  typeof callback === 'function' && callback();
}

const useUserQueue = ({
  onClose,
  onChangeSelectedQueue,
  onChangeSelectedUser,
}) => {
  const firstSelectedFieldNameRef = useRef(null);

  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [loadingQueues, setLoadingQueues] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  async function handleFetchQueues() {
    const shouldntFetchQueue = queues.length > 0 || selectedUser;
    if (shouldntFetchQueue) {
      return;
    }

    setLoadingQueues(true);

    try {
      const { data } = await api.get("queue");
      setQueues(data);
    } catch (err) {
      toastError(err);
    } finally {
      setLoadingQueues(false);
    }
  }

  function clearQueueValues() {
    setSelectedQueue(null);
    optionalCallBack(onChangeSelectedQueue(selectedQueue));
  }

  function clearUserValues() {
    setSelectedUser(null);
    optionalCallBack(onChangeSelectedUser(selectedUser));
  }

  function handleClearQueueAction() {
    clearQueueValues();

    if (firstSelectedFieldNameRef.current === "queue") {
      clearUserValues();
      setUsers([]);
      firstSelectedFieldNameRef.current = null;
    }
  }

  function handleSelectQueueChange(queue) {
    if (queue) {
      if (!selectedUser) {
        firstSelectedFieldNameRef.current = "queue";
      }
      setSelectedQueue(queue);

      const queueUsers = queue?.users;
      if (queueUsers) {
        clearUserValues();
        setUsers(queueUsers);
      }
      optionalCallBack(onChangeSelectedQueue(queue));
      return
    }
    handleClearQueueAction();
  }

  async function handleFetchUsers() {
    const shouldntFetchUser = users.length > 0 || selectedQueue;
    if (shouldntFetchUser) {
      return;
    }

    setLoadingUsers(true);

    try {
      const { data } = await api.get("users");
      setUsers(data.users);
    } catch (err) {
      toastError(err);
    } finally {
      setLoadingUsers(false);
    }
  }

  function handleClearUserAction() {
    clearUserValues();

    if (firstSelectedFieldNameRef.current === "user") {
      clearQueueValues();
      setQueues([]);
      firstSelectedFieldNameRef.current = null;
    }
  }

  function handleSelectUserChange(user) {
    if (user) {
      if (!selectedQueue) {
        firstSelectedFieldNameRef.current = "user";
      }
      setSelectedUser(user);

      const userQueues = user?.queues;
      if (userQueues) {
        clearQueueValues();
        setQueues(userQueues);
      }
      optionalCallBack(onChangeSelectedUser(user));
      return;
    }
    handleClearUserAction();
  }

  const clearAllValues = () => {
    firstSelectedFieldNameRef.current = null;
    setQueues([]);
    setUsers([]);
    clearQueueValues();
    clearUserValues();
    optionalCallBack(onClose());
  };

  return {
    firstSelectedFieldNameRef,
    users,
    queues,
    loadingQueues,
    loadingUsers,
    selectedQueue,
    selectedUser,
    handleFetchQueues,
    handleSelectQueueChange,
    handleFetchUsers,
    handleClearUserAction,
    handleSelectUserChange,
    clearAllValues,
  };
};

export default useUserQueue;
