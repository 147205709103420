import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import ConnectionForm from "../../components/ConnectionForm";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const EditConnection = () => {
  const params = useParams();
  const history = useHistory();

  const [connection, setConnection] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!params.id) return;

    (async () => {
      try {
        const { data } = await api.get(`/whatsapp/${params.id}`);
        setConnection(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [params.id])

  const handleSubmit = async (formData) => {
    try {
      await api.put(`/whatsapp/${params.id}`, formData);
      
      toastSuccess();
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <>
      {!loading && (
        <FormWrapper
        title={`${i18n.t("formWrapper.titles.edit")} ${connection?.name}`}
        >
          <ConnectionForm
            onSubmit={handleSubmit}
            connection={connection}
          />
        </FormWrapper>
      )}
    </>
  );
}

export default EditConnection;