import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";

import {
  ReportProblemOutlined,
  WbIncandescentOutlined,
  MoreHorizOutlined
} from "@material-ui/icons/";

import { AuthContext } from "../../context/Auth/AuthContext"
import { toastError, toastSuccess } from "../../messages/toasts";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  buttonsArea:{
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around"
  },
  buttonType: {
    width: "7em",
    border: "1.5px #C4C4C4 solid",
    borderRadius: "6px",
    backgroundColor: "#fff",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    padding: "10px",
    color: "#757575",

    [theme.breakpoints.up("sm")]: {
      width: "9.5em",
    }, 
    "&:hover": {
      borderColor: "#000",
    },
  },
  buttonHighlight: {
    border: "2.5px #33697B solid",
    color: "#33697B",
  },
  messageTextarea: {
    width: "100%",
    marginTop: "20px",
  },
  displayNone: {
    display: "none",
  },
  buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const FeedbackSchema = Yup.object().shape({
	type: Yup.string().required("Selecione um tipo de feedback"),
	message: Yup.string()
		.min(50, i18n.t("formMessages.textArea.tooShort"))
		.max(30000, i18n.t("formMessages.textArea.tooLong"))
		.required(i18n.t("formMessages.requiredField")),
});

const UserFeedbackModal = ({open, onClose}) => {
  const { user } = useContext(AuthContext)
  
  const classes = useStyles()

  const initialState = { type: "", message: "" }

  const feedbackTypes = [
    {
      type: "BUG",
      title: i18n.t("feedbackUserModal.bug.title"),
      icon: <ReportProblemOutlined />,
      label: i18n.t("feedbackUserModal.bug.label"),
    },
    {
      type: "IDEA",
      title: i18n.t("feedbackUserModal.idea.title"),
      icon: <WbIncandescentOutlined />,
      label: i18n.t("feedbackUserModal.idea.label"),
    },
    {
      type: "OTHER",
      title: i18n.t("feedbackUserModal.other.title"),
      icon: <MoreHorizOutlined />,
      label: i18n.t("feedbackUserModal.other.label"),
    }
  ]

  const [textLabel, setTextLabel] = useState("")
  const [feedback, setFeedback] = useState(initialState)

  const handleClose = () => {
    onClose()
    setFeedback(initialState)
    setTextLabel("")
  }

  const handleSendMail = async values => {    
    const name = user.name;

    try {
      if(!values.message) return;
			await api.post("/feedback", { name, ...values });

      toastSuccess(i18n.t("feedbackUserModal.success"));
		} catch (err) {
      toastError(err);
		}
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      scroll="paper"
    >
      <DialogTitle>{i18n.t("feedbackUserModal.title")}</DialogTitle>
      <Formik
        initialValues={feedback}
        enableReinitialize={true}
        validationSchema={FeedbackSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSendMail(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form>
            <DialogContent dividers>
              <div className={classes.buttonsArea}>
                {feedbackTypes.map(dataFeedback => {
                  return (
                    <button
                      type="button"
                      key={dataFeedback.type}
                      className={`${classes.buttonType} ${dataFeedback.type === feedback.type ? classes.buttonHighlight : null}`}
                      onClick={() => {
                        setFeedback({ type: dataFeedback.type, message: feedback.message })
                        setTextLabel(dataFeedback.label)
                      }}
                    >
                      {dataFeedback.icon}

                      <Typography>{dataFeedback.title}</Typography>
                    </button>
                  )
                })}
              </div>
              <Field
                as={TextField}
                name="message"
                label={textLabel}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
                multiline
                minRows={6}
                variant="outlined"
                className={`${classes.messageTextarea} ${!feedback.type ? classes.displayNone : null}`}
                onChange={e => setFeedback({ type: feedback.type, message: e.target.value })}
              />
            </DialogContent>
            <DialogActions>
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                onClick={handleClose}
              >
                {i18n.t("feedbackUserModal.buttons.cancel")}
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={handleSendMail}
                disabled={!(feedback.type) || isSubmitting}
              >
                {i18n.t("feedbackUserModal.buttons.confirm")}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
} 

export default UserFeedbackModal