import { useState, useRef, useEffect, useContext, useCallback } from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import openSocket from "../../services/socket-io";

import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isTicketParticipant } from "../../services/ticket";

const BrowserNotificationsPopUp = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);

  const [, setDesktopNotifications] = useState([]);

  const { tickets } = useTickets({ withUnreadMessages: "true" });

  const historyRef = useRef(history);

  useEffect(() => {
    setNotifications(tickets);
  }, [tickets]);

  const shouldNotify = useCallback(
    (data, ticketParticipants, isQueueOrUserActivationMsg = false) => {

      const belongsToUserQueues =
        !data.ticket.userId &&
        user.queues.findIndex((queue) => queue.id === data.ticket.queueId) > -1;
      const isCreateAction = data.action === "create";
      const isMessageNotRead = !data?.message?.read;
      const isCurrentUserTheTicketOwner = data.ticket.userId === user.id;
      const isDocumentHidden = document.visibilityState === "hidden";

      return (
        (isCreateAction || isQueueOrUserActivationMsg) &&
        (isMessageNotRead || isQueueOrUserActivationMsg) &&
        (isCurrentUserTheTicketOwner ||
          isTicketParticipant(ticketParticipants, user.id) ||
          belongsToUserQueues) &&
        isDocumentHidden
      );
    },
    [user]
  );

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinNotification"));

    socket.on("ticket", (data) => {
      if (data.action === "updateUnread" || data.action === "delete") {
        setNotifications((prevState) => {
          const ticketIndex = prevState.findIndex(
            (t) => t.id === data.ticketId
          );
          if (ticketIndex !== -1) {
            prevState.splice(ticketIndex, 1);
            return [...prevState];
          }
          return prevState;
        });

        setDesktopNotifications((prevState) => {
          const notfiticationIndex = prevState.findIndex(
            (n) => n.tag === String(data.ticketId)
          );
          if (notfiticationIndex !== -1) {
            prevState[notfiticationIndex].close();
            prevState.splice(notfiticationIndex, 1);
            return [...prevState];
          }
          return prevState;
        });
      }
      if (data.action === "updateQueue" || data.action === "assigned") {
        const { ticket } = data;
        const isQueueOrUserActivationMsg = true;
        const ticketParticipants = data.ticket?.userTicket;

        if (shouldNotify(data, ticketParticipants, isQueueOrUserActivationMsg)) {
          handleNotifications({
            message: {
              body: ticket?.lastMessage,
            },
            contact: ticket.contact,
            ticket,
          });
          loadPreviewStateFromData(data);
        }
      }
    });

    socket.on("appMessage", (data) => {
      const ticketParticipants = data.ticket?.userTicket;

      if (shouldNotify(data, ticketParticipants)) {
        loadPreviewStateFromData(data);
        handleNotifications(data);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [shouldNotify]);

  const loadPreviewStateFromData = (data) => {
    setNotifications((prevState) => {
      const ticketIndex = prevState.findIndex((t) => t.id === data.ticket.id);
      if (ticketIndex !== -1) {
        prevState[ticketIndex] = data.ticket;
        return [...prevState];
      }
      return [data.ticket, ...prevState];
    });
  };

  const handleNotifications = (data) => {
    const { message, contact, ticket } = data;

    const options = {
      body: `${message?.body} - ${format(new Date(), "HH:mm")}`,
      icon: contact.profilePicUrl,
      tag: ticket.id,
      renotify: true,
      silent: true,
    };

    const notification = new Notification(
      `${i18n.t("tickets.notification.message")} ${contact.name}`,
      options
    );

    notification.onclick = (e) => {
      e.preventDefault();
      window.focus();
      historyRef.current.push(`/tickets/${ticket.id}`);
    };

    setDesktopNotifications((prevState) => {
      const notfiticationIndex = prevState.findIndex(
        (n) => n.tag === notification.tag
      );
      if (notfiticationIndex !== -1) {
        prevState[notfiticationIndex] = notification;
        return [...prevState];
      }
      return [notification, ...prevState];
    });
  };

  return null;
};

export default BrowserNotificationsPopUp;
