import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";

import ButtonWithSpinner from "../ButtonWithSpinner"

const DialogModal = (props) => {
  const {
    visible,
    maxWidth,
    fullWidth,
    isloading,
    onClose,
    onConfirm,
    initialDisabled,
    title,
    children,
    cancelLabel,
    confirmLabel,
  } = props;

  return (
    <Dialog 
      open={visible}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="inherit"
          onClick={onClose}
          disabled={isloading}
        >
          {cancelLabel}
        </Button>
        {!initialDisabled && (
          <ButtonWithSpinner
            type="submit"
            variant="contained"
            color="primary"
            loading={isloading}
            onClick={onConfirm}
          >
            {confirmLabel}
          </ButtonWithSpinner>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default DialogModal;