import { useState, useRef, useEffect, useContext, useCallback } from "react";
import openSocket from "../../services/socket-io";
import useSound from "use-sound";
import useTickets from "../useTickets";
import alertSound from "../../assets/WhatsAppSound.mp3";
import useCurrentUrlId from "../useCurrentUrlID"; 
import { AuthContext } from "../../context/Auth/AuthContext";
import { isTicketParticipant } from "../../services/ticket";

const useTicketNotification = () => {
	const { currentUrlIdRef } = useCurrentUrlId();

	const { user } = useContext(AuthContext);
	const [notifications, setNotifications] = useState([]);

	const { tickets } = useTickets({ withUnreadMessages: "true" });
	const [play] = useSound(alertSound);
	const soundAlertRef = useRef();

	useEffect(() => {
		soundAlertRef.current = play;

		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}
	}, [play]);

	useEffect(() => {
		setNotifications(tickets);
	}, [tickets]);

	const shouldNotify = useCallback((data, ticketParticipants) => {
		const belongsToUserQueues = () => {
			return !data.ticket.userId && user.queues.findIndex((queue) => queue.id === data.ticket.queueId) > -1;
		}

		const isTicketWaiting = () => {
			return !data.ticket.userId && !data.ticket.queueId;
		}

		return (
      (data.ticket.id !== +currentUrlIdRef?.current || document.visibilityState === "hidden") &&
			data.action === "create" &&
			!data.message.read &&
			(data.ticket.userId === user.id || isTicketParticipant(ticketParticipants, user.id) ||
			belongsToUserQueues() || isTicketWaiting())
		);
	}, [currentUrlIdRef, user]);

	useEffect(() => {
		const socket = openSocket();

		socket.on("connect", () => socket.emit("joinNotification"));

		socket.on("ticket", data => {
			if (data.action === "updateUnread" || data.action === "delete") {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
					if (ticketIndex !== -1) {
						prevState.splice(ticketIndex, 1);
						return [...prevState];
					}
					return prevState;
				});
			}
		});

		socket.on("appMessage", data => {
			const ticketParticipants = data.ticket?.userTicket;

			if (shouldNotify(data, ticketParticipants)) {
				loadPreviewStateFromData(data);
				handleNotifications();
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [shouldNotify]);

	const loadPreviewStateFromData = (data) => {
		setNotifications(prevState => {
			const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
			if (ticketIndex !== -1) {
				prevState[ticketIndex] = data.ticket;
				return [...prevState];
			}
			return [data.ticket, ...prevState];
		});
	};

	const handleNotifications = () => {
		soundAlertRef.current();
	};

	return {notifications}
};

export default useTicketNotification;