import React, { useState, useEffect, useReducer, useCallback } from "react";
import { Link } from "react-router-dom";

import openSocket from "../../services/socket-io";

import { toastError, toastSuccess } from "../../messages/toasts";

import {
  makeStyles,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  TextField,
  InputAdornment,
  Box,
  Collapse,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import {
  Search,
  Edit,
  DeleteOutline
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfigurationManager from "../../components/ConfigurationManager";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";

import api from "../../services/api";

const reducer = (state, action) => {
  if (action.type === "LOAD_USERS") {
    const users = action.payload;
    const newUsers = [];

    users.forEach((user) => {
      const userIndex = state.findIndex((u) => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex((u) => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex((u) => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  dataWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px"
  },

  tableWrapper: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  }
}));

const Users = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [toggleConfigManager, setToggleConfigManager] = useState(false);
  const [users, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_USERS", payload: data.users });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_USERS", payload: data.user });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.userId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/users/${userId}`);
      toastSuccess(i18n.t("users.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingUser(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleToggleConfigManager = useCallback(() => {
		setToggleConfigManager((prevState) => !prevState);
	}, []);

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingUser &&
          `${i18n.t("users.confirmationModal.deleteTitle")} ${
            deletingUser.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteUser(deletingUser.id)}
      >
        {i18n.t("users.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      {matches ? (
        <Collapse
          in={toggleConfigManager}
        >
          <ConfigurationManager
            onClick={handleToggleConfigManager}
          />
        </Collapse>
      ) : (
        <ConfigurationManager />
      )}
      <Box
        className={classes.dataWrapper}
      >
        <MainHeader
          title="users.title"
          onClick={handleToggleConfigManager}
        >
          <MainHeaderButtonsWrapper>
            <TextField
              variant="outlined"
              size="small"
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Link
              to="/users/new"
            >
              <Button
                variant="contained"
                color="primary"
              >
                {i18n.t("users.buttons.add")}
              </Button>
            </Link>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          elevation={0}
          className={classes.tableWrapper}
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{i18n.t("users.table.name")}</TableCell>
                <TableCell align="center">{i18n.t("users.table.extension")}</TableCell>
                <TableCell align="center">{i18n.t("users.table.email")}</TableCell>
                <TableCell align="center">{i18n.t("users.table.profile")}</TableCell>
                <TableCell align="center">{i18n.t("users.table.whatsapp")}</TableCell>
                <TableCell align="center">{i18n.t("users.table.actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell align="center">{user.name}</TableCell>
                    <TableCell align="center">{user.extension}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.profile}</TableCell>
                    <TableCell align="center">{user.whatsapp?.name}</TableCell>
                    <TableCell align="center">
                      <Link to={`/users/edit/${user.id}`} >
                        <IconButton
                          size="small"
                        >
                          <Edit />
                        </IconButton>
                      </Link>

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmModalOpen(true);
                          setDeletingUser(user);
                        }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={6} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </Box>
    </MainContainer>
  );
};

export default Users;
