import { makeStyles, Paper } from "@material-ui/core";
import React, { useContext } from "react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../messages/toasts";
import api from "../../services/api";

import openSocket from "../../services/socket-io";
import ChatHeader from "../ChatHeader";
import ChatInfo from "../ChatInfo";
import DetailsDrawer from "../DetailsDrawer";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import ChatMessagesList from "../ChatMessagesList";
import ChatMessageInput from "../ChatMessageInput";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isChatParticipant } from '../../services/chat';

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  chatInfo: {
    maxWidth: "50vw",
    flexBasis: "50vw",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "65vw",
      flexBasis: "65vw",
    },
  },
  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const Chat = () => {
  const classes = useStyles();
  
  const { chatId } = useParams();
  
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState({});
  const {user} = useContext(AuthContext)

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchChat = async () => {
        try {
          const { data } = await api.get("/chats/" + chatId);

          setChat(data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchChat();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [chatId, history]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", chatId));

    socket.on("chat", (data) => {
      if (data.action === "update") {
        setChat(data.chat);
      }

      if (data.action === "delete") {
        toastSuccess();
        history.push("/chats");
      }

      if (data.action === "addParticipant") {
        setChat(data.chat);
      }

      if (data.action === "removeParticipant") {
        setChat(data.chat);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [chatId, history]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root} id="drawer-container">
      <Paper
        variant="outlined"
        elevation={0}
        className={clsx(classes.mainWrapper, {
          [classes.mainWrapperShift]: drawerOpen,
        })}
      >
        <ChatHeader loading={loading}>
          <div className={classes.chatInfo}>
            <ChatInfo
              chat={chat}
              onClick={handleDrawerOpen}
            />
          </div>
        </ChatHeader>
        <ReplyMessageProvider>
          <ChatMessagesList chatId={chatId}/>
          {isChatParticipant(chat, user.id) && (<ChatMessageInput/>)}
        </ReplyMessageProvider>
      </Paper>
      <DetailsDrawer
        open={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        loading={loading}
        chat={chat}
      />
    </div>
  );
}

export default Chat;