import React from "react";
import { useHistory } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import UserForm from "../../components/UserForm";

import api from "../../services/api";

import {
  toastError,
  toastSuccess
} from "../../messages/toasts";

const NewUser = () => {
  const history = useHistory();

  const handleSubmit = async (formData) => {
    try {
      await api.post("/users", formData);
      toastSuccess(i18n.t("userModal.success"));
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  }

  return (
    <FormWrapper
      title={i18n.t("formWrapper.titles.new.user")}
    >
      <UserForm
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  );
}

export default NewUser;