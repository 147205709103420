import React, { useState } from "react";
import { CirclePicker } from "react-color";

import {
  Menu,
  MenuItem,
} from "@material-ui/core";

const colors = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#795548",
  "#607D8B",
  "#D00000",
  "#000000",
];

const ColorPicker = ({
  handleChange,
  handleClose,
  anchorEl
}) => {
  const [selectedColor, setSelectedColor] = useState("");
  
  const handleSelectColor = (color) => {
    setSelectedColor(color.hex);
    handleChange(color.hex);
    handleClose();
  };

  return (
    <Menu
      id="color-picker-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      getContentAnchorEl={null}
    >
      <MenuItem>
        <CirclePicker
          colors={colors}
          color={selectedColor}
          onChange={handleSelectColor}
        />
      </MenuItem>
    </Menu>
  );
};

export default ColorPicker;
