function getConfig(name, defaultValue=null) {
    // If inside a docker container, use window.ENV
    if( window.ENV !== undefined ) {
        return window.ENV[name] || defaultValue;
    }

    return process.env[name] || defaultValue;
}

export function getBackendUrl() {
    return getConfig('REACT_APP_BACKEND_URL');
}

export function getFrondendUrl() {
    return getConfig('REACT_APP_FRONTEND_URL');
}

export function getHoursCloseTicketsAuto() {
    return getConfig('REACT_APP_HOURS_CLOSE_TICKETS_AUTO');
}

export function getLoginPageLogo() {
    const defaultLogo = "Bradial_logo.svg";
    return getConfig('REACT_APP_LOGIN_PAGE_LOGO', defaultLogo);
}

export function getHeaderLayoutLogo() {
    const defaultLogo = "Bradial_header_layout_logo.png";
    return getConfig('REACT_APP_HEADER_LAYOUT_LOGO', defaultLogo);
}

export function getDocumentTitle() {
    const defaultTitle = "Bradial Chat";
    return getConfig('REACT_APP_DOCUMENT_TITLE', defaultTitle);
}

export function getManifestShortName() {
    const defaultName= "BradialChat";
    return getConfig('REACT_APP_MANIFEST_SHORT_NAME', defaultName);
}

export function getManifestName() {
    const defaultName = "BradialChat";
    return getConfig('REACT_APP_MANIFEST_NAME', defaultName);
}