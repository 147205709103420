import React, { useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";

import { format, parseISO } from "date-fns";

import { toastError, toastSuccess } from "../../messages/toasts";

import {
  makeStyles,
  Paper,
  Button,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Box,
	CircularProgress,
	Collapse,
	useTheme,
	useMediaQuery
} from "@material-ui/core";
import {
	green
} from "@material-ui/core/colors";
import {
	Edit,
	CheckCircle,
	SignalCellularConnectedNoInternet2Bar,
	SignalCellular4Bar,
	CropFree,
	DeleteOutline
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";

import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ConfigurationManager from "../../components/ConfigurationManager";
import ConfirmationModal from "../../components/ConfirmationModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import CustomToolTip from "../../components/CustomToolTip";
import MainContainer from "../../components/MainContainer";
import QrcodeModal from "../../components/QrcodeModal";
import MainHeader from "../../components/MainHeader";

import api from "../../services/api";

import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

const useStyles = makeStyles((theme) => ({
	dataWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: "24px"
  },

	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},

	buttonProgress: {
		color: green[500],
	},
}));

const Connections = () => {
	const classes = useStyles();
	const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

	const { whatsApps, loading } = useContext(WhatsAppsContext);
	const [qrModalOpen, setQrModalOpen] = useState(false);
	const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [toggleConfigManager, setToggleConfigManager] = useState(false);
	const confirmationModalInitialState = {
		action: "",
		title: "",
		message: "",
		whatsAppId: "",
		open: false,
	};
	const [confirmModalInfo, setConfirmModalInfo] = useState(
		confirmationModalInitialState
	);

	const handleOpenQrModal = whatsApp => {
		setSelectedWhatsApp(whatsApp);
		setQrModalOpen(true);
	};

	const handleCloseQrModal = useCallback(() => {
		setSelectedWhatsApp(null);
		setQrModalOpen(false);
	}, [setQrModalOpen, setSelectedWhatsApp]);

  const handleToggleConfigManager = useCallback(() => {
		setToggleConfigManager((prevState) => !prevState);
	}, []);

	const getAllOpenTicketsByWhatsapp = async (whatsappId) => {
		try {
			const { data: tickets } = await api.get("tickets/count", {
				params: { whatsappId }
			});
			
			return tickets;
		} catch (err) {
			return 0;
		}
	}

	const handleOpenConfirmationModal = async (action, whatsapp) => {
		if (action === "disconnect") {
			setConfirmModalInfo({
				action: action,
				title: i18n.t("connections.confirmationModal.disconnectTitle"),
				message: i18n.t("connections.confirmationModal.disconnectMessage"),
				whatsAppId: whatsapp.id,
			});
		}
		
		if (action === "delete") {
			const tickets = await getAllOpenTicketsByWhatsapp(whatsapp.id);
			const qtyTickets = tickets.length
			const deleteMessage = qtyTickets === 0 ? 
			i18n.t("connections.confirmationModal.deleteMessage.part1") : 
			<>
					{i18n.t("connections.confirmationModal.deleteMessage.part1")}
					<p>
						{i18n.t("connections.confirmationModal.deleteMessage.part2")}
						<strong> {qtyTickets} Ticket(s) </strong>
						{i18n.t("connections.confirmationModal.deleteMessage.part3")}
					</p>
					<p>{i18n.t("connections.confirmationModal.deleteMessage.part4")}</p>
			</>

			setConfirmModalInfo({
				action: action,
				title: `${i18n.t("connections.confirmationModal.deleteTitle")} ${whatsapp.name}`,
				message: deleteMessage,
				whatsAppId: whatsapp.id,
			});
		}
		setConfirmModalOpen(true);
	};

	const handleSubmitConfirmationModal = async () => {
		if (confirmModalInfo.action === "disconnect") {
			try {
				await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
			} catch (err) {
				toastError(err);
			}
		}

		if (confirmModalInfo.action === "delete") {
			try {
				await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
				toastSuccess(i18n.t("connections.toasts.deleted"));
			} catch (err) {
				toastError(err);
			}
		}

		setConfirmModalInfo(confirmationModalInitialState);
	};

	const renderActionButtons = whatsApp => {
		return (
			<>
				{whatsApp.status === "qrcode" && (
					<Button
						size="small"
						variant="contained"
						color="primary"
						onClick={() => handleOpenQrModal(whatsApp)}
					>
						{i18n.t("connections.buttons.qrcode")}
					</Button>
				)}
				{(whatsApp.status === "CONNECTED" ||
					whatsApp.status === "PAIRING" ||
					whatsApp.status === "TIMEOUT") && (
					<CustomToolTip
						content={i18n.t("connections.toolTips.onDisconnect.content")}
					>
						<Button
							size="small"
							variant="outlined"
							color="secondary"
							onClick={() => {
								handleOpenConfirmationModal("disconnect", whatsApp);
							}}
						>
							{i18n.t("connections.buttons.disconnect")}
						</Button>
					</CustomToolTip>
				)}
				{whatsApp.status === "OPENING" && (
					<Button size="small" variant="outlined" disabled color="default">
						{i18n.t("connections.buttons.connecting")}
					</Button>
				)}
				{whatsApp.status === "DISCONNECTING" && (
					<Button size="small" variant="outlined" disabled color="default">
						{i18n.t("connections.buttons.disconnecting")}
					</Button>
				)}
			</>
		);
	};

	const renderStatusToolTips = whatsApp => {
		return (
			<div className={classes.customTableCell}>
				{(whatsApp.status === "OPENING" || whatsApp.status === "DISCONNECTING") && (
					<CircularProgress size={24} className={classes.buttonProgress} />
				)}
				{whatsApp.status === "qrcode" && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.qrcode.title")}
						content={i18n.t("connections.toolTips.qrcode.content")}
					>
						<CropFree />
					</CustomToolTip>
				)}
				{whatsApp.status === "CONNECTED" && (
					<CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
						<SignalCellular4Bar style={{ color: green[500] }} />
					</CustomToolTip>
				)}
				{(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
					<CustomToolTip
						title={i18n.t("connections.toolTips.timeout.title")}
						content={i18n.t("connections.toolTips.timeout.content")}
					>
						<SignalCellularConnectedNoInternet2Bar color="secondary" />
					</CustomToolTip>
				)}
			</div>
		);
	};

	return (
		<MainContainer>
			<ConfirmationModal
				title={confirmModalInfo.title}
				open={confirmModalOpen}
				onClose={setConfirmModalOpen}
				onConfirm={handleSubmitConfirmationModal}
			>
				{confirmModalInfo.message}
			</ConfirmationModal>
			<QrcodeModal
				open={qrModalOpen}
				onClose={handleCloseQrModal}
				whatsAppId={selectedWhatsApp?.id}
			/>
      {matches ? (
        <Collapse
        	in={toggleConfigManager}
        >
          <ConfigurationManager
            onClick={handleToggleConfigManager}
          />
        </Collapse>
      ) : (
        <ConfigurationManager />
      )}
			<Box
				className={classes.dataWrapper}
			>
				<MainHeader
					title="connections.title"
					onClick={handleToggleConfigManager}
				>
					<MainHeaderButtonsWrapper>
						<Link
							to="/connections/new"
						>
							<Button
								variant="contained"
								color="primary"
							>
								{i18n.t("connections.buttons.add")}
							</Button>
						</Link>
					</MainHeaderButtonsWrapper>
				</MainHeader>
				<Paper
					elevation={0}
				>
					<Table size="small">
						<TableHead>
							<TableRow>
                <TableCell align="center">
									{i18n.t("connections.table.label")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("connections.table.name")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("connections.table.status")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("connections.table.session")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("connections.table.lastUpdate")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("connections.table.default")}
								</TableCell>
								<TableCell align="center">
									{i18n.t("connections.table.actions")}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{loading ? (
								<TableRowSkeleton />
							) : (
								<>
									{whatsApps?.length > 0 &&
										whatsApps.map(whatsApp => (
											<TableRow key={whatsApp.id}>
												<TableCell align="center">{whatsApp?.label}</TableCell>
												<TableCell align="center">{whatsApp.name}</TableCell>
												<TableCell align="center">
													{renderStatusToolTips(whatsApp)}
												</TableCell>
												<TableCell align="center">
													{renderActionButtons(whatsApp)}
												</TableCell>
												<TableCell align="center">
													{format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
												</TableCell>
												<TableCell align="center">
													{whatsApp.isDefault && (
														<div className={classes.customTableCell}>
															<CheckCircle style={{ color: green[500] }} />
														</div>
													)}
												</TableCell>
												<TableCell align="center">
													<Link to={`/connections/edit/${whatsApp.id}`}>
														<IconButton
															size="small"
														>
															<Edit />
														</IconButton>
													</Link>
													
													<IconButton
														size="small"
														onClick={e => {
															handleOpenConfirmationModal("delete", whatsApp);
														}}
													>
														<DeleteOutline />
													</IconButton>
												</TableCell>
											</TableRow>
										))}
								</>
							)}
						</TableBody>
					</Table>
				</Paper>
			</Box>
		</MainContainer>
	);
};

export default Connections;
