import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 48px)"
    },
    display: "flex",
    padding: 0,
  },
}));

const MainContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Container className={classes.mainContainer} maxWidth={false}>
      {children}
    </Container>
  );
};

export default MainContainer;
