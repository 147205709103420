import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
  Link,
  Collapse,
} from "@material-ui/core";

import Alert from "@material-ui/lab/Alert";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";

import BrowserNotificationsPopUp from "../components/BrowserNotificationsPopUp";
import ListItems from "./ListItems";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import useImage from "../hooks/useImage";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { getHeaderLayoutLogo } from "../config";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },

  toolbar: {
    paddingRight: 12,
    paddingLeft: 16,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "48px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.third.main,
    overflowX: "hidden",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7.5),
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingTop: "48px",
    overflow: "auto",
  },
  bannerContainer: {
    borderRadius: 0,
  },
  bannerLink: {
    color: theme.palette.warning.dark,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 13,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  logo: {
    height: 32,
  },
  color: {
    color: theme.palette.third.main,
    opacity: "0.9",
  },
}));

const LoggedInLayout = ({ children }) => {
  const classes = useStyles();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const { user } = useContext(AuthContext);

  const DYNAMICALLY_LOGO = getHeaderLayoutLogo();

  const { loading: loadingLogo, image } = useImage(DYNAMICALLY_LOGO);
  const [bannerDismissed, setBannerDismissed] = useLocalStorage(
    "bannerDismissed",
    false
  );

  const whatsAppMeLink =
    "https://wa.me/551939099662?text=Ol%C3%A1.%20Tenho%20interesse%20em%20migrar%20para%20o%20Bradial%20Chat%20V2.%20Como%20podemos%20dar%20in%C3%ADcio%20nesse%20processo?";

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const onDismissBanner = () => {
    setBannerDismissed(true);
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={drawerOpen ? classes.drawerPaper : classes.drawerPaperClose}
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <ChevronLeftIcon className={classes.color} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItems drawerOpen={drawerOpen} />
        </List>
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        color="primary"
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography noWrap className={classes.title}>
            {!loadingLogo && (
              <img
                src={image}
                alt={DYNAMICALLY_LOGO}
                className={classes.logo}
              />
            )}
          </Typography>
          {user.id && <BrowserNotificationsPopUp />}

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={menuOpen}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleOpenUserModal}>
                {i18n.t("mainDrawer.appBar.user.profile")}
              </MenuItem>
              <MenuItem onClick={handleClickLogout}>
                {i18n.t("mainDrawer.appBar.user.logout")}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <Collapse in={!bannerDismissed}>
          <Alert
            icon={<NewReleasesOutlinedIcon fontSize="inherit" />}
            severity="warning"
            onClose={onDismissBanner}
            className={classes.bannerContainer}
          >
            Lançamos a <b>versão 2</b> do Bradial Chat com diversas
            <b> novidades</b> e <b>melhorias!</b> Lembrando que essa versão terá
            <b> suporte apenas até o final de 2024. </b>
            <Link
              href={whatsAppMeLink}
              target="_blank"
              rel="noreferrer"
              className={classes.bannerLink}
            >
              Quero migrar 🚀
            </Link>
          </Alert>
        </Collapse>

        {children ? children : null}
      </main>
    </div>
  );
};

export default LoggedInLayout;
