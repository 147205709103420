import React from "react";
import { useHistory } from "react-router-dom";

import { i18n } from "../../translate/i18n";

import FormWrapper from "../../components/FormWrapper";
import AnnouncementForm from "../../components/AnnouncementForm";

import api from "../../services/api";

import { toastError, toastSuccess } from "../../messages/toasts";

const NewAnnouncement = () => {
  const history = useHistory();

  const handleSubmit = async (dataForm) => {
    try {
      await api.post("announcements", dataForm);

      toastSuccess();
      history.goBack();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <FormWrapper
      title={i18n.t("formWrapper.titles.new.announcement")}
    >
      <AnnouncementForm
        onSubmit={handleSubmit}
      />
    </FormWrapper>
  );
}

export default NewAnnouncement;