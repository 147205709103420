import React, { Fragment, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { format, isSameDay, parseISO } from "date-fns";

import { Avatar, Badge, Divider, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import MarkdownWrapper from "../MarkdownWrapper";

import {AuthContext} from "../../context/Auth/AuthContext"
import { chatName } from "../../services/chat";

const useStyles = makeStyles(theme => ({
	chat: {
		position: "relative",
	},
	limitContactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
		paddingRight: 80,
	},
	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
		paddingRight: 0,
	},
	lastMessageTime: {
		justifySelf: "flex-end",
	},
	contactLastMessage: {
		paddingRight: 120,
	},
	limitContactName: {
		paddingRight: 90,
	},
	noLimitContactName: {
		paddingRight: 0,
	},
	newMessagesCount: {
		alignSelf: "center",
		marginLeft: "auto",
		marginRight: 8,
	},
	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},
}));

const ChatListItem = ({ chat }) => {
  const classes = useStyles();
	const history = useHistory();
	const { chatId } = useParams();
	const {user} = useContext(AuthContext)

  const handleSelectChat = id => {
		history.push(`/chats/${id}`);
	};

  return(
    <Fragment key={chat.id}>
      <ListItem
				dense
				button
				onClick={() => handleSelectChat(chat.id)}
				selected={chatId && +chatId === chat.id}
				className={classes.chat}
			>
        <ListItemAvatar>
					<Avatar src={chat.pictureUrl} />
				</ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper }>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
								className={classes.noLimitContactName}
							>
								{chatName(chat, user)}
							</Typography>
							{chat.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(chat.updatedAt), new Date()) ? (
										<>{format(parseISO(chat.updatedAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(chat.updatedAt), "dd/MM/yy")}</>
									)}
								</Typography>
							)}
						</span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{chat.lastMessage ? (
									<MarkdownWrapper>{chat.lastMessage}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>
							<Badge
                overlap="rectangular"
								className={classes.newMessagesCount}
								badgeContent={chat.unreadMessages}
								classes={{
									badge: classes.badgeStyle,
								}}
							/>
						</span>
          }
        />
      </ListItem>
      <Divider variant="inset" />
    </Fragment>
  );
}

export default ChatListItem;