import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const RootPage = ({ component: Component, ...rest }) => {
  const { loading } = useContext(AuthContext);

  return (
    <>
      {loading && <BackdropLoading />}
      <Redirect to={{ pathname: "/tickets", state: { from: rest.location } }} />
    </>
  );
};

export default RootPage;
