import React, { useState, useEffect, useReducer, useRef, useContext } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "../../services/socket-io";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";

import { parseContact } from "../../services/parseVcard"

import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import Audio from "../Audio";
import Video from "../Video";
import ChatMessageOptionsMenu from "../ChatMessageOptionsMenu";
import whatsBackground from "../../assets/Background.svg";

import api from "../../services/api";
import { toastError } from "../../messages/toasts";
import { AuthContext } from "../../context/Auth/AuthContext";
import useDownload from "../../hooks/useDownload";

const useStyles = makeStyles(theme => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    fontSize: theme.typography.fontSize
  },
  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },
  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },
  messageLeft: {
    marginTop: 4,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderRadius: "8px 8px 8px 0",
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },
  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },
  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },
  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },
  messageRight: {
    marginTop: 4,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderRadius: "8px 8px 0 8px",
    padding: "5px 5px 0 5px",
    boxShadow: "0 1px 1px #b3b3b3",
  },
  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },
  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },
  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },
  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },
  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },
  textContentItemMedia: {
    maxWidth: 250,
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },
  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },
  messageMedia: {    
   objectFit: "cover",
   "&:fullscreen": { 
    objectFit: 'revert' 
   },
    borderRadius: "8px",
    width: 250,    
    maxHeight: 300,
    minHeight: 80,
  },
  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },
  deletedTimestamp: {
    fontStyle: "italic",
  },
  systemMessage: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    backgroundColor: "#e1f3fb",
    margin: "10px 0",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
    color: "#808888",
    padding: 8,
    fontSize: theme.typography.fontSize
  },
  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },
  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },
  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },
  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const ChatMessageList = ({ chatId }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();
  const lastMessageIdRef = useRef();
  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentChatId = useRef(chatId);
  const [setUrl] = useDownload();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentChatId.current = chatId;
  }, [chatId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          const { data } = await api.get(`/chatmessages/${chatId}`, {
            params: { pageNumber },
          });

          const messages = data?.messages;

          lastMessageIdRef.current = messages?.slice(-1)[0]?.id;

          if (currentChatId.current === chatId) {
            dispatch({ type: "LOAD_MESSAGES", payload: messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && messages && messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, chatId]);

  useEffect(() => {
    const socket = openSocket();

    socket.on("connect", () => socket.emit("joinChatBox", chatId));

    socket.on("appChatMessage", (data) => {
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });

        lastMessageIdRef.current = data.message.id;
        scrollToBottom();
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [chatId]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50 && !loading) {
      e.currentTarget.scrollTop = scrollTop + 100;
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if(message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      return <LocationPreview image={imageLocation} link={linkLocation} />
    }
    else if (message.mediaType === "vcard") {
      const parsedContact = parseContact(message.body)

      return <VcardPreview name={parsedContact?.name} number={parsedContact?.number}/>;
    }
    else if (message.mediaType === "image") {
      return <ModalImageCors mediaUrl={message.mediaUrl} />;
    } else if (message.mediaType === "audio") {
      return (
        <Audio mediaUrl={message.mediaUrl} />
      );
    } else if (message.mediaType === "video") {
      return (
        <Video mediaUrl={message.mediaUrl} />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              onClick={() => setUrl({
                url: message.mediaUrl,
                name: message.body
              })}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  }

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.systemMessage}
          key={`timestamp-${message.id}`}
        >
          {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
        </span>
      );
    }
    
    let messageDay = parseISO(messagesList[index].createdAt);
    let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

    if (!isSameDay(messageDay, previousMessageDay)) {
      return (
        <span
          className={classes.systemMessage}
          key={`timestamp-${message.id}`}
        >
          {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
        </span>
      );
    }

    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderAutomaticMessage = (message) => {
    return (
      <span className={classes.systemMessage}>
        {message.body}
      </span>
    )
  }

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3 || message.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      const messageUserId = message.userMessage[0].userSenderId;
      const previousMessageUserId = messagesList[index - 1].userMessage[0].userSenderId;

      if (messageUserId !== previousMessageUserId) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}
          {message.quotedMsg?.body}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.messagesListWrapper}>
      <ChatMessageOptionsMenu
        message={selectedMessage}
        lastMessageId={lastMessageIdRef}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 && messagesList.map((message, index) => {
          const isMessageFromMe = message.userMessage[0].userSenderId === user.id
          const isAutomaticMessage = message.mediaType === 'chat-automatic'
          
          return (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={message.id}
            >
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              {isAutomaticMessage ? renderAutomaticMessage(message) : (
                <div className={isMessageFromMe ? classes.messageRight : classes.messageLeft}>
                  {isMessageFromMe && (
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}
                  {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard" 
                  ) && checkMessageMedia(message)}
                  <div className={message.mediaUrl ? classes.textContentItemMedia : clsx(classes.textContentItem, {
                      [classes.textContentItemDeleted]: message.isDeleted,
                    })}
                  >
                    {message.isDeleted && (
                      <Block
                        color="disabled"
                        fontSize="small"
                        className={classes.deletedIcon}
                      />
                    )}
                    {message.quotedMsg && renderQuotedMessage(message)}
                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                  </div>
                  <span className={clsx(classes.timestamp, {
                    [classes.deletedTimestamp]: message.isDeleted,
                  })}>
                    {format(parseISO(message.createdAt), "HH:mm")}
                    {isMessageFromMe && renderMessageAck(message)}
                  </span>
                </div>
              )}
            </div>
          )
        })}
      </div>
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
}

export default ChatMessageList;